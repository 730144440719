<template>
  <el-form
    ref="instForm"
    :model="formData"
    :rules="formRulues"
    :class="{
      'base-form': true,
      'base-form--column': configInfo.layoutColumn,
    }"
    :label-width="configInfo.labelWidth"
    size="small"
  >
    <el-row
      v-for="(rowCols, rowIndex) in configInfo.fields"
      :key="rowIndex"
      type="flex"
      :gutter="gridInfo.gutter"
      class="base-form__row"
    >
      <el-col
        v-for="item in rowCols"
        :key="item.name"
        :span="item.grid ? item.grid.span : gridInfo.span"
        :xs="item.grid ? item.grid.xs : gridInfo.xs"
        :sm="item.grid ? item.grid.sm : gridInfo.sm"
        :md="item.grid ? item.grid.md : gridInfo.md"
        :lg="item.grid ? item.grid.lg : gridInfo.lg"
        :xl="item.grid ? item.grid.xl : gridInfo.xl"
        class="base-form__col"
      >
        <template v-if="item.type === 'slot'">
          <slot :name="item.name" />
        </template>
        <el-form-item
          v-else-if="item.type === 'row'"
          :label="item.label ? item.label + '：' : undefined"
          :class="{'is-required': item.isRequired}"
        >
          <el-row :gutter="item.gutter" class="base-form__inline">
            <template v-for="colItem in item.cols">
              <span
                v-if="colItem.type === 'string'"
                :key="colItem.content"
                :style="colItem.style"
                class="base-form__inline-text"
                :class="colItem.className"
              >
                {{colItem.content}}
              </span>
              <el-button
                v-else-if="colItem.type === 'button'"
                :key="colItem.buttonText"
                :type="colItem.buttonType"
                :icon="colItem.buttonIcon"
                :style="colItem.style"
                :class="colItem.className"
                @click="hanldeClick(colItem.onClick)"
              >
                {{colItem.buttonText}}
              </el-button>
              <el-col v-else :key="colItem.name">
                <FormItem
                  :options="colItem"
                  :formData="formData"
                  :updateFlag="updateFlag"
                  @uploadChange="handleUploadChange"
                  @enter="handleOnEnter"
                  @change="handleItemChange"
                />
              </el-col>
            </template>
          </el-row>
        </el-form-item>
        <FormItem
          v-else
          :options="item"
          :formData="formData"
          :updateFlag="updateFlag"
          @uploadChange="handleUploadChange"
          @enter="handleOnEnter"
          @change="handleItemChange"
        />
      </el-col>
      <!-- <el-col
        v-if="configInfo.buttons.length > 0"
        class="vue-form-layout-confirm"
        :span="configInfo.buttonsGrid ? configInfo.buttonsGrid.span : gridInfo.span"
        :xs="configInfo.buttonsGrid ? configInfo.buttonsGrid.xs : gridInfo.xs"
        :sm="configInfo.buttonsGrid ? configInfo.buttonsGrid.sm : gridInfo.sm"
        :md="configInfo.buttonsGrid ? configInfo.buttonsGrid.md : gridInfo.md"
        :lg="configInfo.buttonsGrid ? configInfo.buttonsGrid.lg : gridInfo.lg"
        :xl="configInfo.buttonsGrid ? configInfo.buttonsGrid.xl : gridInfo.xl"
      >
        <el-form-item label=" ">
          <template v-for="(item) in configInfo.buttons">
            <el-button
              v-if="item.type === 'reset'"
              :key="item.buttonText"
              :type="item.buttonType"
              :plain="item.buttonPlain"
              :icon="item.buttonIcon"
              :style="item.style"
              :class="item.className"
              size="small"
              @click="handleReset"
            >
              {{item.buttonText}}
            </el-button>
            <el-button
              v-else-if="item.type === 'confirm'"
              :key="item.buttonText"
              :type="item.buttonType"
              :icon="item.buttonIcon"
              :style="item.style"
              :class="item.className"
              size="small"
              @click="handleConfirm"
            >
              {{item.buttonText}}
            </el-button>
            <el-button
              v-else
              :key="item.buttonText"
              :type="item.buttonType"
              :plain="item.buttonPlain"
              :icon="item.buttonIcon"
              :style="item.style"
              :class="item.className"
              size="small"
              @click="hanldeClick(colItem.onClick)"
            >
              {{item.buttonText}}
            </el-button>
          </template>
        </el-form-item>
      </el-col> -->
    </el-row>
  </el-form>
</template>

<script>
/* eslint-disable no-unused-vars */
import Validator from './validator'
import FormItem from './FormItem.vue'

/**
 * 预设的栅格配置
 */
const PRESET_GRID = {
  // 默认方案（每一项表单占据整行宽度）
  default: {
    gutter: undefined, // 栅格间隔
    span: 24, // 栅格占据的列数
    xs: undefined, // <768px 响应式栅格数或者栅格属性对象
    sm: undefined, // ≥768px 响应式栅格数或者栅格属性对象
    md: undefined, // ≥992px 响应式栅格数或者栅格属性对象
    lg: undefined, // ≥1200px 响应式栅格数或者栅格属性对象
    xl: undefined, // ≥1920px 响应式栅格数或者栅格属性对象
  },
  // 编辑表单（每一项表单占据整行宽度）
  edit: {
    gutter: undefined, // 栅格间隔
    span: 12, // 栅格占据的列数
    xs: 24, // <768px 响应式栅格数或者栅格属性对象
    sm: 21, // ≥768px 响应式栅格数或者栅格属性对象
    md: 18, // ≥992px 响应式栅格数或者栅格属性对象
    lg: 12, // ≥1200px 响应式栅格数或者栅格属性对象
    xl: 10, // ≥1920px 响应式栅格数或者栅格属性对象
  },
  // 筛选表单（多列布局）
  filter: {
    gutter: 24, // 栅格间隔
    span: 7, // 栅格占据的列数
    xs: 24, // <768px 响应式栅格数或者栅格属性对象
    sm: 12, // ≥768px 响应式栅格数或者栅格属性对象
    md: 12, // ≥992px 响应式栅格数或者栅格属性对象
    lg: 8, // ≥1200px 响应式栅格数或者栅格属性对象
    xl: 6, // ≥1920px 响应式栅格数或者栅格属性对象
  },
}

/**
 * 预设的按钮配置
 */
const PRESET_BUTTONS = {
  // 筛选表单
  filter: [
    {
      type: 'reset',
      buttonText: '重置',
      buttonType: 'default',
      buttonPlain: true,
    },
    {
      type: 'confirm',
      buttonText: '搜索',
      buttonType: 'primary',
      buttonIcon: 'el-icon-search',
    }
  ],
  // 编辑表单
  edit: [
    {
      type: 'reset',
      buttonText: '重置',
      buttonType: 'default',
    },
    {
      type: 'confirm',
      buttonText: '保存',
      buttonType: 'primary',
    }
  ],
  // 仅搜索
  search: [
    {
      type: 'confirm',
      buttonText: '搜索',
      buttonType: 'primary',
      buttonIcon: 'el-icon-search',
    }
  ],
}

// 基础表单配置
const DEFAULT_FORM_CONFIG = {
  // 字段配置
  fields: [],
  // 栅格配置方案，提供了多种预设方案，也可以自定义对象
  // default 默认方案
  // filter 筛选表单
  grid: 'default',
  // label标签的宽度，默认为auto
  labelWidth: undefined,
  // 是否为纵向布局
  layoutColumn: false,
  // 表单按钮，提供了多种预设方案，也可以自定义数组
  // filter 筛选表单
  // edit 编辑表单
  // search 仅搜索
  buttons: [],
  // 表单按钮的栅格配置
  buttonsGrid: undefined,
  // 重置表单后的回调函数
  onReset: (formData) => {},
  // 点击提交且校验成功后的回调函数
  onConfirm: (formData) => {},
}

export default {
  name: "FormLayout",
  components: {
    FormItem,
  },
  props: {
    // 配置信息
    config: {
      type: Object,
      required: false,
      default(){
        return { ...DEFAULT_FORM_CONFIG};
      }
    },
    // 表单数据
    data: { type: Object, required: false, default(){ return {}; } },
  },
  data() {
    return {
      // 更新标识
      updateFlag: new Date().getTime(),
      // 栅格配置
      gridInfo: {},
      // 表单数据
      formData: {},
      // 表单校验规则
      formRulues: {},
      // 表单项的集合
      formItems: {},
      // 配置信息
      configInfo: { ...DEFAULT_FORM_CONFIG, ...this.config},
    };
  },
  watch: {
    config() {
      this.initConfigInfo()
    },
    // data() {
    //   this.formData = this.data || {}
    // },
  },
  computed: {
  },
  created() {
    this.initConfigInfo()
  },
  methods: {
    // 初始化配置信息
    initConfigInfo() {
      this.configInfo = { ...DEFAULT_FORM_CONFIG, ...this.config}
      const { labelWidth, grid, buttons } = this.configInfo

      // 栅格配置
      if (typeof grid === 'object') {
        this.gridInfo = {...grid}
      } else if (typeof grid === 'string' && PRESET_GRID[grid]) {
        this.gridInfo = {...PRESET_GRID[grid]}
      } else {
        this.gridInfo = {...PRESET_GRID.default}
      }

      // 表单按钮配置
      if (typeof buttons === 'string' && PRESET_BUTTONS[buttons]) {
        this.configInfo.buttons = [...PRESET_BUTTONS[buttons]]
      }

      // 最大label文本数量
      let maxLabelWords = 0

      // 表单数据
      const formData = {}

      // 设置表单字段默认值
      this.configInfo.fields.forEach((rowCols) => {
        rowCols.forEach((item) => {
          if (item.type === 'row') {
            item.cols.forEach(colItem => {
              if (colItem.rules && colItem.rules.required) {
                // 如果多列表单布局中存在一个必填项，则整行表单需要标记为必填状态
                item.isRequired = true
              }
              if (colItem.name) {
                this.formItems[colItem.name] = colItem
              }
              if ((item.label || '').length > maxLabelWords) {
                maxLabelWords = item.label.length
              }
              if (colItem.prefix && colItem.prefix.type === 'select' && colItem.prefix.name) {
                // 搜索输入框前面的下拉框
                this.formItems[colItem.prefix.name] = colItem.prefix
                this.setDefaultFormData(colItem.prefix, formData)
              }
              this.setDefaultFormData(colItem, formData)
            })
          } else {
            if (item.name) {
              this.formItems[item.name] = item
            }
            if ((item.label || '').length > maxLabelWords) {
              maxLabelWords = item.label.length
            }
            if (item.prefix && item.prefix.type === 'select' && item.prefix.name) {
              this.formItems[item.prefix.name] = item.prefix
              this.setDefaultFormData(item.prefix, formData)
            }
            this.setDefaultFormData(item, formData)
          }
        })
      })
      this.formData = formData

      // 设置label宽度
      if (labelWidth === undefined) {
        this.configInfo.labelWidth = `${maxLabelWords + 1}em`
      }

      // 获取表单校验规则
      this.formRulues = Validator.makeRulus(this.configInfo.fields)
    },

    // 设置表单数据的默认值
    setDefaultFormData(item, formData) {
      if (item.name) {
        if (item.defaultValue === '' && (item.type === 'number' || item.type === 'upload')) {
          // 数字输入框、上传组件，默认值不能为空字符串
          formData[item.name] = undefined
        } else if (item.type === 'checkbox' && !item.isBoolean) {
          // 复选框的值必须是数组（单个表示布尔值除外）
          formData[item.name] = Array.isArray(item.defaultValue) ? item.defaultValue : []
        } else if (item.type === 'select' && item.multiple && (item.defaultValue === '' || item.defaultValue === undefined)) {
          // 下拉选择框为多选时，默认值必须为空数组
          formData[item.name] = []
        } else if (!formData[item.name]) {
          formData[item.name] = item.defaultValue
        }
      }
    },

    // 更新表单配置项
    updateFormOptions(name, options) {
      const formItem = this.formItems[name] || {}
      Object.keys(options).forEach(key => {
        formItem[key] = options[key]
      })

      // 通过this.formItems赋值变更，无法触发子组件form-item的更新
      // 所以通过每次变更updateFlag来通知form-item子组件强制更新
      this.updateFlag = new Date().getTime()
    },

    // 更新表单校验规则
    updateFormRules(name, rules) {
      const formItem = this.formItems[name] || {}
      const ruleConfig = Validator.makeRulesItem({ name, label: formItem.label, rules })
      this.formRulues[name] = ruleConfig
      setTimeout(() => {
        this.validateField(name);
      }, 0)
    },

    // 校验单个字段
    validateField(name) {
      this.$refs.instForm.validateField(name, (errorMessage) => {})
    },

    // 重置
    handleReset() {
      this.$refs.instForm.resetFields()
      if (typeof this.configInfo.onReset === 'function') {
        this.configInfo.onReset(this.formData)
      }
    },

    // 提交
    handleConfirm() {
      this.$refs.instForm.validate((valid) => {
        if (valid) {
          if (typeof this.configInfo.onConfirm === 'function') {
            this.configInfo.onConfirm(this.formData)
          }
        }
      });
    },

    // 获取使用的表单数据
    getUseFormData() {
      const useData = {}
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key]) {
          useData[key] = this.formData[key]
        }
      })
      return useData;
    },

    // 上传组件变更事件
    handleUploadChange(fileResponse, flag, item) {
      this.validateField(item.name)
      this.handleItemChange(item)
    },

    // 监听change事件
    handleItemChange(item) {
      if (['editor'].includes(item.type)) {
        // 针对指定类型，需要在变更值的时候触发规则校验
        this.validateField(item.name);
      }
      if (item.onChange) {
        item.onChange(this.formData, {
          updateFormRules: this.updateFormRules.bind(this),
          updateFormOptions: this.updateFormOptions.bind(this),
        })
      }
    },

    // 文本框回车事件
    handleOnEnter(item) {
      if (item.onEnter) {
        item.onEnter(this.formData, {
          updateFormRules: this.updateFormRules.bind(this),
          updateFormOptions: this.updateFormOptions.bind(this),
        })
      }
    },

    // 点击事件
    hanldeClick(callback) {
      if (typeof callback === 'function') {
        callback(this.formData)
      }
    },
  }
};
</script>

<style lang="scss">
// 表单布局
.base-form {
  .el-row--flex {
    flex-wrap: wrap;
  }
  .el-form-item {
    display: flex;
    margin-bottom: 16px;

    .el-form-item {
      margin-bottom: 0 !important;
    }
  }

  .el-form-item__label {
    box-sizing: content-box;
  }

  .el-form-item__content {
    flex: 1;
    width: auto;
    margin-left: 0 !important;
  }

  // 使表单元素100%宽度
  .el-input,
  .el-input-number,
  .el-textarea,
  .el-select,
  .el-date-editor {
    width: 100% !important;
  }

  // 日期范围分隔文案的样式
  .el-range-separator {
    width: auto;
    padding: 0 3px;
  }
}

// 表单布局-纵向布局
.base-form--column {
  .base-form__row {
    display: flex;
    flex-direction: column;
  }
  .base-form__col {
    display: block;
  }
}

// 表单布局下的嵌套横向布局
.base-form__inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.base-form__inline-text {
  color: #606266;
  font-size: 13px;
  padding: 0 4px;
}


// 表单项的内容区
.base-form-item__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100%;
}

// 表单项的前置内容和后置内容
.base-form-item--prefix,
.base-form-item--suffix {
  .el-input-group__prepend,
  .el-input-group__append {
    flex-shrink: 0;
    width: auto;
    height: 32px;
    padding: 0 12px;
    font-size: 12px;
    line-height: 30px;
  }
}
.base-form-item--prefix {
  .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .el-input-group__prepend .el-select {
    width: 105px !important;
    padding-right: 10px;
    margin: 0 -20px;
  }
  .el-input-group__prepend .el-select .el-input__inner {
    text-align: center;
    border: none !important;
  }
}
.base-form-item--suffix {
  .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // 输入框的suffix内容作为按钮使用
  .el-input-group__append.is-button {
    cursor: pointer;
    padding: 0 16px !important;
    background: #2d8cf0!important;
    color: #fff!important;
    border-color: #2d8cf0!important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: relative;
    z-index: 2;

    &:hover {
      background: #57a3f3!important;
      border-color: #57a3f3!important;
    }

    &:active {
      background: #2b85e4!important;
      border-color: #2b85e4!important;
    }
  }
  // 没有文本标题的按钮
  .el-input-group__append.is-button-notext {
    padding: 0 20px !important;
    .el-button span {
      display: none;
    }
  }
}
</style>