<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section list-filter">
      <el-form :model="searchData" ref="mainFilterForm" size="small">
        <el-row :gutter="24">
          <!-- <el-col :span="7" :xs="24" :sm="12" :md="8" :xl="6">
            <el-form-item label="订单状态：" class="main-form-row">
              <el-select
                placeholder="请选择商品分类"
                clearable
                v-model="searchData.pid"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="7" :xs="24" :sm="12" :md="8" :xl="6">
            <el-form-item label="分类状态：" class="main-form-row">
              <el-select
                v-model="searchData.is_show"
                placeholder="请选择分类状态"
                clearable
                @change="handleSearch"
              >
                <el-option :value="1" label="显示" />
                <el-option :value="0" label="隐藏" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" :xs="24" :sm="12" :md="8" :xl="6">
            <el-form-item label="分类名称：" class="main-form-row">
              <el-input
                v-model="searchData.cate_name"
                placeholder="请输入分类名称"
                class="input-append-button"
                @keyup.enter.native="handleSearch"
              >
                <div slot="append" @click="handleSearch">
                  <i class="el-icon-search" />
                </div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="handleAdd"
          >添加商品分类</el-button
        >
      </div>

      <!-- 列表数据 -->
      <div class="list-table list-table--expand">
        <el-table
          v-loading="isLoading"
          :data="listData"
          row-key="id"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          >
          <el-table-column
            prop="cate_name"
            label="分类名称"
            align="left"
            min-width="250"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="分类ID"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pic"
            label="分类图标"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <div class="goods-img" v-viewer>
                <el-image :src="imageBaseUrl + scope.row.pic" fit="cover">
                  <div slot="error" class="goods-img-error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="is_show"
            label="状态"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                active-text="显示"
                inactive-text="隐藏"
                :active-value="'1'"
                :inactive-value="'0'"
                :width="60"
                @change="handleStateChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="250"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 添加分类 -->
    <AddClassify ref="AddClassify" @confirm="updateListData" />
  </div>
</template>

<script>
import mixinTable from "@/utils/mixinsTable";
import {
  getClassifyListApi,
  setClassifyStatusApi,
  deleteClassifyApi,
} from "@/api/product";
import { imageBaseUrl } from "@/utils/config";
import AddClassify from "./modules/AddClassify.vue";

export default {
  name: "ProductClassify",
  mixins: [mixinTable],
  components: {
    AddClassify,
  },
  data() {
    return {
      imageBaseUrl,
      // 列表接口
      fetchAction: getClassifyListApi,
      // 搜索条件
      searchData: {
        // pid: '', // 商品分类
        is_show: "", // 分类状态
        cate_name: "", // 分类名称
      },
    };
  },
  computed: {
    // 一级分类列表
    categoryList() {
      return this.listData.map((item) => {
        return { value: item.id, label: item.cate_name };
      });
    },
  },
  created() {},
  methods: {
    // mock数据设置函数

    // 添加分类
    handleAdd() {
      this.$refs.AddClassify.show();
    },

    // 编辑操作
    handleEdit(record) {
      this.$refs.AddClassify.show({ data: record });
    },

    // 状态变更
    async handleStateChange(record) {
      const params = { id: record.id, is_show: record.is_show };
      const res = await setClassifyStatusApi(params);

      if (res.status !== 200) {
        this.$message.error(res.msg);
        setTimeout(() => {
          record.is_show = record.is_show === 0 ? 1 : 0;
        }, 600);
      }
    },

    // 删除操作
    handleDelete(record) {
      this.$confirm("删除商品分类后将无法恢复，是否继续？", "提示", {
        type: "warning",
        callback: async (action) => {
          if (action === "confirm") {
            const params = { id: record.id };
            const res = await deleteClassifyApi(params);
            if (res.status === 200) {
              this.$message.success("删除分类成功!");
              this.updateListData();
            } else {
              this.$message.error(res.msg);
            }
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scope>
// 商品图
.goods-img {
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;

  .el-image {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.goods-img-error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #c0c4cc;
}
</style>
