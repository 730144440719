<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <div class="filter-title">店铺选择</div>
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="店铺选择"
          v-model="searchData.MallCode"
          @change="MallChange"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <div class="main-boxs">
        <vuedraggable
          :list="ProductList"
          @end="handleEndMove"
          class="list-wrap"
          handle=".mover"
        >
          <div
            class="list-item mover"
            v-for="(item, index) in ProductList"
            :key="index"
          >
            <div class="list-image-box">
              <img class="list-image" :src="imageBaseUrl + item.image" />
            </div>
            <div class="list-cont">
              <div class="list-title">
                {{ item.store_name }}
              </div>
              <div class="list-price">{{ item.price }}积分</div>
            </div>
            <div
              class="main-upload-images-close"
              @click="handleRemoveImage(index)"
            >
              <i class="el-icon-error" />
            </div>
          </div>

          <div class="list-item-choice" @click="openMarkingDialog()">
            <i class="el-icon-plus"></i>
          </div>
        </vuedraggable>

        <div class="btnBox">
          <el-button type="primary" size="small" @click="saveItems()">
            保存/发布
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog
      class="main-dialog-form"
      :title="'添加商品'"
      :visible.sync="MarkingDialog"
      :close-on-click-modal="false"
      width="85%"
    >
      <el-table
        element-loading-background="rgba(255,255,255,0.6)"
        :data="listData"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="8">商品分类：{{ props.row.cate_name }}</el-col>
              <el-col :span="8">商品市场价格：{{ props.row.ot_price }}</el-col>
              <el-col :span="8">成本价：{{ props.row.cost }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">收藏：{{ props.row.collect }}</el-col>
              <el-col :span="8">虚拟销量：{{ props.row.ficti }}</el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          type="selection"
          align="center"
          width="55"
          :selectable="selectableFn"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="商品id"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column prop="image" label="商品图" align="center" width="100">
          <template slot-scope="scope">
            <div class="goods-img" v-viewer>
              <img :src="imageBaseUrl + scope.row.image" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="store_name"
          label="商品名称"
          align="left"
          min-width="250"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="商品售价"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="sales"
          label="销量"
          align="center"
          min-width="80"
          sortable
        ></el-table-column>
        <el-table-column
          prop="stock"
          label="库存"
          align="center"
          min-width="80"
        ></el-table-column>
      </el-table>
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="listDataTotal"
          :page-sizes="[10, 20, 30]"
          :page-size="searchMarkData.limit"
          :current-page.sync="searchMarkData.page"
          @size-change="handleSizeChange"
          @current-change="qiehuan"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import vuedraggable from "vuedraggable";
import { getCounterList } from "@/api/storeList";
import { fetchGetProductItemList, addProductItemList } from "@/api/bannerList";
import { fetchProdctList } from "@/api/product";
import { imageBaseUrl } from "@/utils/config";

export default {
  name: "ProductListofPointMall",
  components: {
    vuedraggable,
  },
  data() {
    return {
      imageBaseUrl,
      MarkingDialog: false,
      shopList: [],
      listData: [],
      listDataTotal: 0,
      formData: {
        ItemName: "积分商品列表",
        ItemType: "1", // 默认1
        Item: [],
      },
      ProductList: [],
      searchData: {
        MallCode: "",
      },
      searchMarkData: {
        keywords: "", // 商品搜索
        page: 1,
        limit: 10,
        MallCode: "",
        type: 1,
        is_virtual: "2",
      },
    };
  },

  async created() {
    await this.getSelectData();
    // this.getProdctList();
    // this.getItemList();
  },
  mounted() {},
  destroyed() {},
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");
      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.MallCode = tableData.data.list[0].MallCode;
      await this.getItemList();
    },
    // 里面列表的
    async getProdctList() {
      const req = {
        ...this.searchMarkData,
        ...{ MallCode: this.searchData.MallCode },
      };
      const { data } = await fetchProdctList(req);
      this.listData = data.list;
      this.listDataTotal = data.count;
    },
    // 外面的
    async getItemList() {
      const { data } = await fetchGetProductItemList(this.searchData);
      if (data.count == 0) {
        console.log("xxx");
      } else {
        this.formData.ItemId = data.list[0].ItemId;
        let id = "";
        data.list[0].Item.forEach((i) => {
          id += `${i},`;
        });

        const req = {
          page: 1,
          limit: 999,
          MallCode: this.searchData.MallCode,
          type: 1,
          is_virtual: 2,
          id,
        };

        console.log("oldid", id);
        if (id == "") {
          this.ProductList = [];
          return;
        }

        const {
          data: { list },
        } = await fetchProdctList(req);

        this.ProductList = list;

        console.log("old", list);
      }
    },
    MallChange() {
      this.searchMarkData = {
        keywords: "", // 商品搜索
        page: 1,
        limit: 10,
        MallCode: "",
        type: 1,
        is_virtual: "2",
      };
      this.getItemList();
    },
    handleRemoveImage(idx) {
      this.$delete(this.ProductList, idx);
    },
    handleEndMove() {
      if (this.limit > 1) {
        this.fileResponse = this.fileList.map(
          (fileItem) => fileItem.response.data
        );
        this.$emit("change", this.fileResponse, "remove", this.extraData);
      }
    },
    openMarkingDialog() {
      this.getProdctList();
      this.MarkingDialog = true;
      this.$refs.multipleTable.clearSelection();
    },
    addMarkingEvent() {
      this.MarkingDialog = false;
      this.multipleSelection.forEach((i) => {
        this.ProductList.push(i);
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    async saveItems() {
      this.formData.Item = [];

      this.ProductList.forEach((i) => {
        this.formData.Item.push(i.id);
      });
      this.formData.MallCode = this.searchData.MallCode;

      await addProductItemList(this.formData);
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },
    selectableFn(row) {
      let canSelect = true;
      this.ProductList.forEach((i) => {
        if (i.id == row.id) canSelect = false;
      });

      return canSelect;
    },
    handleSizeChange(val) {
      this.searchMarkData.limit = val;
      this.getProdctList();
    },
    qiehuan(val) {
      this.searchMarkData.page = val;
      this.getProdctList();
    },
  },
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
/* 商品列表 */
.main-boxs {
  display: flex;
}
.list-wrap {
  width: 405px;
  margin: 0 15px;
  padding-bottom: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #999;
  padding: 20px;
  height: 640px;
  overflow: auto;
}
.list-item {
  position: relative;
  margin-bottom: 14px;
  width: 170px;
  height: 250px;
  /* overflow: hidden; */
  cursor: pointer;
}
.list-item-choice {
  margin-bottom: 14px;
  width: 170px;
  height: 250px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  font-size: 28px;
  cursor: pointer;
}
.list-image-box {
  width: 170px;
  height: 170px;
}
.list-image {
  width: 100%;
  height: 100%;
}
.list-cont {
  padding: 4px 4px 0;
}
.list-title {
  height: 42px;
  line-height: 21px;
  font-size: 14px;
  color: #222;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.list-price {
  display: block;
  font-size: 14px;
  line-height: 27px;
  color: #b88b4a;
}
</style>
