import request from "@/utils/request";

export function settingAdmin(data) {
  return request({
    url: "/adminapi/setting/admin",
    method: "get",
    params: data
  });
}

export function adminCreate(data) {
  return request({
    url: "/adminapi/setting/admin/create",
    method: "post",
    data
  });
}

export function settingAdminDel(data) {
  return request({
    url: "/adminapi/setting/admin/del",
    method: "get",
    params: data
  });
}

// .BC管理列表
export function StaffMasterList(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/StaffMasterList",
    method: "get",
    params: data
  });
}
// 添加修改BC管理
export function addStaffMaster(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/addStaffMaster",
    method: "post",
    data
  });
}

