<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <div class="filter-title">店铺选择</div>
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="店铺选择"
          v-model="searchData.MallCode"
          @change="MallChange"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          class="filter-item"
          type="primary"
          size="small"
          v-on:click="
            () => showAddMarkingDialog({ type: 'add', mark_type: '1' })
          "
          icon="el-icon-circle-plus-outline"
          >添加账号</el-button
        >
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          fit
          ref="table"
          v-loading="loading"
        >
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="BCName" label="姓名"> </el-table-column>
          <el-table-column prop="Sex" label="性别"> </el-table-column>
          <el-table-column prop="BCCode" label="员工编号"></el-table-column>
          <el-table-column prop="MallCode" label="所属店铺"> </el-table-column>
          <el-table-column prop="Grade" label="职位"> </el-table-column>
          <el-table-column prop="Email" label="账号/Email"> </el-table-column>

          <el-table-column label="控制" align="center" width="200">
            <!--  -->
            <template slot-scope="scope">
              <el-button
                v-on:click="
                  () => {
                    showAddMarkingDialog({
                      type: 'updata',
                      ...scope.row,
                      mark_type: '1',
                    });
                  }
                "
                size="mini"
                type="primary"
                style="width: 60px"
                >编辑</el-button
              >
              <!-- <el-button
                v-on:click="
                  () => {
                    markDelBtn({
                      account: scope.row.account,
                    });
                  }
                "
                size="mini"
                type="danger"
                style="width: 60px"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      class="main-dialog-form"
      :title="MarkingDialogType === 'add' ? '创建账号' : '修改账号'"
      :visible.sync="MarkingDialog"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">姓名:</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.BCName"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">性别:</div>
        </div>

        <el-radio-group class="dialog-right" v-model="formData.Sex">
          <el-radio :label="'M'">男</el-radio>
          <el-radio :label="'F'">女</el-radio>
        </el-radio-group>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">职位:</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            :disabled="true"
            v-model="formData.Grade"
          ></el-input>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">账号/Email:</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.Email"
          ></el-input>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">密码:</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.Password"
          ></el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCounterList } from "@/api/storeList";

import { StaffMasterList, addStaffMaster } from "@/api/adminList";

export default {
  name: "adminList",
  data() {
    return {
      tableData: [],
      shopList: [],
      radio: "M",

      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",

      formData: {
        BCName: "",
        MallCode: "",
        Sex: "F",
        Email: "",
        Password: "",
        Grade: "客服人员",
      },
      searchData: {
        limit: 25,
        page: 1,
      },
    };
  },
  created() {
    this.getSelectData();
  },
  mounted() {},
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");

      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.MallCode = tableData.data.list[0].MallCode;
      this.getTableData();
    },
    async MallChange() {
      // const tableData = await bannerList(this.searchData);
      // this.BannerList = tableData.data.list;
      this.getTableData();
    },

    async getTableData() {
      this.loading = true;
      const tableData = await StaffMasterList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    async showAddMarkingDialog(res) {
      const { type } = res;

      if (type === "add") {
        this.formData = {
          BCName: "",
          MallCode: this.searchData.MallCode,
          Sex: "F",
          Email: "",
          Password: "",
          Grade: "客服人员",
        };
      } else {
        this.formData = res;
        console.log(res);
      }
      this.MarkingDialogType = type;
      this.MarkingDialog = true;
    },
    // async markDelBtn(res) {
    //   this.$confirm("此操作将永久删除该账户, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       await settingAdminDel(res);
    //       this.getTableData();
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });

    //   console.log(res);
    // },
    async addMarkingEvent() {
      const data = this.formData;

      await addStaffMaster(data);

      this.getTableData();

      this.MarkingDialog = false;
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  display: flex;
  align-items: center;
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
