<template>
  <div class="memberPool">
    <cc-title :titleD="'自建名单'" :titleX="''"></cc-title>
    <div class="filter-container">
      <el-select
        clearable
        style="width: 200px"
        class="filter-item"
        placeholder="是否生效"
        v-model="searchData.Region"
      >
      </el-select>
      <el-date-picker
        v-model="value1"
        class="filter-item"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-date-picker
        class="filter-item"
        v-model="value1"
        type="date"
        placeholder="选择显示日期"
      >
      </el-date-picker>

      <el-button
        class="filter-item"
        type="primary"
        v-on:click="search"
        icon="el-icon-search"
        >搜索名单</el-button
      >
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      border
      fit
      @selection-change="handleSelectionChange"
      ref="table"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>

      <el-table-column prop="orderNum" label="名单名称" align="center">
      </el-table-column>
      <el-table-column prop="orderTime" label="名单人数" align="center">
      </el-table-column>
      <el-table-column prop="activityNum" label="开始日期" align="center">
      </el-table-column>
      <el-table-column prop="activityName" label="结束日期" align="center">
      </el-table-column>
      <el-table-column prop="activityName2" label="显示日期" align="center">
      </el-table-column>
      <el-table-column prop="activityName3" label="是否生效" align="center">
      </el-table-column>

      <el-table-column label="控制" align="center" width="250">
        <template>
          <el-button
            size="mini"
            v-on:click="bianji"
            type="success"
            style="width: 60px"
            >编辑</el-button
          >
          <el-button size="mini" type="primary" style="width: 60px"
            >生效</el-button
          >
          <el-button size="mini" type="danger" style="width: 60px"
            >失效</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footpagination">
      <el-pagination
        background
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        :total="totalSize"
        :page-sizes="[10, 100, 500, 1000]"
        :page-size="searchData.pageSize"
        @current-change="qiehuan"
      >
      </el-pagination>
    </div>

    <cc-title :titleD="'Arvato名单'" :titleX="''"></cc-title>
    <div class="filter-container">
      <el-date-picker
        v-model="value1"
        class="filter-item"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-date-picker
        class="filter-item"
        v-model="value1"
        type="date"
        placeholder="选择显示日期"
      >
      </el-date-picker>

      <el-button
        class="filter-item"
        type="primary"
        v-on:click="search"
        icon="el-icon-search"
        >搜索名单</el-button
      >
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      border
      fit
      @selection-change="handleSelectionChange"
      ref="table"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>

      <el-table-column prop="orderNum" label="名单名称" align="center">
      </el-table-column>
      <el-table-column prop="orderTime" label="名单人数" align="center">
      </el-table-column>
      <el-table-column prop="activityNum" label="开始日期" align="center">
      </el-table-column>
      <el-table-column prop="activityName" label="结束日期" align="center">
      </el-table-column>
      <el-table-column prop="activityName2" label="显示日期" align="center">
      </el-table-column>
    </el-table>

    <el-dialog
      title="创建名单"
      :visible.sync="dialogV"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="xiugai">
        <el-row :gutter="20">
          <el-col :span="10">
            <div class="grid-content bg-purple">
              名单名称：
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder=""
              ></el-input>
            </div>
          </el-col>
          <el-col :span="10">
            <div style="height: 40px; " class="grid-content bg-purple">
              <div class="title">人数：500人</div>
            </div>
          </el-col></el-row
        >
        <el-row :gutter="20">
          <el-col :span="10">
            <div class="grid-content bg-purple">
              日期选择：
              <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="10">
            <div style="height: 40px; " class="grid-content bg-purple">
              显示日期：<el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择显示日期"
              >
              </el-date-picker>
            </div> </el-col
        ></el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogV = false">取 消</el-button>
        <el-button type="primary" @click="dingdanxiugai">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "memberPool",
  data() {
    return {
      value1: "",
      tableData: [
        {
          orderNum: "三月未复购",
          orderTime: "1000",
          activityNum: "2021-05-21",
          activityName: "2021-05-21",
          activityName2: "2021-05-21",
          activityName3: "否"
        }
      ],
      totalSize: 0,
      dialogV: false,
      contentDialogV: false,
      options: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options2: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options3: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options4: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options5: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options6: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options7: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options8: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options9: [
        {
          value: "1",
          label: "全部"
        }
      ],
      options10: [
        {
          value: "1",
          label: "全部"
        }
      ],

      searchData: {
        Region: "", //	是	订单号
        CurrentCounte: "", //	string	是	订单名称
        CreateBC: "", //	string	是	订单类型 1：牌位订单 2：捐赠订单 3:用户一般乐捐订单 4：点灯活动订单
        Province: "",
        searchData: "",
        MemberCode: "",

        MemberType: "",
        AgeGroup: "",
        wxbanding: "",
        AvailableBP: "",
        BeExpriedBP: "",
        AccumulatedSpending: "",
        AccumulatedFreq: ""
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    search() {
      this.searchData.pageNo = 1;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    qiehuan(val) {
      this.searchData.pageNo = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.getList();
    },
    bianji() {
      this.dialogV = true;
    },
    charudelete() {
      this.contentDialogV = true;
    },
    deleteContent() {
      this.contentDialogV = false;
    },
    dingdanxiugai() {}
  }
};
</script>
<style lang="scss">
.grid-content {
  margin-bottom: 40px;
  height: 40px;
  line-height: 40px;
}
.footpagination {
  padding: 20px 0 30px 0;
}

.foot-box {
  padding-top: 20px;
}
</style>
