import axios from "axios";
import { Message } from "element-ui";

import { apiUrl } from "./config";
// import router from "../router";

// const token = ;

const getToken = () => `Bearer ${localStorage.getItem("token")}`;

// create an axios instance
const service = axios.create({
  baseURL: apiUrl, // api的base_url
  timeout: 500000 // request timeout
});

// request interceptor

service.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken();

    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  response => {
    // console.log(response);
    if (response.data.status === 410000) {
      window.location.href = "/#/login";
      localStorage.setItem("MallCode", "");
      return;
    }
    if (
      response.data.status !== 200 &&
      response.config.responseType !== "arraybuffer"
    ) {
      Message({
        message: response.data.msg,
        type: "error",
        duration: 5 * 1000
      });
      throw new Error("请求出错了", response.data.msg);
    }
    // eslint-disable-next-line consistent-return
    return response.data;
  },
  error => {
    console.log(`err${error}`); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
