<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <div class="filter-title">MemberCode</div>
        <el-input
          style="width: 200px"
          class="filter-item"
          clearable
          v-model="searchData.MemberCode"
          placeholder="MemberCode"
        ></el-input>
        <div class="filter-title">MemberName</div>
        <el-input
          style="width: 200px"
          class="filter-item"
          clearable
          v-model="searchData.MemberName"
          placeholder="MemberName"
        ></el-input>
        <div class="filter-title">WeChatName</div>
        <el-input
          style="width: 200px"
          class="filter-item"
          clearable
          v-model="searchData.WeChatName"
          placeholder="WeChatName"
        ></el-input>

        <div class="filter-title">WeChatPhoneNumber</div>

        <el-input
          style="width: 200px"
          class="filter-item"
          clearable
          v-model="searchData.WeChatPhoneNumber"
          placeholder="WeChatPhoneNumber"
        ></el-input>
        <div class="filter-title">MemberCardNo</div>

        <el-input
          style="width: 200px"
          class="filter-item"
          clearable
          v-model="searchData.MemberCardNo"
          placeholder="MemberCardNo"
        ></el-input>

        <div class="filter-title">增量会员</div>
        <el-select
          clearable
          style="width: 150px"
          class="filter-item"
          placeholder="IsNew"
          v-model="searchData.IsNew"
        >
          <el-option
            v-for="item in IsNewoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <div class="filter-title">手机授权会员</div>
        <el-select
          clearable
          style="width: 150px"
          class="filter-item"
          placeholder="IsPhone"
          v-model="searchData.IsPhone"
        >
          <el-option
            v-for="item in IsPhoneoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="filter-title">RegistMall</div>
        <el-select
          clearable
          style="width: 200px"
          class="filter-item"
          placeholder="RegistMall"
          v-model="searchData.RegistMallCode"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>

        <div class="filter-title">Member Type</div>
        <el-select
          clearable
          style="width: 200px"
          class="filter-item"
          placeholder="RegistMall"
          v-model="searchData.MemberType"
        >
          <el-option
            v-for="item in MemberTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="filter-title">BindingTime</div>
        <el-date-picker
          class="filter-item"
          v-model="tempTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="defaultTime"
          value-format="timestamp"
        >
        </el-date-picker>
        <div class="filter-title"></div>

        <el-button
          class="filter-item"
          type="primary"
          v-on:click="search"
          icon="el-icon-search"
          >搜索顾客</el-button
        >
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <!-- <div class="list-operate">
        <el-button
          class="filter-item"
          type="primary"
          size="small"
          v-on:click="bianji"
          icon="el-icon-circle-plus-outline"
          >创建名单</el-button
        >
      </div> -->

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          border
          fit
          @selection-change="handleSelectionChange"
          ref="table"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column label="MemberCode" width="155">
            <template slot-scope="scope">
              <!-- <el-link type="primary" v-on:click="showMemberDetail(scope.row)"> -->
              {{ scope.row.MemberCode }}
              <!-- </el-link> -->
              <!-- <el-button
                type="primary"
                v-on:click="showMemberDetail(scope.row)"
                size="mini"
                ></el-button
              > -->
            </template>
          </el-table-column>
          <el-table-column prop="MemberName" label="Member Name">
          </el-table-column>

          <el-table-column prop="MemberType" label="Member Type">
            <template slot-scope="scope">{{
              scope.row.MemberType == "11"
                ? "贵宾卡"
                : scope.row.MemberType == "12"
                ? "银卡"
                : scope.row.MemberType == "13"
                ? "金卡"
                : scope.row.MemberType == "14"
                ? "至尊卡"
                : "未同步"
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="" label="Tags" width="155">
            <template slot-scope="scope">
              <div
                class="tag-box"
                v-for="(i, idx) in TagsCut(scope.row.Tags)"
                :key="idx"
              >
                <el-button
                  v-if="i !== '-' && i !== []"
                  type="primary"
                  size="mini"
                  v-on:click="JumpMemberPoll(i.value)"
                  >{{ i.label }}</el-button
                >
                {{ i === "-" ? "-" : i === [] ? "-" : "" }}
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop=" " label="Persona"   > -->
          <!-- </el-table-column> -->
          <!-- <el-table-column prop="CreateStaffCode" label="CreateStaffCode"> -->
          <!-- <template slot-scope="scope">
              {{
                scope.row.CurrentMemberTier
                  ? i18n[scope.row.CurrentMemberTier.toLowerCase()]
                  : ""
              }}
            </template> -->
          <!-- </el-table-column> -->
          <!-- <el-table-column
            prop="CreateStaffCounterCode"
            label="CreateStaffCounterCode"
          >
          </el-table-column> -->
          <!-- <el-table-column prop="ManageStaffCode" label="ManageStaffCode">
          </el-table-column>
          <el-table-column
            prop="ManageStaffCounterCode"
            label="ManageStaffCounterCode"
          >
          </el-table-column> -->

          <el-table-column prop="MemberIdNo" label="MemberIdNo">
          </el-table-column>

          <el-table-column prop="MemberCardNo" label="MemberCardNo">
          </el-table-column>

          <el-table-column prop="MemberCid" label="MemberCid">
          </el-table-column>
          <!-- <el-table-column prop="WeChatPhoneNumber" label="WeChatPhoneNumber">
          </el-table-column> -->

          <!-- <el-table-column prop="WeChatUserID" label="WeChatUserID">
          </el-table-column> -->
          <el-table-column prop="WeChatOpenID" label="WeChatOpenID">
          </el-table-column>

          <el-table-column prop="WeChatUnionID" label="WeChatUnionID">
          </el-table-column>

          <el-table-column prop="WeChatName" label="WeChatName">
          </el-table-column>

          <el-table-column
            prop="WeChatAvatarUrl"
            label="WeChatAvatarUrl"
            align="center"
          >
            <template slot-scope="scope">
              <el-avatar
                v-if="scope.row.WeChatAvatarUrl"
                :size="50"
                :src="scope.row.WeChatAvatarUrl"
              ></el-avatar>
            </template>

            <!-- 头像啊 -->
          </el-table-column>

          <!-- <el-table-column prop="WeChatGender" label="WeChatGender">
          </el-table-column> -->

          <el-table-column prop="WeChatPhoneNumber" label="WeChatPhoneNumber">
          </el-table-column>

          <!-- <el-table-column prop="WeChatAge" label="WeChatAge">
          </el-table-column> -->

          <el-table-column prop="AvailableBP" label="AvailableBP">
          </el-table-column>

          <el-table-column prop="AvailableBPBTY" label="AvailableBPBTY">
          </el-table-column>

          <!-- <el-table-column prop="BeExpiredBP" label="BeExpiredBP">
          </el-table-column> -->
          <el-table-column align="center" prop="IsNew" label="IsNew">
          </el-table-column>
          <el-table-column
            align="center"
            prop="RegistMallCode"
            label="RegistMallCode"
          >
          </el-table-column>
          <el-table-column align="center" prop="CmmktCode" label="CmmktCode">
          </el-table-column>
          <el-table-column align="center" prop="AddTime" label="AddTime">
          </el-table-column>

          <el-table-column align="center" prop="CreatedAt" label="BindingTime">
          </el-table-column>

          <!-- <el-table-column prop="LastPurchaseDate" label="Last Purchase Date">
          </el-table-column> -->
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[50, 75, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { getCounterList } from "@/api/storeList";
import { memberPoolList } from "@/api/memberPool";
// counterList

// import { markingList } from "@/api/memberGroup";

const regionsList = [];
export default {
  name: "memberPool",
  data() {
    return {
      defaultTime: ["00:00:00", "23:59:59"],
      tempTime: null,
      Regions: "",
      CurrentCounters: "",
      loading: true,

      value1: [],
      tableData: [],
      totalSize: 0,
      dialogV: false,
      contentDialogV: false,
      tableDataBase: [],
      tableDataWecom: [],
      tableBpPoints: [],
      tableDatabcInfo: [],
      tableSummary: [],
      tableBuylist: [],
      tableAssignList: [],
      tableFollowupList: [],
      tableRegister: [],
      shopList: [],
      IsNewoptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "Y",
          label: "增量",
        },
        {
          value: "N",
          label: "存量",
        },
      ],

      IsPhoneoptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "未绑定",
        },
        {
          value: "2",
          label: "已绑定",
        },
      ],
      MemberTypeList: [
        {
          value: "11",
          label: "贵宾卡",
        },
        {
          value: "12",
          label: "银卡",
        },
        {
          value: "13",
          label: "金卡",
        },
        {
          value: "14",
          label: "至尊卡",
        },
      ],

      Tagesoptions: [],
      Regionsoptions: [],
      CurrentCounteroptions: [],

      searchData: {
        MemberType: "",
        CurrentCounter: "",
        CreateBC: "",
        MemberCode: "",
        CurrentMemberTier: "",
        wxbanding: "",
        AvailableBP: "",
        BeExpriedBP: "",
        mark_id: "",
        limit: 50,
        page: 1,
      },
    };
  },
  created() {},
  async mounted() {
    const tableData = await getCounterList({
      limit: 9999,
      page: 1,
    });
    this.shopList = tableData.data.list;
    // this.getMarkingList();
    // this.getCounterList();
    // counterList;
    this.getTableData();
  },
  methods: {
    TagsCut(teg) {
      const tegs = teg.split(",");
      const list = [];
      tegs.forEach((i) => {
        this.Tagesoptions.forEach((j) => {
          if (j.label === i) list.push(j);
        });
      });
      if (list.length === 0) return "-";
      return list;
    },
    // async getMarkingList() {
    //   const tableData = await markingList();

    //   let MarkingList = tableData.data.list.filter(i => i.status === 4);
    //   MarkingList = MarkingList.map(i => {
    //     return { value: i.mark_id, label: i.mark_name };
    //   });
    //   this.Tagesoptions = MarkingList;

    //   const { mark_id } = this.$router.currentRoute.query;

    //   this.searchData.mark_id = mark_id ? Number(mark_id) : "";
    //   this.getTableData();
    //   //  {
    //   //       value: "2",
    //   //       label: "无"
    //   //     }
    //   console.log(MarkingList);
    // },
    async getTableData() {
      this.loading = true;

      const { tempTime } = this;
      if (tempTime != null) {
        const startdata = moment(tempTime[0]).unix();
        const enddata = moment(tempTime[1]).unix();
        this.searchData.CreatedAt = JSON.stringify([startdata, enddata]);
      } else {
        this.searchData.CreatedAt = [];
      }
      const tableData = await memberPoolList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    // async getCounterList() {
    //   const {
    //     data: { list }
    //   } = await counterList();
    //   regionsList = list;
    //   let Regions = list.map(i => i.Region);
    //   Regions = [...new Set(Regions)];
    //   const Regionsoptions = Regions.map(i => {
    //     if (i === "") {
    //       return { value: i, label: "all" };
    //     }
    //     return { value: i, label: i };
    //   });
    //   this.Regionsoptions = Regionsoptions;
    //   console.log(Regionsoptions);
    //   console.log(regionsList);
    // },
    regionChange(res) {
      const CurrentCounters = [];
      this.CurrentCounters = "";
      if (res !== "") {
        regionsList.forEach((i) => {
          if (i.Region === res) {
            const temp = {
              value: i.CounterCode,
              label: i.CounterShortName,
            };
            CurrentCounters.push(temp);
          }
        });
      }
      this.CurrentCounteroptions = CurrentCounters;
      console.log(CurrentCounters);
      this.setCurrentCounter();
    },
    currentCountersChange() {
      this.setCurrentCounter();
    },
    setCurrentCounter() {
      this.searchData.CurrentCounter = "";
      const { Regions } = this;
      const { CurrentCounters } = this;
      if (Regions !== "") {
        if (CurrentCounters !== "") {
          this.searchData.CurrentCounter = CurrentCounters;
        } else {
          this.CurrentCounteroptions.forEach((i) => {
            this.searchData.CurrentCounter += `${i.value},`;
          });
        }
      }
      console.log(Regions, CurrentCounters);
    },

    showMemberDetail(res) {
      const routeData = this.$router.resolve({
        name: "memberDetial",
        query: { MemberCode: res.MemberCode },
      });
      window.open(routeData.href, "_blank");
    },

    search() {
      this.searchData.page = 1;
      this.getTableData();
    },
    handleSelectionChange() {
      // this.multipleSelection = val;
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    bianji() {
      this.dialogV = true;
    },
    JumpMemberPoll(res) {
      const mark_id = res;

      const routeData = this.$router.resolve({
        name: "memberPool",
        query: { mark_id },
      });
      window.open(routeData.href, "_blank");
      console.log(mark_id);
    },

    dingdanxiugai() {},
  },
};
</script>
<style lang="scss">
.grid-content {
  margin-bottom: 40px;
  height: 40px;
  line-height: 40px;
}
.footpagination {
  padding: 20px 0 30px 0;
}

.foot-box {
  padding-top: 20px;
}
.shopsbox {
  border-bottom: 1px solid #ebeef5;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.shopsbox:last-child {
  border-bottom: 0px solid #ebeef5;
}
.tag-box {
  padding-bottom: 5px;
}
</style>
