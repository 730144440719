<template>
  <div class="main-content">
    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="showAddMarkingDialog({ type: 'add', mark_type: '1' })"
        >
          创建店铺
        </el-button>
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          ref="table"
          v-loading="loading"
          :data="tableData"
          border
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          />
          <el-table-column
            prop="MallName"
            label="MallName"
          />
          <el-table-column
            prop="MallCode"
            label=" MallCode"
          />
          <el-table-column
            prop="ERPMallCode"
            label=" ERPMallCode"
          />
          <el-table-column
            prop="AddTime"
            label="AddTime"
          />
          <el-table-column
            label="控制"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                style="width: 60px"
                @click=" () => { showAddMarkingDialog({ type: 'updata', ...scope.row, mark_type: '1' }); } "
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                style="width: 60px"
                @click=" () => { markDelBtn({ MallCode: scope.row.MallCode }); } "
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          :current-page.sync="searchData.page"
          @size-change="handleSizeChange"
          @current-change="qiehuan"
        />
      </div>
    </div>

    <el-dialog
      class="main-dialog-form"
      :title="MarkingDialogType === 'add' ? '创建店铺' : '修改店铺'"
      :visible.sync="MarkingDialog"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">
            店铺名称：
          </div>
        </div>
        <div class="dialog-right">
          <el-input
            v-model="formData.MallName"
            class="filter-item"
            style="width: 350px;"
            placeholder=""
          />
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">
            店铺地址：
          </div>
        </div>
        <div class="dialog-right">
          <el-input
            v-model="formData.Address"
            class="filter-item"
            style="width: 350px;"
            placeholder=""
          />
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">
            ERPCode：
          </div>
        </div>
        <div class="dialog-right">
          <el-input
            v-model="formData.ERPMallCode"
            class="filter-item"
            style="width: 350px;"
            placeholder=""
          />
        </div>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addMarkingEvent"
        >保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCounterList, counterSave, counterDel } from '@/api/storeList';

export default {
  name: 'StoreList',
  data() {
    return {
      tableData: [],
      tableData2: [],
      behavioralConditionsList: [],
      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: 'add',
      options: [
        {
          value: '1',
          label: '全部',
        },
      ],
      formData: {
        MallName: '',
        Address: '',
        MallCode: '',
        ERPMallCode: '',
      },
      searchData: {
        limit: 25,
        page: 1,
      },
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  destroyed() {},
  methods: {
    async getTableData() {
      this.loading = true;
      const tableData = await getCounterList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    async showAddMarkingDialog(res) {
      const { type } = res;

      if (type === 'add') {
        this.formData = {
          MallName: '',
          Address: '',
          ERPMallCode: '',
        };
      } else {
        this.formData = {
          MallName: res.MallName,
          Address: res.Address,
          MallCode: res.MallCode,
          ERPMallCode: res.ERPMallCode,
        };
        console.log(res);
      }
      this.MarkingDialogType = type;
      this.MarkingDialog = true;
    },
    async markDelBtn(res) {
      this.$confirm('此操作将永久删除该店铺, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await counterDel(res);
          this.getTableData();
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });

      console.log(res);
    },
    async addMarkingEvent() {
      const data = this.formData;

      await counterSave(data);

      this.getTableData();

      this.MarkingDialog = false;
      this.$message({
        message: '保存成功',
        type: 'success',
      });
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
