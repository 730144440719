<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section list-filter">
      <el-form :model="searchData" ref="mainFilterForm" size="small">
        <el-row :gutter="24">
          <el-col :span="7" :xs="24" :sm="12" :md="8" :xl="6">
            <el-form-item label="规格搜索：" class="main-form-row">
              <el-input v-model="searchData.rule_name" placeholder="请输入规格名称" class="input-append-button" @keyup.enter.native="handleSearch">
                <div slot="append" @click="handleSearch">
                  <i class="el-icon-search" />
                </div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">添加商品规格</el-button>
        <!-- <el-button type="danger" icon="el-icon-delete" size="small" @click="handleBatchDelete">批量删除</el-button> -->
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          v-loading="isLoading"
          :data="listData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="55"></el-table-column>
          <el-table-column prop="id" label="ID" align="center" width="80"></el-table-column>
          <el-table-column prop="rule_name" label="规格名称" align="center" min-width="150"></el-table-column>
          <el-table-column prop="attr_name" label="商品规格" align="center" min-width="250"></el-table-column>
          <el-table-column prop="attr_value" label="商品属性" align="center" min-width="300"></el-table-column>
          <el-table-column label="操作" align="center" width="250" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          v-if="isDone && listData.length > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="totalElements"
          :current-page="currentPage"
          :page-sizes="paginationSizes"
          :page-size="pageSize"
          :layout="paginationLayout"
          background
        ></el-pagination>
      </div>
    </div>

    <!-- 添加规格 -->
    <AddAttr ref="AddAttr" @confirm="updateListData" />
  </div>
</template>

<script>
  import mixinTable from '@/utils/mixinsTable'
  import {
    getAttrListApi,
    deleteAttrApi
  } from '@/api/product'
  import AddAttr from './modules/AddAttr'

  export default {
    name: "ProductAttr",
    mixins: [mixinTable],
    components: {
      AddAttr,
    },
    data() {
      return {
        // 列表接口
        fetchAction: getAttrListApi,
        // 搜索条件
        searchData: {
          rule_name: '', // 规格搜索
        },
        // 多选列表
        selectedList: [],
      };
    },
    created() {
    },
    methods: {

      // 多选变更
      handleSelectionChange(selectedList) {
        this.selectedList = selectedList
      },

      // 添加商品规格
      handleAdd() {
        this.$refs.AddAttr.show()
      },

      // 编辑操作
      handleEdit(record) {
        this.$refs.AddAttr.show({ data: record })
      },

      // 删除操作
      handleDelete(record) {
        this.$confirm('删除商品规格后将无法恢复，是否继续？', '提示', { type: 'warning', callback: async (action) => {
          if (action === 'confirm') {
            const params = {ids: record.id}
            const res = await deleteAttrApi(params)
            if (res.status === 200) {
              this.$message.success('删除规格成功!')
              this.updateListData()
            } else {
              this.$message.error(res.msg)
            }
          }
        }})
      },

      // 批量删除
      handleBatchDelete() {

      },
    },
  };
</script>

<style lang="scss" scope>
  // 商品图
  .goods-img {
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;

    .el-image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .goods-img-error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #c0c4cc;
  }
</style>
