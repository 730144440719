<template>
  <div class="config-cont">
    <el-tabs value="content" class="main-tabs-side">
      <el-tab-pane name="content" label="内容设置">
        <el-row class="config-item">
          <el-col :span="4" class="config-label">主标题</el-col>
          <el-col :span="19">
            <el-input
              v-model="currentValue.title"
              placeholder="主标题"
              size="small"
            />
          </el-col>
        </el-row>
        <el-row class="config-item">
          <el-col :span="4" class="config-label">副标题</el-col>
          <el-col :span="19">
            <el-input
              v-model="currentValue.subTitle"
              placeholder="副标题"
              size="small"
            />
          </el-col>
        </el-row>
        <el-row class="config-item">
          <el-col :span="4" class="config-label">更多</el-col>
          <el-col :span="19">
            <el-input
              v-model="currentValue.moreText"
              placeholder="更多"
              size="small"
            />
          </el-col>
        </el-row>
        <el-row class="config-item">
          <el-col :span="4" class="config-label">链接</el-col>
          <el-col :span="19">
            <el-input
              v-model="currentValue.jumpPage"
              placeholder="请输入跳转链接"
              size="small"
            />
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane name="style" label="样式设置">
        <div class="config-prop">
          <div class="config-label">排版方向</div>
          <el-radio-group v-model="currentValue.direction" size="mini">
            <el-radio-button label="row">横向</el-radio-button>
            <el-radio-button label="column">纵向</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">对齐方式</div>
          <el-radio-group v-model="currentValue.align" size="mini">
            <el-radio-button label="left">居左</el-radio-button>
            <el-radio-button label="center">居中</el-radio-button>
            <el-radio-button label="right">居右</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="currentValue.direction === 'row' && currentValue.align === 'left'" class="config-prop">
          <div class="config-label">显示更多</div>
          <el-switch v-model="currentValue.showMore"></el-switch>
        </div>
        <div class="config-prop">
          <div class="config-label">背景颜色</div>
          <el-color-picker v-model="currentValue.bgColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">标题颜色</div>
          <el-color-picker v-model="currentValue.titleColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">副标题颜色</div>
          <el-color-picker v-model="currentValue.subTitleColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">标题样式</div>
          <el-radio-group v-model="currentValue.titleStyle" size="mini">
            <el-radio-button label="normal">正常</el-radio-button>
            <el-radio-button label="bold">加粗</el-radio-button>
            <el-radio-button label="italic">斜体</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">标题大小</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.titleSize" />
            <el-input-number v-model="currentValue.titleSize" size="mini" controls-position="right" :min="12" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginHorizontal" />
            <el-input-number v-model="currentValue.marginHorizontal" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">顶部边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginTop"  />
            <el-input-number v-model="currentValue.marginTop" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 默认配置
  const DEFAULT_DATA = {
    // 内容设置
    title: '',
    subTitle: '',
    jumpPage: '',
    // 样式设置
    bgColor: '',
    titleColor: '#333',
    subTitleColor: '#999',
    direction: 'column',
    align: 'center',
    titleStyle: 'normal',
    titleSize: 18,
    showMore: false,
    marginHorizontal: 0,
    marginTop: 0,
  }

  export default {
    name: "ConfigTitle",
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';
</style>
