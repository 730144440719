<template>
  <div
    :class="{
      'comp-swiper': true,
      'border-radius': config.swiperRadiusType === 'radius',
      'space-margin': config.isSpaceMargin,
    }"
    :style="layoutStyle"
  >
    <swiper :key="swiperOption.timestamp" ref="swiper" :options="swiperOption">
      <swiper-slide v-for="item in config.slideList" :key="item.id">
        <img
          v-if="item.image"
          class="banner-img"
          :src="imageBaseUrl + item.image"
          :style="'height: ' + swiperHeight + 'px'"
        />
        <EmptyBanner
          v-else
          :height="swiperHeight"
          :iconSize="30"
          iconColor="#aaa"
        />
      </swiper-slide>
    </swiper>

    <!-- 指示器 -->
    <div
      v-if="indicatorList.length > 1 && config.indicatorType !== 'none'"
      class="swiper-indicator"
      :class="['align-' + (config.indicatorAlign || 'center')]"
    >
      <div
        v-if="config.indicatorType === 'num'"
        class="indicator-item type-num"
      >
        {{ currentIndex + 1 }}/{{ indicatorList.length }}
      </div>
      <template v-else>
        <div
          v-for="(item, index) in indicatorList"
          :key="index"
          class="indicator-item"
          :class="[
            index === currentIndex ? 'indicator-active' : '',
            'type-' + (config.indicatorType || 'dot'),
          ]"
          :style="
            'background-color: ' +
            (index === currentIndex
              ? config.indicatorActiveColor
              : config.indicatorColor) +
            ';'
          "
        />
      </template>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/swiper.css";
import { imageBaseUrl } from "@/utils/config";
import EmptyBanner from "./EmptyBanner.vue";

export default {
  name: "WidgetSwiper",
  components: {
    Swiper,
    SwiperSlide,
    EmptyBanner,
  },
  directives: {
    swiper: directive,
  },
  props: {
    // 配置数据
    config: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 图片资源路径
      imageBaseUrl,
      // 轮播图高度
      swiperHeight: 200,
      // 当前轮播图索引
      currentIndex: 0,
    };
  },
  computed: {
    // 轮播图配置
    swiperOption() {
      // console.log("swiperOption", this.config);
      const that = this;
      const spaceMargin = this.config.isSpaceMargin
        ? this.config.spaceMarginValue || 0
        : 0;
      return {
        // 时间戳标识
        timestamp: new Date().getTime(),
        // 无缝斜街，对应小程序的 circular 属性
        loop: this.config.isLoop,
        // 是否自动播放，对应小程序的 autoplay 属性
        autoplay: this.config.isAutoPlay
          ? {
              // 用户交互后是否禁止自动播放，无对应小程序配置
              disableOnInteraction: false,
              // 自动轮播的间隔时间，对应小程序的 interval 属性
              delay: this.config.inteval,
            }
          : false,
        // 轮播图切换的动画速度，对应小程序的 duration 属性
        speed: this.config.duration,
        // 轮播图两侧边距，对应小程序的 previous-margin 和 	next-margin
        slidesOffsetBefore: spaceMargin,
        slidesOffsetAfter: spaceMargin,
        // 轮播区域宽度
        width: this.getSlideWidth(),
        // 事件
        on: {
          // 轮播图变更索引
          slideChange() {
            that.currentIndex = this.realIndex;
          },
        },
      };
    },

    // 指示器列表
    indicatorList() {
      if (
        !this.config.slideList ||
        (this.config.slideList.length === 1 &&
          this.config.slideList[0].image === "")
      ) {
        return new Array(3);
      }
      return new Array(this.config.slideList.length);
    },

    // 容器样式
    layoutStyle() {
      const styleObj = {};
      const { marginHorizontal, marginTop } = this.config;

      if (marginHorizontal) {
        styleObj.marginLeft = `${marginHorizontal}px`;
        styleObj.marginRight = `${marginHorizontal}px`;
      }

      if (marginTop) {
        styleObj.marginTop = `${marginTop}px`;
      }

      return styleObj;
    },
  },
  watch: {
    config: {
      handler() {
        this.updateSlideHeight();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSlideHeight();
  },
  methods: {
    // 检测轮播图的宽度
    getSlideWidth() {
      const spaceMargin = this.config.isSpaceMargin
        ? this.config.spaceMarginValue || 0
        : 0;
      const width =
        375 - (this.config.marginHorizontal || 0) * 2 - spaceMargin * 2;
      return width;
    },

    // 更新轮播图高度
    updateSlideHeight() {
      let firstBanner = "";
      if (this.config && this.config.slideList) {
        this.config.slideList.some((item) => {
          if (item.image) {
            firstBanner = item.image;
            return true;
          }
          return false;
        });
      }

      const currentWidth = this.getSlideWidth();
      if (
        this.cacheFirstBanner !== firstBanner ||
        this.cacheSlideWidth !== currentWidth
      ) {
        this.cacheFirstBanner = firstBanner;
        this.cacheSlideWidth = currentWidth;
        if (firstBanner) {
          const that = this;
          const eleImage = new Image();
          eleImage.onload = function onLoad() {
            // console.log('updateSlideHeight', currentWidth, this.width, this.height)
            that.swiperHeight = Math.round((currentWidth / this.width) * this.height);
          };
          eleImage.src = imageBaseUrl + firstBanner;
        }
      }
    },
  },
};
</script>

<style lang="scss" scope>
// 图片容器
.comp-swiper {
  position: relative;
  overflow: hidden;

  // 两侧留白切换动画
  &.space-margin {
    .swiper-slide {
      transition: 300ms;
      transform: scale(0.88);
      transform-origin: center bottom;
    }
    .swiper-slide-active {
      transform: scale(1);
    }
  }

  .banner-img {
    display: block;
  }
}

// 边框圆角
.border-radius {
  border-radius: 10px;
}

// 指示器
.swiper-indicator {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;

  &.align-left {
    left: 10px;
    justify-content: flex-start;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    right: 10px;
    justify-content: flex-end;
  }

  .indicator-item {
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    margin: 0 2px;
    background: #ffffff;

    &.type-dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    &.type-line {
      width: 12px;
      height: 2px;
    }
    &.type-num {
      height: 18px;
      padding: 0 4px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #fff;
      background-color: #000;
      opacity: 0.6;
    }
  }

  .indicator-active {
    background: #f85555;
  }
}
</style>