<template>
  <el-dialog
    class="main-dialog-form"
    :visible.sync="isVisible"
    title="订单备注"
    destroy-on-close
    @close="handleClose"
  >
    <el-form :model="formData" :rules="formRules" ref="instForm" size="small">
      <el-row>
        <el-col :span="24">
          <el-form-item class="main-form-row" label="备注：" prop="remark">
            <el-input v-model="formData.remark" type="textarea" :rows="3" maxlength="200" show-word-limit placeholder="订单备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button @click="handleConfirm" type="primary" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { postOrderRemark } from '@/api/order'

  export default {
    name: "OrderRemark",
    data() {
      return {
        // 是否显示弹框
        isVisible: false,
        // 表单数据
        formData: {
          remark: '', // 备注内容
        },
        // 表单规则
        formRules: {
          remark: [
            { required: true, message: '请输入备注内容' },
          ],
        }
      }
    },
    methods: {
      // 由外部调用显示弹框
      show({remark = ''} = {}) {
        this.formData.remark = remark
        this.isVisible = true
      },

      // 关闭弹框
      handleClose() {
        this.isVisible = false
      },

      // 点击确定
      handleConfirm() {
        this.$refs.instForm.validate((valid) => {
          if (valid) {
            this.saveFormData()
          }
        });
      },

      // 保存表单数据
      async saveFormData() {
        const res = await postOrderRemark(this.formData)
        console.log('res', res)

        this.$message({
          message: '备注成功',
          type: 'success'
        });

        this.isVisible = false
        this.$emit('confirm')
      },
    },
  };
</script>

<style lang="scss" scope>

</style>
