<template>
  <div class="main-content">
    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          fit
          ref="table"
          v-loading="loading"
        >
          <el-table-column prop="id" label="id"></el-table-column>
          <el-table-column prop="ErrorUrl" label="ErrorUrl"> </el-table-column>

          <el-table-column prop="ErrorParam" label="ErrorParam">
          </el-table-column>

          <el-table-column prop="ErrorCode" label="ErrorCode"></el-table-column>
          <el-table-column prop="ErrorData" label="ErrorData">
          </el-table-column>

          <el-table-column prop="AddTime" label="AddTime"></el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { ErrorLogList } from "@/api/ErrorLog";

export default {
  name: "RaffleActivityMemberListt",
  data() {
    return {
      tableData: [],
      loading: true,
      totalSize: 0,
      searchData: {
        limit: 25,
        page: 1,
      },
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    async getTableData() {
      this.loading = true;
      const tableData = await ErrorLogList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },

    async MallChange() {
      this.searchData.CampaignId = "";
      await this.getTableData();
      await this.getCampaignList();
    },
    ConditionSearch() {
      this.searchData.page = 1;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
