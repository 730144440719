import request from "@/utils/request";

/**
 * 获取当前模板列表
 * @param {Object}} data 参数传递
 * @returns
 */
 export function getModuleList(data) {
  return request({
    url: `/adminapi/memberpool/memberpool/ModulList`,
    method: "get",
    params: data,
  });
}

/**
 * 保存模板数据
 * @param {Object}} data 参数传递
 * @returns
 */
 export function saveModuleData(data) {
  return request({
    url: `/adminapi/memberpool/memberpool/AddModul`,
    method: "post",
    data
  });
}

/**
 * 设为首页模板
 * @param {Object}} data 参数传递
 * @returns
 */
 export function setIndexModule(data) {
  return request({
    url: `/api/memberPools/setModulsByModulId`,
    method: "get",
    params: data,
  });
}

/**
 * 删除指定模板
 * @param {Object}} data 参数传递
 * @returns
 */
 export function deleteModule(data) {
  return request({
    url: `/adminapi/memberpool/memberpool/DelModul`,
    method: "get",
    params: data,
  });
}