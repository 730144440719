<template>
  <div
    :class="{
      'comp-nav': true,
      'border-radius': config.bgRadiusType === 'radius'
    }"
    :style="layoutStyle"
  >
    <div
      v-if="config.navList && config.navList.length > 0"
      class="nav-list"
      :class="['rowpernum-' + config.rowPerNum]"
    >
      <div v-for="(item) in config.navList" :key="item.id" class="nav-item">
        <div class="nav-icon-box" :class="'is-' + config.iconRadiusType">
          <img v-if="item.image" class="nav-icon-img" :src="imageBaseUrl + item.image" alt="" />
          <EmptyBanner v-else full :iconSize="16" iconColor="#aaa" />
        </div>
        <div class="nav-text" :style="textStyle">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { imageBaseUrl } from "@/utils/config";
  import EmptyBanner from './EmptyBanner.vue'

  export default {
    name: "WidgetNavList",
    components: {
      EmptyBanner,
    },
    props: {
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        // 图片资源路径
        imageBaseUrl,
      }
    },
    computed: {
      // 容器样式
      layoutStyle() {
        const styleObj = {}
        const {bgColor, marginHorizontal, marginTop} = this.config

        if (bgColor) {
          styleObj.backgroundColor = bgColor
        }

        if (marginHorizontal) {
          styleObj.marginLeft = `${marginHorizontal}px`
          styleObj.marginRight = `${marginHorizontal}px`
        }

        if (marginTop) {
          styleObj.marginTop = `${marginTop}px`
        }

        return styleObj;
      },

      // 文字样式
      textStyle() {
        const styleObj = {}
        const {textColor} = this.config

        if (textColor) {
          styleObj.color = textColor
        }

        return styleObj;
      },
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
// 导航容器
.comp-nav {
  overflow: hidden;
}

// 边框圆角
.border-radius {
  border-radius: 10px;
}

// 图片列表
.nav-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0 0;

  &.rowpernum-3 {
    padding-left: 6%;
    padding-right: 6%;

    .nav-item {
      width: 33.33%;
    }
  }

  &.rowpernum-4 {
    padding-left: 4%;
    padding-right: 4%;

    .nav-item {
      width: 25%;
    }
  }

  &.rowpernum-5 {
    padding-left: 2%;
    padding-right: 2%;

    .nav-item {
      width: 20%;
    }
  }
}
.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.nav-icon-box {
  overflow: hidden;
  width: 40px;
  height: 40px;

  &.is-radius {
    border-radius: 50%;
  }
}
.nav-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}
.nav-text {
  max-width: 100%;
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
</style>
