<template>
  <div class="memberPool">
    <cc-title :titleD="'商户审核'" :titleX="''"></cc-title>
    <div class="filter-container"></div>

    <div class="filter-container"></div>

    <el-table :data="tableData" style="width: 100%" border fit ref="table">
      <el-table-column type="index" align="center" label="序号" width="50">
      </el-table-column>

      <el-table-column prop="name" label="用户名"></el-table-column>
      <el-table-column prop="shopname" label=" 店铺名称"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="state" label="状态"> </el-table-column>

      <el-table-column label="控制" align="center" width="200">
        <!--  -->
        <template slot-scope="scope">
          <el-button
            v-on:click="
              () => {
                showAddMarkingDialog({
                  type: 'updata',
                  ...scope.row,
                  mark_type: '1'
                });
              }
            "
            size="mini"
            type="primary"
            style="width: 60px"
            >通过</el-button
          >
          <el-button
            v-on:click="
              () => {
                markDelBtn({
                  mark_id: scope.row.mark_id
                });
              }
            "
            size="mini"
            type="danger"
            style="width: 60px"
            >停用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footpagination"></div>
  </div>
</template>
<script>
let timeFn;
export default {
  name: "memberPool",
  data() {
    return {
      tableData: [
        {
          name: "yuyi",
          shopname: "天猫1",
          email: "yuyiux.com",
          phone: 135123456,
          state: "待审核"
        }
      ],
      tableData2: [],
      behavioralConditionsList: [],

      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",

      options: [
        {
          value: "1",
          label: "全部"
        }
      ],
      formData: {
        mark_name: "",
        condition_id: [],
        mark_id: "",
        mark_type: ""
      },
      searchData: {
        mark_name: "",
        mark_type: "1"
      },
      searchData2: {
        mark_name: "",
        mark_type: "2"
      }
    };
  },
  created() {},
  mounted() {},
  destroyed() {
    clearInterval(timeFn);
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
