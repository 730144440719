import request from "@/utils/request";

export function getCounterList(data) {
  return request({
    url: "/adminapi/counter/counter/list",
    method: "get",
    params: data
  });
}

//

export function counterSave(data) {
  return request({
    url: "/adminapi/counter/counter/save",
    method: "post",
    data
  });
}

export function counterDel(data) {
  return request({
    url: "/adminapi/counter/counter/del",
    method: "get",
    params: data
  });
}
