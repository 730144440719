<template>
  <el-menu
    class="page-side-menu"
    :default-active="activeName"
    background-color="#0E396C"
    text-color="#D5D7DB"
    active-text-color="#FFFFFF"
    :collapse="isCollapseMenu && deviceType === 'desktop'"
    :collapse-transition="false"
  >
    <SideNavItem
      v-for="navItem in menuList"
      :key="navItem.name"
      :info="navItem"
    />
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
import routes from "@/router/routes";
import SideNavItem from "./SideNavItem.vue";

export default {
  name: "SideNav",
  components: {
    SideNavItem,
  },
  data() {
    return {
      // 菜单列表
      menuList: [],
      // 激活的菜单
      activeName: "",
    };
  },
  computed: {
    ...mapState({
      isCollapseMenu: (state) => state.isCollapseMenu,
      deviceType: (state) => state.deviceType,
    }),
  },
  watch: {
    $route() {
      this.activeName = this.$route.name;
    },
  },
  created() {
    this.activeName = this.$route.name;
    this.checkMenuList();
  },
  mounted() {},
  methods: {
    // 检测菜单列表
    checkMenuList() {
      const menuList = [];

      // 检测导航菜单是否没有子级菜单显示
      routes.forEach((item) => {
        let hideSubMenu = false;
        if (item.children) {
          hideSubMenu = item.children.every((child) => {
            if (child.meta === undefined || !child.meta.title || child.meta.hideMenu) {
              return true;
            }
            return false;
          });
        }
        if (item.meta) {
          item.meta.hideSubMenu = hideSubMenu;
        }
      });

      // 获取用户显示的菜单信息
      this.userMenuShowInfo = this.getUserMenuShowInfo();

      // 检测子级菜单
      this.checkMenuListChildren(menuList, routes);

      // 返回用于渲染的菜单列表
      this.menuList = menuList;
      // console.log("menuList", menuList);
    },

    // 获取用户显示的菜单信息
    getUserMenuShowInfo() {
      let userMenuShowList = [];
      const MallCode = localStorage.getItem("MallCode");
      console.log("MallCode===>>", MallCode);

      if (MallCode !== "null") {
        console.log("MallCode", MallCode, "list1");
        userMenuShowList = [
          {
            name: "memberPool",
          },
          {
            name: "contentManage",
            children: [{ name: "storeList" }],
          },
          {
            name: "activityManage",
            children: [{ name: "ActivityList" }, { name: "RaffleMember" }],
          },
        ];
      } else {
        console.log("MallCode", MallCode, "listxxxxxx1");
        userMenuShowList = [
          {
            name: "memberPool",
          },
          {
            name: "memberTag",
            children: [{ name: "memberCondition" }, { name: "memberGroup" }],
          },
          {
            name: "contentManage",
            children: [
              { name: "storeList" },
              { name: "bannerList" },
              { name: "ProductListofPointMall" },
            ],
          },
          {
            name: "activityManage",
            children: [{ name: "ActivityList" }, { name: "RaffleMember" }],
          },
          {
            name: "productManage",
            children: [
              { name: "productList" },
              { name: "productAdd" },
              { name: "productClassify" },
              { name: "productAttr" },
            ],
          },
          {
            name: "orderManage",
            children: [{ name: "OrderList" }],
          },
          {
            name: "accountManage",
            children: [{ name: "adminList" }, { name: "BCList" }],
          },
          {
            name: "designManage",
          },
          {
            name: "errorLog",
          },
        ];
      }
      const userMenuShowInfo = {};
      this.checkUserMenuInfoChildren(userMenuShowInfo, userMenuShowList);
      // console.log("userMenuShowInfo", userMenuShowInfo);
      return userMenuShowInfo;
    },

    // 检测用户显示的子级菜单
    checkUserMenuInfoChildren(userMenuShowInfo, children) {
      children.forEach((item) => {
        userMenuShowInfo[item.name] = item; // 将显示的菜单平级罗列出来
        if (item.children && item.children.length > 0) {
          this.checkUserMenuInfoChildren(userMenuShowInfo, item.children);
        }
      });
    },

    // 检测子级菜单
    checkMenuListChildren(menuList, children) {
      children.forEach((item) => {
        const hasAuth = !!this.userMenuShowInfo[item.name]; // 用户具备菜单权限
        const showMenu = item.meta && !item.meta.hideMenu; // 显示菜单
        if (hasAuth && showMenu) {
          const menuItem = { name: item.name, children: [], ...item.meta };
          const hideSubMenu = item.meta && item.meta.hideSubMenu;
          if (item.children && item.children.length > 0 && !hideSubMenu) {
            this.checkMenuListChildren(menuItem.children, item.children);
          }
          menuList.push(menuItem);
        }
      });
    },
  },
};
</script>
