<template>
  <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" />
  <svg v-else :class="['svg-icon', className || '']" aria-hidden="true">
    <use :href="`#icon-${this.iconName}`" />
  </svg>
</template>

<script>
/**
 * @fileoverview SVG图标组件 (参考地址 https://juejin.cn/post/6844903517564436493)
*/

export default {
  name: 'SvgIcon',
  props: {
    // 图标的名称
    iconName: { type: String, required: true },
    // 自定义类名
    className: { type: String, default: '' },
  },
  computed: {
    // 是否为外部svg
    isExternal() {
      return /^(https?:|mailto:|tel:)/.test(this.iconName)
    },

    // 外部svg引入
    styleExternalIcon() {
      return {
        mask: `url(${this.iconName}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconName}) no-repeat 50% 50%`,
      }
    },
  },
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentColor;
}

.svg-external-icon {
  display: inline-block;
  background-color: currentColor;
  mask-size: cover !important;
}
</style>
