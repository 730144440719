<template>
  <el-form-item
    v-if="options.type === 'string' && (!options.checkShow || options.checkShow(formData))"
    :label="options.label ? options.label + '：' : undefined"
    :class="{'base-form-item': true, 'base-form-item--hide-label': options.hideLabel}"
  >
    <span :style="options.style" :class="options.className">{{options.content}}</span>
  </el-form-item>
  <el-form-item
    v-else-if="!options.checkShow || options.checkShow(formData)"
    :label="options.label ? options.label + '：' : undefined"
    :class="[
      {
        'base-form-item': true,
        'base-form-item--hide-label': options.hideLabel,
        'base-form-item--prefix': options.prefix,
        'base-form-item--suffix': options.suffix
      },
      options.className || '',
    ]"
    :style="options.style"
    :prop="options.name"
  >
    <div class="base-form-item__content">
      <div
        v-if="options.prefix"
        :class="{ 'el-input-group__prepend': true }"
      >
        <span v-if="typeof options.prefix === 'string'">{{options.prefix}}</span>
        <el-select
          v-else-if="options.prefix.type === 'select'"
          v-model="formData[options.prefix.name]"
          placeholder="请选择"
          :style="options.prefix.style"
          :class="options.prefix.className"
        >
          <el-option
            v-for="(item) in options.prefix.dataList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <el-input
        v-if="options.type==='text'"
        v-model="formData[options.name]"
        type="text"
        :placeholder="options.placeholder"
        :maxlength="options.maxlength"
        :show-word-limit="options.showWordLimit"
        :clearable="options.clearable"
        :disabled="options.disabled"
        :readonly="options.readonly"
        @keyup.enter.native="handleOnEnter"
        @change="handleChange"
      ></el-input>

      <el-input
        v-if="options.type==='textarea'"
        v-model="formData[options.name]"
        type="textarea"
        :placeholder="options.placeholder"
        :maxlength="options.maxlength"
        :show-word-limit="options.showWordLimit"
        :clearable="options.clearable"
        :disabled="options.disabled"
        :readonly="options.readonly"
        :rows="options.rows || 3"
        @change="handleChange"
      ></el-input>

      <el-input-number
        v-else-if="options.type==='number'"
        v-model="formData[options.name]"
        :placeholder="options.placeholder"
        controls-position="right"
        :min="options.min || 0"
        :max="options.max"
        :step="options.step || 1"
        :precision="options.precision || undefined"
        :step-strictly="options.stepStrictly || false"
        :disabled="options.disabled"
        @change="handleChange"
      ></el-input-number>

      <el-date-picker
        v-else-if="options.type==='date'"
        v-model="formData[options.name]"
        type="date"
        :format="options.format || 'yyyy-MM-dd'"
        :value-format="options.valueFormat || 'yyyy-MM-dd'"
        :placeholder="options.placeholder"
        :disabled="options.disabled"
        @change="handleChange"
      ></el-date-picker>

      <el-date-picker
        v-else-if="options.type==='daterange'"
        v-model="formData[options.name]"
        type="daterange"
        :format="options.format || 'yyyy-MM-dd'"
        :value-format="options.valueFormat || 'yyyy-MM-dd'"
        :unlink-panels="options.unlinkPanels || true"
        :range-separator="options.rangeSeparator || '至'"
        :start-placeholder="options.startPlaceholder || '开始日期'"
        :end-placeholder="options.endPlaceholder || '结束日期'"
        :disabled="options.disabled"
        @change="handleChange"
      ></el-date-picker>

      <el-date-picker
        v-else-if="options.type==='datetime'"
        v-model="formData[options.name]"
        type="datetime"
        :format="options.format || 'yyyy-MM-dd HH:mm:ss'"
        :value-format="options.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
        :placeholder="options.placeholder"
        :disabled="options.disabled"
        @change="handleChange"
      ></el-date-picker>

      <el-date-picker
        v-else-if="options.type==='datetimerange'"
        v-model="formData[options.name]"
        type="datetimerange"
        :format="options.format || 'yyyy-MM-dd HH:mm:ss'"
        :value-format="options.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
        :unlink-panels="options.unlinkPanels || true"
        :range-separator="options.rangeSeparator || '至'"
        :start-placeholder="options.startPlaceholder || '开始时间'"
        :end-placeholder="options.endPlaceholder || '结束时间'"
        :disabled="options.disabled"
        @change="handleChange"
      ></el-date-picker>

      <el-select
        v-else-if="options.type==='select'"
        v-model="formData[options.name]"
        :placeholder="options.placeholder || '请选择'"
        filterable
        clearable
        :multiple="options.multiple"
        :multiple-limit="options.multipleLimit"
        :collapse-tags="options.collapseTags"
        :allow-create="options.allowCreate"
        :disabled="options.disabled"
        @change="handleChange"
      >
        <el-option
          v-for="selectItem in options.dataList"
          :key="selectItem.value"
          :label="options.labelName ? selectItem[options.labelName] : selectItem.label"
          :value="options.valueName ? selectItem[options.valueName] : selectItem.value"
        ></el-option>
      </el-select>

      <el-radio-group
        v-else-if="options.type==='radio'"
        v-model="formData[options.name]"
        :disabled="options.disabled"
        :class="{'vue-layout-column' : options.layoutColumn}"
        @change="handleChange"
      >
        <el-radio
          v-for="radioItem in options.dataList"
          :key="radioItem.value"
          :label="options.valueName ? radioItem[options.valueName] : radioItem.value"
          :disabled="radioItem.disabled"
        >{{options.labelName ? radioItem[options.labelName] : radioItem.label}}
        </el-radio>
      </el-radio-group>

      <el-radio-group
        v-else-if="options.type==='radioButton'"
        v-model="formData[options.name]"
        :disabled="options.disabled"
        size="small"
        @change="handleChange"
      >
        <el-radio-button
          v-for="radioItem in options.dataList"
          :key="radioItem.value"
          :label="options.valueName ? radioItem[options.valueName] : radioItem.value"
          :disabled="radioItem.disabled"
        >{{options.labelName ? radioItem[options.labelName] : radioItem.label}}
        </el-radio-button>
      </el-radio-group>

      <el-checkbox-group
        v-else-if="options.type==='checkbox' && !options.isBoolean"
        v-model="formData[options.name]"
        :class="{'vue-layout-column' : options.layoutColumn}"
        :disabled="options.disabled"
        @change="handleChange"
      >
        <el-checkbox
          v-for="checkItem in options.dataList"
          :key="checkItem.value"
          :label="options.valueName ? checkItem[options.valueName] : checkItem.value"
          :disabled="checkItem.disabled"
        >{{options.labelName ? checkItem[options.labelName] : checkItem.label}}
        </el-checkbox>
      </el-checkbox-group>

      <el-checkbox
        v-else-if="options.type==='checkbox' && options.isBoolean"
        v-for="checkItem in options.dataList"
        :key="checkItem.value"
        v-model="formData[options.name]"
        :label="options.valueName ? checkItem[options.valueName] : checkItem.value"
        :disabled="checkItem.disabled"
        @change="handleChange"
      >{{options.labelName ? checkItem[options.labelName] : checkItem.label}}
      </el-checkbox>

      <UpLoadFile
        v-else-if="options.type==='upload'"
        v-model="formData[options.name]"
        :acceptType="options.acceptType"
        :limit="options.limit"
        :size="options.size"
        :title="options.title"
        :message="options.message"
        :extraData="item"
        :disabled="options.disabled"
        @change="handleUploadChange"
      ></UpLoadFile>

      <el-button
        v-else-if="options.type === 'button'"
        :type="options.buttonType"
        :icon="options.buttonIcon"
        :class="[!options.buttonText ? 'is-buton-no-text' : '']"
        @click="hanldeClick(options.onClick)"
      >{{options.buttonText}}</el-button>

      <div
        v-if="options.suffix"
        :class="{
          'el-input-group__append': true,
          'is-button': options.suffix && options.suffix.type === 'button',
          'is-button-notext': options.suffix && options.suffix.type === 'button' && !options.suffix.buttonText,
        }"
      >
        <span v-if="typeof options.suffix === 'string'">{{options.suffix}}</span>
        <el-button
          v-else-if="options.suffix.type === 'button'"
          :type="options.suffix.buttonType"
          :icon="options.suffix.buttonIcon"
          :style="options.suffix.style"
          :class="options.suffix.className"
          @click="hanldeClick(options.suffix.onClick)"
        >
          {{options.suffix.buttonText}}
        </el-button>
      </div>
    </div>
  </el-form-item>
</template>

<script>
import UpLoadFile from '@/components/UploadFile/UploadFile.vue'

export default {
  name: "FormItem",
  components: {
    UpLoadFile,
  },
  props: {
    // 更新标识，该标识发生变更表示当前组件被外请请求需要更新
    updateFlag: { type: [Number, String], required: false, default: undefined },
    // 表单项的配置
    options: {
      type: Object,
      required: false,
      default(){
        return {
          type: 'text', // 表单类型
          name: 'name', // 表单name
          label: '文本输入框', // 表单label
          placeholder : '', // 占位文本
          defaultValue: '', // 默认值
          rules: {}, // 校验规则
          grid: {}, // 栅格布局
          style: undefined, // 样式自定义
          className: undefined, // class类名自定义
        };
      }
    },
    // 表单数据
    formData: { type: Object, required: false, default(){ return {}; } },
  },
  data() {
    return {}
  },
  watch: {
    updateFlag() {
      this.$forceUpdate()
    },
  },
  computed: {
  },
  created() {
  },
  methods: {
    // 上传组件的变更事件
    handleUploadChange(fileResponse, flag, extraData) {
      this.$emit('uploadChange', fileResponse, flag, extraData)
    },

    // 变更事件
    handleChange() {
      this.$emit('change', this.options)
    },

    // 文本框回车事件
    handleOnEnter() {
      this.$emit('enter', this.options)
    },

    // 点击事件
    hanldeClick(callback) {
      if (typeof callback === 'function') {
        callback(this.formData)
      }
    },
  }
};
</script>