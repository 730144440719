<template>
  <div class="memberPool">
    <cc-title :titleD="'商户注册'" :titleX="''"></cc-title>

    <el-steps :active="stepsActive" align-center>
      <el-step title="步骤1" description="基本信息"></el-step>
      <el-step title="步骤2" description="联系方式"></el-step>
      <el-step title="步骤3" description="天猫店铺信息"></el-step>
      <el-step title="步骤4" description="注册完成"></el-step>
    </el-steps>
    <div class="info">
      <div class="info-box">
        <template v-if="stepsActive == 0">
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">用户名：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="用户名"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">密码：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="密码"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">密码确认：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="密码确认"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">验证码：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="验证码"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
        </template>
        <template v-if="stepsActive == 1">
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">手机号：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="手机号"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf">
              <div class="dialog-title">邮箱：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 350px;"
                placeholder="邮箱"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
        </template>
        <template v-if="stepsActive == 2">
          <div class="dialog-box">
            <div class="dialog-letf" style="width: 130px;">
              <div class="dialog-title" style="width: 130px;">店铺名称：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 300px;"
                placeholder="店铺名称"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf" style="width: 130px;">
              <div class="dialog-title" style="width: 130px;">
                店铺名称缩写：
              </div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 300px;"
                placeholder="店铺名称首字母缩写"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>

          <div class="dialog-box">
            <div class="dialog-letf" style="width: 130px;">
              <div class="dialog-title" style="width: 130px;">店铺AppID：</div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 300px;"
                placeholder="天猫店铺AppID"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
          <div class="dialog-box">
            <div class="dialog-letf" style="width: 130px;">
              <div class="dialog-title" style="width: 130px;">
                店铺AppSecret：
              </div>
            </div>
            <div class="dialog-right">
              <el-input
                class="filter-item"
                style="width: 300px;"
                placeholder="天猫店铺AppSecret"
                v-model="formData.mark_name"
              ></el-input>
            </div>
          </div>
        </template>
        <template v-if="stepsActive == 3">
          <div class="dialog-box">
            注册完成等待审核
          </div>
        </template>

        <div class="dialog-btn-box">
          <el-button v-if="stepsActive != 3" type="info" @click="prev"
            >上一步</el-button
          >
          <div v-if="stepsActive != 3" style="width: 30px;"></div>
          <el-button v-if="stepsActive != 3" type="primary" @click="next"
            >下一步</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {

// } from "@/api/memberGroup";

let timeFn;
export default {
  name: "memberPool",
  data() {
    return {
      stepsActive: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",

      formData: {
        mark_name: "",
        condition_id: [],
        mark_id: "",
        mark_type: ""
      }
    };
  },
  created() {},
  mounted() {},
  destroyed() {
    clearInterval(timeFn);
  },
  methods: {
    prev() {
      if (this.stepsActive > 0) this.stepsActive -= 1;
    },
    next() {
      if (this.stepsActive < 3) this.stepsActive += 1;
    },
    stopTimeFn() {
      clearInterval(timeFn);
    },

    JumpMemberPoll(res) {
      const { mark_id, status } = res;

      if (status !== 4) return;

      const routeData = this.$router.resolve({
        name: "memberPool",
        query: { mark_id }
      });
      window.open(routeData.href, "_blank");
      console.log(mark_id);
    }
  }
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}
.info {
  display: flex;
  padding-top: 50px;
  justify-content: center;
}
.info-box {
  padding: 36px 60px;
  border-radius: 8px;
  width: 553px;
  border: 1px solid var(--cb-color-border-tertiary, #ebebeb);
  box-shadow: 0 0 4px 0 var(--cb-color-shadow, rgba(0, 0, 0, 0.16));
}
.dialog-btn-box {
  padding: 15px 0;
  display: flex;
  justify-content: center;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
