<template>
  <div
    :class="[
      'comp-goods',
      'bg-' + config.bgRadiusType,
      'cont-' + config.contRadiusType
    ]"
    :style="layoutStyle"
  >
    <div :class="'goods-' + config.layoutType">
      <div
        v-for="(item, index) in goodsList"
        :key="item.id"
        :class="[
          'goods-item',
          (config.layoutType === 'double' && index >= 2) || (config.layoutType === 'triple' && index >= 3) || (config.layoutType === 'banner' && index >= 1) ? 'is-mgt' : '',
          (config.layoutType === 'double' && index % 2 !== 0) || (config.layoutType === 'triple' && index % 3 !== 0) ? 'is-mgl' : '',
        ]">
        <div class="goods-pic" :style="goodsImgStyle">
          <img v-if="item.image" class="goods-pic-img" :src="imageBaseUrl + item.image" alt="" />
          <EmptyBanner v-else :full="config.layoutType !== 'banner'" :iconSize="24" iconColor="#aaa" />
        </div>
        <div class="goods-cont">
          <div class="goods-title" :class="['is-rows-' + config.titleRows]" :style="titleStyle" v-if="config.showGoodsName">{{item.store_name}}</div>
          <div class="goods-price">
            <div class="price-value" :style="priceStyle" v-if="config.showPrice">&yen;{{item.price}}</div>
            <div v-if="item.oldPrice && config.showOldPrice" class="price-old" :style="oldPriceStyle">&yen;{{item.oldPrice}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { fetchProdctList } from "@/api/product";
  import { imageBaseUrl } from "@/utils/config";
  import EmptyBanner from './EmptyBanner.vue'

  // 生成默认的商品
  const makeDefaultGoodsItem = () => {
    return  { id: 1001, store_name: '商品名称', price: 66.66, oldPrice: 99.99 }
  }

  export default {
    name: "WidgetGoodsList",
    components: {
      EmptyBanner,
    },
    props: {
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        // 图片资源路径
        imageBaseUrl,
        // 商品列表
        goodsList: [makeDefaultGoodsItem()],
      }
    },
    computed: {
      // 容器样式
      layoutStyle() {
        const styleObj = {}
        const {bgColor, marginHorizontal, marginTop} = this.config

        if (bgColor) {
          styleObj.backgroundColor = bgColor
        }

        if (marginHorizontal) {
          styleObj.marginLeft = `${marginHorizontal}px`
          styleObj.marginRight = `${marginHorizontal}px`
        }

        if (marginTop) {
          styleObj.marginTop = `${marginTop}px`
        }

        return styleObj;
      },

      // 标题样式
      titleStyle() {
        const styleObj = {}
        const {titleColor} = this.config

        if (titleColor) {
          styleObj.color = titleColor
        }

        return styleObj;
      },

      // 价格样式
      priceStyle() {
        const styleObj = {}
        const {priceColor} = this.config

        if (priceColor) {
          styleObj.color = priceColor
        }

        return styleObj;
      },

      // 划线价样式
      oldPriceStyle() {
        const styleObj = {}
        const {oldPriceColor} = this.config

        if (oldPriceColor) {
          styleObj.color = oldPriceColor
        }

        return styleObj;
      },

      // 商品图片样式
      goodsImgStyle() {
        const styleObj = {}

        if (this.config.layoutType === 'double') {
          styleObj.height = `${Math.floor((375 - this.config.marginHorizontal * 2 - 10) / 2)}px`
        } else if (this.config.layoutType === 'triple') {
          styleObj.height = `${Math.floor((375 - this.config.marginHorizontal * 2 - 10) / 3)}px`
        }

        return styleObj;
      },
    },
    watch: {
      config: {
        handler() {
          this.checkGoodsUpdate()
        },
        deep: true
      },
    },
    created() {
      this.cacheChooseType = 'auto'
      this.cacheClassifyValue = ''
      this.cacheGoodsNum = 6
    },
    mounted() {
      this.checkGoodsUpdate()
    },
    methods: {
      // 检测商品数据的更新
      checkGoodsUpdate() {
        if (
          this.config.chooseType === 'auto'
          && (
            this.cacheChooseType !== this.config.chooseType // 从手动选择切换到自动选择
            || this.cacheClassifyValue !== this.config.classifyValue // 商品分类发生变更
            || this.cacheGoodsNum !== this.config.goodsNum // 展示数量发生变更
          )
        ) {
          this.cacheChooseType = this.config.chooseType
          this.getGoodsList()
          return
        }

        if (
          this.config.chooseType === 'custom'
          && (
            this.cacheChooseType !== this.config.chooseType // 从自动选择切换到手动选择
            || JSON.stringify(this.config.chooseGoodsList) !== this.cacheGoodsListStr // 手动选择的商品列表发生变更
          )
        ) {
          this.cacheChooseType = this.config.chooseType
          if (!this.config.chooseGoodsList || this.config.chooseGoodsList.length === 0) {
            this.goodsList = [makeDefaultGoodsItem()]
          } else {
            this.goodsList = this.config.chooseGoodsList
          }
          this.cacheGoodsListStr = JSON.stringify(this.goodsList)
        }
      },

      // 获取商品列表
      async getGoodsList() {
        this.cacheClassifyValue = this.config.classifyValue
        this.cacheGoodsNum = this.config.goodsNum
        const searchData = {
          cate_id: this.config.classifyValue || '', // 商品分类
          keywords: '',
          page: 1,
          limit: this.config.goodsNum > 100 ? 100 : this.config.goodsNum,
          MallCode: "",
          type: 1,
          is_virtual: "2",
        }
        const { data } = await fetchProdctList(searchData);
        this.goodsList = data.list;
      },
    },
  };
</script>

<style lang="scss" scope>
// 导航容器
.comp-goods {
  overflow: hidden;

  // 背景圆角
  &.bg-radius {
    border-radius: 10px;
  }

  // 内容圆角
  &.cont-radius {
    .goods-single .goods-pic {
      border-radius: 10px;
    }
    .goods-double .goods-item {
      border-radius: 10px;
    }
    .goods-triple .goods-item {
      border-radius: 10px;
    }
    .goods-banner .goods-item {
      border-radius: 10px;
    }
  }
}

// ===================================================================
// ===================================================================
// ===================================================================
// 单列布局
.goods-single {

  .goods-item {
    display: flex;
    flex-direction: row;
    padding: 10px 12px;
    background-color: #fff;
  }

  .goods-pic {
    overflow: hidden;
    width: 110px;
    height: 110px;
    flex-shrink: 0;
  }

  .goods-pic-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .goods-cont {
    min-width: 10px;
    flex-grow: 1;
    padding: 5px 10px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .goods-title {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .goods-price {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .price-value {
    font-size: 18px;
    line-height: 20px;
    color: #e93323;
  }

  .price-old {
    margin-left: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #aaa;
    text-decoration: line-through;
  }
}

// ===================================================================
// ===================================================================
// ===================================================================
// 两列布局
.goods-double {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .goods-item {
    overflow: hidden;
    width: 40%;
    max-width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &.is-mgl {
      margin-left: 10px;
    }

    &.is-mgt {
      margin-top: 10px;
    }
  }

  .goods-pic {
    overflow: hidden;
    flex-shrink: 0;
  }

  .goods-pic-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .goods-cont {
    padding: 6px 8px;
  }

  .goods-title {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    &.is-rows-1 {
      -webkit-line-clamp: 1;
    }

    &.is-rows-2 {
      height: 40px;
      -webkit-line-clamp: 2;
    }
  }

  .goods-price {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .price-value {
    font-size: 18px;
    line-height: 28px;
    color: #e93323;
  }

  .price-old {
    margin-left: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #aaa;
    text-decoration: line-through;
  }
}


// ===================================================================
// ===================================================================
// ===================================================================
// 三列布局
.goods-triple {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .goods-item {
    overflow: hidden;
    width: 30%;
    max-width: 33%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &.is-mgl {
      margin-left: 10px;
    }

    &.is-mgt {
      margin-top: 10px;
    }
  }

  .goods-pic {
    overflow: hidden;
    flex-shrink: 0;
  }

  .goods-pic-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .goods-cont {
    padding: 6px 8px;
  }

  .goods-title {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    &.is-rows-1 {
      -webkit-line-clamp: 1;
    }

    &.is-rows-2 {
      height: 40px;
      -webkit-line-clamp: 2;
    }
  }

  .goods-price {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
  }

  .price-value {
    order: 1;
    font-size: 18px;
    line-height: 28px;
    color: #e93323;
  }

  .price-old {
    order: 0;
    font-size: 13px;
    line-height: 15px;
    color: #aaa;
    text-decoration: line-through;
  }
}

// ===================================================================
// ===================================================================
// ===================================================================
// 大图布局
.goods-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .goods-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &.is-mgt {
      margin-top: 15px;
    }
  }

  .goods-pic {
    overflow: hidden;
    flex-shrink: 0;
  }

  .goods-pic-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .goods-cont {
    padding: 10px 15px;
  }

  .goods-title {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .goods-price {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .price-value {
    font-size: 18px;
    line-height: 28px;
    color: #e93323;
  }

  .price-old {
    margin-left: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #aaa;
    text-decoration: line-through;
  }
}
</style>