import Vue from "vue";
import ElementUI from "element-ui";
import Viewer from 'v-viewer'

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "element-ui/lib/theme-chalk/index.css";
import 'viewerjs/dist/viewer.css';

import App from "./App.vue";
import store from './store/store'
import router from "./router/router";

import oTitle from "./components/title.vue";
import UploadFile from './components/UploadFile/UploadFile.vue'
import FormLayout from './components/FormLayout/FormLayout.vue'
import FormItem from './components/FormLayout/FormItem.vue'

import "./styles/theme/index.css"; // 使用自定义主题色
import "./styles/index.scss"; // global css
import "./icons"; // icon

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
Vue.component("cc-title", oTitle);
Vue.component("UploadFile", UploadFile);
Vue.component("FormLayout", FormLayout);
Vue.component("FormItem", FormItem);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
