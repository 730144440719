<template>
  <div class="main-content">
    <!-- tab切换 -->
    <div class="main-tab">
      <el-tabs v-model="tabValue">
        <el-tab-pane
          v-for="(item) in tabList"
          :key="item.value"
          :name="item.value"
          :label="item.label"
        />
      </el-tabs>
    </div>

    <!-- 会员行为条件 -->
    <div v-show="tabValue === 'conditionBehavior'">
      <!-- 筛选表单 -->
      <div class="main-section">
        <div class="filter-container">
          <el-input
            style="width: 200px"
            class="filter-item"
            v-model="DynamicConditionSearchData.condition_name"
            placeholder="条件名称"
            clearable
          ></el-input>

          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="DynamicConditionSearch"
            icon="el-icon-search"
            >搜索条件</el-button
          >
        </div>
      </div>

      <!-- 主体内容区 -->
      <div class="main-section">
        <!-- 操作按钮 -->
        <div class="list-operate">
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="() => CreateDynamicCondition({ type: 'add' })"
            icon="el-icon-circle-plus-outline"
            >创建条件</el-button
          >
        </div>

        <!-- 列表数据 -->
        <div class="list-table">
          <el-table
            :data="DynamicConditionTableData"
            style="width: 100%"
            border
            fit
            ref="table"
          >
            <el-table-column type="index" label="序号" width="50" align="center">
            </el-table-column>

            <el-table-column prop="condition_name" label="条件名称">
            </el-table-column>
            <el-table-column prop="addtime" label="条件细节">
              <template slot-scope="scope">
                {{ CopywritingOutput(scope.row) }}
              </template>
            </el-table-column>

            <el-table-column prop="addtime" label="创建日期"> </el-table-column>

            <el-table-column label="控制" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  v-on:click="
                    () => CreateDynamicCondition({ type: 'updata', ...scope.row })
                  "
                  size="mini"
                  type="primary"
                  style="width: 60px"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  v-on:click="
                    () => {
                      conditionsDelbtn({
                        condition_id: scope.row.id
                      });
                    }
                  "
                  type="danger"
                  style="width: 60px"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 会员数据条件 -->
    <div v-show="tabValue === 'conditionData'">
      <!-- 筛选表单 -->
      <div class="main-section">
        <div class="filter-container">
          <el-input
            style="width: 200px"
            class="filter-item"
            v-model="StaticConditionSearchData.condition_name"
            placeholder="条件名称"
            clearable
          ></el-input>

          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="StaticConditionSearch"
            icon="el-icon-search"
            >搜索条件</el-button
          >
        </div>
      </div>

      <!-- 主体内容区 -->
      <div class="main-section">
        <!-- 操作按钮 -->
        <div class="list-operate">
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="CreateStaticCondition({ type: 'add' })"
            icon="el-icon-circle-plus-outline"
            >创建条件</el-button
          >
        </div>

        <!-- 列表数据 -->
        <div class="list-table">
          <el-table
            :data="StaticconditionTableData"
            style="width: 100%"
            border
            fit
            ref="table"
          >
            <el-table-column type="index" label="序号" width="50"> </el-table-column>

            <el-table-column prop="condition_name" label="条件名称">
            </el-table-column>
            <el-table-column prop="addtime" label="条件细节">
              <template slot-scope="scope">
                {{ CopywritingOutput(scope.row) }}
              </template>
            </el-table-column>

            <el-table-column prop="addtime" label="创建日期"> </el-table-column>

            <el-table-column label="删除控制" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  v-on:click="
                    () => CreateStaticCondition({ type: 'updata', ...scope.row })
                  "
                  size="mini"
                  type="primary"
                  style="width: 60px"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  v-on:click="
                    () => {
                      conditionsDelbtn({
                        condition_id: scope.row.id
                      });
                    }
                  "
                  type="danger"
                  style="width: 60px"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog
      :title="
        DynamicConditionDialogType === 'add'
          ? '创建会员行为条件'
          : '修改会员行为条件'
      "
      :visible.sync="DynamicConditionDialog"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">条件名称：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px;"
            placeholder=""
            v-model="formData.condition_name"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">
            条件类型：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.number_type">
            <el-radio border label="1">回访次数</el-radio>
            <el-radio border label="2">回访转化次数</el-radio>
            <el-radio border label="3">回访转化金额</el-radio>
            <el-radio border label="4">购买次数</el-radio>
            <el-radio border label="5">购买金额</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div
        class="dialog-box"
        v-if="
          formData.number_type === '1' ||
            formData.number_type === '2' ||
            formData.number_type === '3' ||
            formData.number_type === '4' ||
            formData.number_type === '5'
        "
      >
        <div class="dialog-letf">
          <div class="dialog-title">
            条件属性1：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.amount_month">
            <el-radio border label="1">近1月</el-radio>
            <el-radio border label="2">近2月</el-radio>
            <el-radio border label="3">近3月</el-radio>
            <el-radio border label="4">近4月</el-radio>
            <el-radio border label="5">近5月</el-radio>
            <el-radio border label="6">近6月</el-radio>
            <el-radio border label="7">近7月</el-radio>
            <el-radio border label="8">近8月</el-radio>
            <el-radio border label="9">近9月</el-radio>
            <el-radio border label="10">近10月</el-radio>
            <el-radio border label="11">近11月</el-radio>
            <el-radio border label="12">近12月</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div
        class="dialog-box"
        v-if="
          formData.number_type === '1' ||
            formData.number_type === '2' ||
            formData.number_type === '3' ||
            formData.number_type === '4' ||
            formData.number_type === '5'
        "
      >
        <div class="dialog-letf">
          <div class="dialog-title">
            条件属性2：
          </div>
        </div>
        <div class="dialog-right flex">
          <el-radio-group v-model="formData.amount_condition">
            <el-radio border label="1">大于</el-radio>
            <el-radio border label="2">小于</el-radio>
            <el-radio border label="3">区间</el-radio>
          </el-radio-group>

          <div class="dialog-special" v-if="formData.amount_condition !== '3'">
            <el-input
              class="filter-item "
              style="width: 100px;"
              placeholder=""
              v-model="tempnum1"
            ></el-input>
            {{
              formData.number_type === "1" ||
              formData.number_type === "2" ||
              formData.number_type === "4"
                ? "次"
                : "元"
            }}
          </div>
          <div class="dialog-special" v-if="formData.amount_condition === '3'">
            <el-input
              class="filter-item "
              style="width: 100px;"
              placeholder=""
              v-model="tempnum1"
            ></el-input>
            ～
            <el-input
              class="filter-item "
              style="width: 100px;"
              placeholder=""
              v-model="tempnum2"
            ></el-input>
            {{
              formData.number_type === "1" ||
              formData.number_type === "2" ||
              formData.number_type === "4"
                ? "次"
                : "元"
            }}
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="DynamicConditionDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="() => setBehavioralConditions({ condition_type: '1' })"
          >保 存</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="
        StaticconditionDialogType === 'add'
          ? '创建会员数据条件'
          : '修改会员数据条件'
      "
      :visible.sync="StaticconditionDialog"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">条件名称：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px;"
            placeholder=""
            v-model="formData.condition_name"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">
            条件类型：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.number_type">
            <el-radio border label="1">卡级</el-radio>
            <el-radio border label="2">生日</el-radio>
            <el-radio border label="3">企业微信绑定</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="dialog-box" v-if="formData.number_type === '1'">
        <div class="dialog-letf">
          <div class="dialog-title">
            条件属性1：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.amount_month">
            <el-radio border label="1">19P 黑</el-radio>
            <el-radio border label="2">19D 钻</el-radio>
            <el-radio border label="3">19G 金</el-radio>
            <el-radio border label="4">19S 银</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="dialog-box" v-if="formData.number_type === '2'">
        <div class="dialog-letf">
          <div class="dialog-title">
            条件属性1：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.amount_month">
            <el-radio border label="1">1月</el-radio>
            <el-radio border label="2">2月</el-radio>
            <el-radio border label="3">3月</el-radio>
            <el-radio border label="4">4月</el-radio>
            <el-radio border label="5">5月</el-radio>
            <el-radio border label="6">6月</el-radio>
            <el-radio border label="7">7月</el-radio>
            <el-radio border label="8">8月</el-radio>
            <el-radio border label="9">9月</el-radio>
            <el-radio border label="10">10月</el-radio>
            <el-radio border label="11">11月</el-radio>
            <el-radio border label="12">12月</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="dialog-box" v-if="formData.number_type === '3'">
        <div class="dialog-letf">
          <div class="dialog-title">
            条件属性1：
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="formData.amount_month">
            <el-radio border label="1">是</el-radio>
            <el-radio border label="2">否</el-radio>
          </el-radio-group>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="StaticconditionDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="() => setBehavioralConditions({ condition_type: '2' })"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addBehavioralConditions,
  behavioralConditionsList,
  conditionsDel,
  updateBehavioralConditions,
  conditions_notice
} from "@/api/tag";

export default {
  name: "memberPool",
  data() {
    return {
      // tab列表
      tabList: [
        { label: '会员行为条件', value: 'conditionBehavior' },
        { label: '会员数据条件', value: 'conditionData' },
      ],
      // 当前tab
      tabValue: 'conditionBehavior',
      radio1: "",
      radio2: "",
      radio3: "",
      StaticconditionTableData: [],
      StaticConditionSearchData: {
        condition_name: "",
        condition_type: "2"
      },
      StaticconditionDialog: false,
      StaticconditionDialogType: "add",
      DynamicConditionTableData: [],
      DynamicConditionSearchData: {
        condition_name: "",
        condition_type: "1"
      },
      DynamicConditionDialog: false,
      DynamicConditionDialogType: "add",

      tempnum1: 0,
      tempnum2: 0,

      formData: {
        condition_name: "",
        number_type: "",
        amount_month: "",
        amount_condition: "",
        amount_number: ""
      }
    };
  },
  created() {},
  mounted() {
    // this.getbehavioralConditionsList();
    // this.getbehavioralStaticconditionList();
  },
  methods: {
    // 动态的
    async getbehavioralConditionsList() {
      const SearchData = this.DynamicConditionSearchData;
      const tableData = await behavioralConditionsList(SearchData);
      this.DynamicConditionTableData = tableData.data.list;
    },
    async getbehavioralStaticconditionList() {
      const SearchData = this.StaticConditionSearchData;
      const tableData = await behavioralConditionsList(SearchData);
      this.StaticconditionTableData = tableData.data.list;
    },
    async setBehavioralConditions(res) {
      this.formData.condition_type = res.condition_type;
      const { formData, tempnum1, tempnum2 } = this;
      if (formData.amount_condition === "3") {
        formData.amount_number = `${tempnum1}-${tempnum2}`;
      } else {
        formData.amount_number = tempnum1;
      }

      if (formData.condition_id) {
        console.log("up");
        await updateBehavioralConditions(formData);
      } else {
        console.log("add");
        await addBehavioralConditions(formData);
      }

      this.getbehavioralConditionsList();
      this.getbehavioralStaticconditionList();
      this.DynamicConditionDialog = false;
      this.StaticconditionDialog = false;
      this.$message({
        message: "保存成功",
        type: "success"
      });
    },
    DynamicConditionSearch() {
      this.getbehavioralConditionsList();
    },
    async conditionsDelbtn(res) {
      const notics = await conditions_notice(res);

      this.$confirm(notics.msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          await conditionsDel(res);
          this.getbehavioralConditionsList();
          this.getbehavioralStaticconditionList();
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });

      console.log(notics);
    },
    CreateDynamicCondition(res) {
      const { type } = res;
      // console.log(res);
      this.DynamicConditionDialogType = type;
      let formData;

      if (type === "add") {
        formData = {
          condition_id: "",
          condition_name: "",
          number_type: "",
          amount_month: "",
          amount_condition: "",
          amount_number: ""
        };
        this.tempnum1 = 0;
        this.tempnum2 = 0;
      } else {
        formData = {
          condition_id: res.id,
          condition_name: `${res.condition_name}`,
          number_type: `${res.number_type}`,
          amount_month: `${res.amount_month}`,
          amount_condition: `${res.amount_condition}`,
          amount_number: ""
        };

        if (formData.amount_condition === "3") {
          const amount_number = res.amount_number.split("-");

          const tempnum1 = amount_number[0];
          const tempnum2 = amount_number[1];
          this.tempnum1 = tempnum1;
          this.tempnum2 = tempnum2;
        } else {
          this.tempnum1 = res.amount_number;
          this.tempnum2 = "";
        }
      }
      this.formData = formData;
      this.DynamicConditionDialog = true;
    },
    // 静态的
    StaticConditionSearch() {
      this.getbehavioralStaticconditionList();
    },
    CreateStaticCondition(res) {
      const { type } = res;
      // console.log(res);
      this.StaticconditionDialogType = type;
      let formData;

      if (type === "add") {
        formData = {
          condition_id: "",
          condition_name: "",
          number_type: "",
          amount_month: "",
          amount_condition: "",
          amount_number: ""
        };
      } else {
        formData = {
          condition_id: res.id,
          condition_name: `${res.condition_name}`,
          number_type: `${res.number_type}`,
          amount_month: `${res.amount_month}`,
          amount_condition: `${res.amount_condition}`,
          amount_number: ""
        };
      }
      this.formData = formData;
      this.StaticconditionDialog = true;
    },
    CopywritingOutput(res) {
      // console.log(res);
      let str = "";
      if (res.condition_type === 1) {
        const amount_month = {
          1: "近1月",
          2: "近2月",
          3: "近3月",
          4: "近4月",
          5: "近5月",
          6: "近6月",
          7: "近7月",
          8: "近8月",
          9: "近9月",
          10: "近10月",
          11: "近11月",
          12: "近12月"
        };
        const number_type = {
          1: "回访次数",
          2: "回访转化次数",
          3: "回访转化金额",
          4: "购买次数",
          5: "购买金额"
        };
        const amount_condition = {
          1: "大于",
          2: "小于",
          3: " "
        };

        const Company = {
          1: "次",
          2: "次",
          3: "元",
          4: "次",
          5: "元"
        };

        str =
          amount_month[res.amount_month] +
          number_type[res.number_type] +
          amount_condition[res.amount_condition] +
          res.amount_number +
          Company[res.number_type];
      } else {
        const level = {
          1: "19P 黑",
          2: "19D 钻",
          3: "19G 金",
          4: "19S 银"
        };
        if (res.number_type === 1) {
          str = `${level[res.amount_month]}卡用户`;
        }
        if (res.number_type === 2) {
          str = `${res.amount_month}月过生日用户`;
        }
        if (res.number_type === 3) {
          str = `企业微信${res.amount_month === 1 ? "已" : "未"}绑定用户`;
        }
      }
      return str;
    }
  }
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}
.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  &.flex {
    display: flex;
  }
  .dialog-special {
    padding-left: 15px;
  }
  .el-radio {
    margin-right: 15px;
  }
  .el-radio.is-bordered {
    margin-bottom: 15px;
  }
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0px;
  }
}
</style>
