<template>
  <div class="main-content">
    <cc-title
      :titleD="isEdit ? '编辑商品' : '添加商品'"
      :titleX="''"
    ></cc-title>

    <div class="main-section">
      <!-- 步骤列表 -->
      <el-tabs v-model="stepValue">
        <el-tab-pane
          v-for="item in stepList"
          :key="item.value"
          :name="item.value"
          :label="item.label"
        />
      </el-tabs>

      <!-- 表单主体 -->
      <el-form
        :model="formData"
        :rules="formRules"
        ref="instForm"
        class="main-form mgt20"
        label-width="120px"
        size="small"
      >
        <el-row v-show="stepValue === '1'">
          <el-col :span="24">
            <el-form-item label="商品名称：" prop="store_name">
              <el-input
                v-model="formData.store_name"
                class="perW50"
                placeholder="请输入商品名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品分类：" prop="cate_id">
              <el-select
                v-model="formData.cate_id"
                class="perW50"
                placeholder="请选择商品分类"
                multiple
                filterable
                clearable
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :value="item.id"
                  :disabled="item.pid == 0"
                  :label="item.html + item.cate_name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品关键字：" prop="keyword">
              <el-input
                v-model="formData.keyword"
                class="perW50"
                placeholder="请输入商品关键字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="单位：" prop="unit_name">
              <el-input
                v-model="formData.unit_name"
                class="perW50"
                placeholder="请输入单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品简介：" prop="store_info">
              <el-input
                v-model="formData.store_info"
                class="perW50"
                type="textarea"
                :rows="3"
                placeholder="请输入商品简介"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品封面图：" prop="image">
              <UploadFile
                v-model="formData.image"
                acceptType="image"
              ></UploadFile>
              <div class="el-upload__tip">(345*345)</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品推荐图：" prop="recommend_image">
              <UploadFile
                v-model="formData.recommend_image"
                acceptType="image"
              ></UploadFile>
              <div class="el-upload__tip">(382*160)</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品轮播图：" prop="slider_image">
              <UploadFile
                v-model="formData.slider_image"
                acceptType="image"
                :limit="10"
              ></UploadFile>
              <div class="el-upload__tip">(最多10张 750*750)</div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="主图视频：" prop="video_link">
              <div class="row">
                <el-input
                  v-model="formData.video_link"
                  class="perW50"
                  placeholder="请输入视频连接"
                ></el-input>
                <UploadFile
                  v-model="formData.video_link"
                  acceptType="video"
                  :showFileList="false"
                  title="上传视频"
                  class="mgl10"
                ></UploadFile>
              </div>
              <div class="video-box" v-if="formData.video_link">
                <video
                  style="
                    width: 100%;
                    height: 100% !important;
                    border-radius: 10px;
                  "
                  :src="formData.video_link"
                  controls="controls"
                >
                  您的浏览器不支持 video 标签。
                </video>
                <div class="video-box-mark"></div>
                <div
                  class="video-box-icon el-icon-delete"
                  @click="handleDelVideo"
                />
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="商品类型：" prop="is_virtual">
              <el-radio-group v-model="formData.is_virtual">
                <el-radio :label="'0'">普通商品</el-radio>
                <el-radio :label="'1'">虚拟商品</el-radio>
                <el-radio :label="'2'">积分商品</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="formData.is_virtual == 1">
            <el-form-item label="虚拟类型：" prop="virtual_type">
              <el-radio-group v-model="formData.virtual_type">
                <el-radio :label="'1'">卡密</el-radio>
                <el-radio :label="'2'">优惠券</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品规格：" prop="spec_type">
              <el-radio-group v-model="formData.spec_type">
                <el-radio :label="'0'">单规格</el-radio>
                <el-radio :label="'1'">多规格</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 单规格表单 -->
          <el-col v-if="formData.spec_type == 0" :span="24">
            <el-form-item>
              <el-table :data="[{}]" size="small" border>
                <el-table-column label="图片" align="center" width="120">
                  <div class="rowcenmid">
                    <UploadFile
                      v-model="sigleSpecInfo.pic"
                      acceptType="image"
                      imageSize="sm"
                    ></UploadFile>
                  </div>
                </el-table-column>
                <el-table-column label="售价" align="center" min-width="120">
                  <el-input-number
                    v-model="sigleSpecInfo.price"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
                <el-table-column label="成本价" align="center" min-width="120">
                  <el-input-number
                    v-model="sigleSpecInfo.cost"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
                <el-table-column label="原价" align="center" min-width="120">
                  <el-input-number
                    v-model="sigleSpecInfo.ot_price"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
                <el-table-column label="库存" align="center" min-width="120">
                  <el-input-number
                    v-model="sigleSpecInfo.stock"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
                <el-table-column
                  label="商品编号"
                  align="center"
                  min-width="120"
                >
                  <el-input
                    v-model="sigleSpecInfo.bar_code"
                    placeholder="请输入"
                  ></el-input>
                </el-table-column>
                <el-table-column
                  label="重量（KG）"
                  align="center"
                  min-width="120"
                >
                  <el-input-number
                    v-model="sigleSpecInfo.weight"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
                <el-table-column
                  label="体积(m³)"
                  align="center"
                  min-width="120"
                >
                  <el-input-number
                    v-model="sigleSpecInfo.volume"
                    placeholder="请输入"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
          <!-- 多规格表单 -->
          <template v-if="formData.spec_type == 1">
            <el-col :span="24">
              <el-form-item label="选择规格：" prop="selectRule">
                <div class="row">
                  <el-select
                    v-model="formData.selectRule"
                    placeholder="请选择"
                    filterable
                    class="perW30"
                  >
                    <el-option
                      v-for="item in ruleList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.rule_name"
                    />
                  </el-select>
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleChooseRule"
                    >确认</el-button
                  >
                  <!-- <el-button
                    class="mgl20"
                    size="small"
                    @click="handleAddRuleTemplate"
                    >添加规格模板</el-button
                  > -->
                </div>
              </el-form-item>
            </el-col>
            <el-col v-if="attrsList.length > 0" :span="24">
              <el-form-item>
                <vuedraggable
                  :list="attrsList"
                  group="peoples"
                  handle=".move-icon"
                  :move="handleAttrsMove"
                  @end="handleAttrsEndMove"
                >
                  <div
                    v-for="(item, index) in attrsList"
                    :key="index"
                    :class="['rowmid', index !== 0 ? 'mgt10' : '']"
                  >
                    <div class="move-icon mgr10">
                      <span class="iconfont icon_diandiandian"></span>
                    </div>
                    <div
                      style="width: 90%"
                      :class="moveIndex === index ? 'borderStyle' : ''"
                    >
                      <div class="rowmid">
                        <span class="mgr5">{{ item.value }}</span>
                        <span
                          class="el-icon-circle-close"
                          @click="handleRemoveRole(index)"
                        ></span>
                      </div>
                      <div class="row rowwrap">
                        <vuedraggable
                          :list="item.detail"
                          handle=".drag-tag"
                          class="rowmid rowwrap"
                        >
                          <el-tag
                            v-for="(attrName, attrIndex) in item.detail"
                            :key="attrIndex"
                            size="small"
                            closable
                            class="mgr20 mgt5 mgb5 drag-tag"
                            @close="handleRemoveAttr(item.detail, attrIndex)"
                          >
                            {{ attrName }}
                          </el-tag>
                        </vuedraggable>
                        <el-input
                          v-model="item.detail.attrsVal"
                          placeholder="添加属性"
                          class="input-append-button"
                          style="width: 150px"
                          @keyup.enter.native="
                            handleAddAttr(item.detail.attrsVal, index, $event)
                          "
                        >
                          <el-button
                            slot="append"
                            type="primary"
                            @click="handleAddAttr(item.detail.attrsVal, index)"
                            >添加</el-button
                          >
                        </el-input>
                      </div>
                    </div>
                  </div>
                </vuedraggable>
              </el-form-item>
            </el-col>
            <el-col v-if="showAddNewRule" :span="24">
              <el-form-item>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item
                      label="规格："
                      class="main-form-row label-auto"
                    >
                      <el-input
                        v-model="formNewRule.attrsName"
                        placeholder="请输入规格"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="规格值："
                      class="main-form-row label-auto"
                    >
                      <el-input
                        v-model="formNewRule.attrsVal"
                        placeholder="请输入规格值"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>
                      <el-button
                        type="primary"
                        size="small"
                        @click="handleConfirmNewRule"
                        >确定</el-button
                      >
                      <el-button size="small" @click="handleCancelNewRule"
                        >取消</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col v-if="!showAddNewRule" :span="24">
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-plus"
                  @click="handleAddNewRule"
                  >添加新规格</el-button
                >
                <el-button type="success" size="small" @click="handleGenerate"
                  >立即生成</el-button
                >
              </el-form-item>
            </el-col>
            <el-col v-if="attrsInfo.attrs.length > 0" :span="24">
              <el-form-item>
                <div v-if="formData.spec_type == 1">批量设置：</div>
                <el-table :data="[{}]" size="small" border>
                  <el-table-column label="图片" align="center" width="120">
                    <div class="rowcenmid">
                      <UploadFile
                        v-model="sigleSpecInfo.pic"
                        acceptType="image"
                        imageSize="sm"
                      ></UploadFile>
                    </div>
                  </el-table-column>
                  <el-table-column label="售价" align="center" min-width="120">
                    <el-input-number
                      v-model="sigleSpecInfo.price"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column
                    label="成本价"
                    align="center"
                    min-width="120"
                  >
                    <el-input-number
                      v-model="sigleSpecInfo.cost"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column label="原价" align="center" min-width="120">
                    <el-input-number
                      v-model="sigleSpecInfo.ot_price"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column label="库存" align="center" min-width="120">
                    <el-input-number
                      v-model="sigleSpecInfo.stock"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column
                    label="商品编号"
                    align="center"
                    min-width="120"
                  >
                    <el-input
                      v-model="sigleSpecInfo.bar_code"
                      placeholder="请输入"
                    ></el-input>
                  </el-table-column>
                  <el-table-column
                    label="重量（KG）"
                    align="center"
                    min-width="120"
                  >
                    <el-input-number
                      v-model="sigleSpecInfo.weight"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column
                    label="体积(m³)"
                    align="center"
                    min-width="120"
                  >
                    <el-input-number
                      v-model="sigleSpecInfo.volume"
                      placeholder="请输入"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-table-column>
                  <el-table-column align="center" width="160" fixed="right">
                    <el-button type="text" size="mini" @click="handleBatchAdd"
                      >批量添加</el-button
                    >
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" size="mini" @click="handleBatchDel"
                      >清空</el-button
                    >
                  </el-table-column>
                </el-table>
                <div v-if="formData.spec_type == 1" class="mgt24">
                  商品属性：
                </div>
                <el-table :data="attrsInfo.attrs" size="small" border>
                  <el-table-column
                    v-for="(item, idx) in attrsInfo.items"
                    :key="idx"
                    :label="item.value"
                    align="center"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.detail[item.value] }}
                    </template>
                  </el-table-column>
                  <el-table-column label="图片" align="center" width="120">
                    <template slot-scope="scope">
                      <div class="rowcenmid">
                        <UploadFile
                          v-model="attrsInfo.attrs[scope.$index].pic"
                          acceptType="image"
                          imageSize="sm"
                        ></UploadFile>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="售价" align="center" min-width="120">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].price"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="成本价"
                    align="center"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].cost"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column label="原价" align="center" min-width="120">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].ot_price"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column label="库存" align="center" min-width="120">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].stock"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="商品编号"
                    align="center"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="attrsInfo.attrs[scope.$index].bar_code"
                        placeholder="请输入"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="重量（KG）"
                    align="center"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].weight"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="体积(m³)"
                    align="center"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="attrsInfo.attrs[scope.$index].volume"
                        placeholder="请输入"
                        controls-position="right"
                        :min="0"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="80">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="mini"
                        @click="handleDeleteAttrsInfoValue(scope.$index)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="24">
            <el-form-item label="关联用户标签：" prop="label_id">
              <el-select
                v-model="formData.label_id"
                class="perW50"
                placeholder="请选择关联用户标签"
                multiple
                filterable
                clearable
              >
                <el-option
                  v-for="item in userLabelList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.label_name"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="stepValue === '2'">
          <el-form-item label="商品详情：" prop="content_image">
            <div class="content-image">
              <UploadFile
                v-model="formData.content_image"
                acceptType="image"
                imageSize="lg"
                :limit="9999"
              ></UploadFile>
              <div class="mobile-preview">
                <div class="mobile-preview-title">移动端预览</div>
                <div class="mobile-preview-cont">
                  <img
                    v-for="(imageUrl, imageIndex) in formData.content_image"
                    :key="imageIndex"
                    :src="
                      imageUrl.indexOf('http') !== -1
                        ? imageUrl
                        : imageBaseUrl + imageUrl
                    "
                  />
                </div>
              </div>
            </div>
          </el-form-item>
        </el-row>
        <el-row v-show="stepValue === '3'">
          <!-- <el-col :span="8">
            <el-form-item label="虚拟销量：" prop="ficti">
              <el-input-number
                v-model="formData.ficti"
                class="perW50"
                placeholder="请输入虚拟销量"
                controls-position="right"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="8">
            <el-form-item label="积分：" prop="give_integral">
              <el-input-number
                v-model="formData.give_integral"
                class="perW50"
                placeholder="请输入积分"
                controls-position="right"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="8">
            <el-form-item label="排序：" prop="sort">
              <el-input-number
                v-model="formData.sort"
                class="perW50"
                placeholder="请输入排序"
                controls-position="right"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="商品状态：" prop="is_show">
              <el-radio-group v-model="formData.is_show">
                <el-radio :label="'1'">上架</el-radio>
                <el-radio :label="'0'">下架</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="热卖单品：" prop="is_hot">
              <el-radio-group v-model="formData.is_hot">
                <el-radio :label="'1'">开启</el-radio>
                <el-radio :label="'0'">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="8">
            <el-form-item label="促销单品：" prop="is_benefit">
              <el-radio-group v-model="formData.is_benefit">
                <el-radio :label="'1'">开启</el-radio>
                <el-radio :label="'0'">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="精品推荐：" prop="is_best">
              <el-radio-group v-model="formData.is_best">
                <el-radio :label="'1'">开启</el-radio>
                <el-radio :label="'0'">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="首发新品：" prop="is_new">
              <el-radio-group v-model="formData.is_new">
                <el-radio :label="'1'">开启</el-radio>
                <el-radio :label="'0'">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优品推荐：" prop="is_good">
              <el-radio-group v-model="formData.is_good">
                <el-radio :label="'1'">开启</el-radio>
                <el-radio :label="'0'">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="16">
            <el-form-item label="赠送优惠券：">
              <div v-if="couponList.length > 0" class="mgb20">
                <el-tag
                  v-for="item in couponList"
                  :key="item.id"
                  closable
                  @close="handleCloseCoupon(item)"
                >
                  {{ item.title }}
                </el-tag>
              </div>
              <el-button type="primary" size="small" @click="handleAddCoupon"
                >添加优惠券</el-button
              >
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="16">
            <el-form-item label="商品口令：" prop="command_word">
              <el-input
                v-model="formData.command_word"
                placeholder="请输入商品口令"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-form-item>
          <el-button v-if="stepValue !== '1'" size="small" @click="handPrevStep"
            >上一步</el-button
          >
          <el-button
            v-if="stepValue !== '3'"
            type="primary"
            size="small"
            @click="handNextStep"
            >下一步</el-button
          >
          <el-button
            v-if="stepValue === '3' || isEdit"
            type="primary"
            size="small"
            @click="handSave"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
import {
  getClassifyListApi,
  getAttrListApi,
  fetchRule,
  postProductInfo,
  fetchProductInfo,
  getFisrtLevelClassifyApi,
} from "@/api/product";
import { imageBaseUrl } from "@/utils/config";

/**
 * 基础规格属性
 */
const BASE_SPEC_ATTR = {
  pic: "", // 图片
  price: 0, // 售价
  cost: 0, // 成本价
  ot_price: 0, // 原价
  stock: 0, // 库存
  bar_code: "", // 商品编号
  weight: 0, // 重量（KG）
  volume: 0, // 体积(m³)
};

export default {
  name: "ProductAdd",
  components: {
    vuedraggable,
  },
  data() {
    return {
      // 图片url
      imageBaseUrl,
      // 是否为编辑模式
      isEdit: this.$route.params.id != 0,
      EditId: this.$route.params.id,
      // 步骤列表
      stepList: [
        { label: "商品信息", value: "1" },
        { label: "商品详情", value: "2" },
        { label: "其它设置", value: "3" },
      ],
      // 当前步骤
      stepValue: "1",
      // 商品分类列表
      categoryList: [],
      // 用户标签列表
      userLabelList: [],
      // 赠送优惠券列表
      couponList: [],
      // 规格列表
      ruleList: [],
      // 是否选择了规格
      didChooseRule: false,
      // 规格属性列表
      attrsList: [],
      // 多规格属性数据
      attrsInfo: {
        items: [], // 规格项
        attrs: [], // 规格属性列表
      },
      // 单规格属性
      sigleSpecInfo: { ...BASE_SPEC_ATTR },
      // 是否生成规格
      didMakeRule: false,
      // 排序顺序
      moveIndex: -1,
      // 是否显示添加新规格
      showAddNewRule: false,
      // 规格数据
      formNewRule: {
        attrsName: "",
        attrsVal: "",
      },
      // 表单数据
      formData: {
        MallCode: this.$route.params.MallCode,
        store_name: "", // 商品名称
        cate_id: [], // 商品分类
        keyword: "", // 商品关键字
        unit_name: "", // 单位
        store_info: "", // 商品简介
        is_virtual: "0", // 商品类型：是否为虚拟商品
        virtual_type: "1", // 虚拟商品的类型
        spec_type: "0", // 规格类型
        selectRule: "", // 选择规格
        image: "", // 商品封面图
        recommend_image: "", // 商品推荐图
        slider_image: [], // 商品轮播图
        content_image: [], // 商品内容图
        video_link: "", // 视频链接
        label_id: "", // 用户标签
        ficti: "0", // 虚拟销量
        give_integral: "0", // 积分
        sort: "0", // 排序
        is_show: "1", // 是否为上架状态
        is_hot: "0", // 是否为热卖单品
        is_benefit: "0", // 是否为促销单品
        is_best: "0", // 是否为精品推荐
        is_new: "0", // 是否为首发新品
        is_good: "0", // 是否为优品推荐
        command_word: "", // 商品口令
      },
      // 表单规则
      formRules: {
        store_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        cate_id: [
          {
            required: true,
            message: "请选择商品分类",
            trigger: "change",
            type: "array",
            min: "1",
          },
        ],
        keyword: [
          { required: true, message: "请输入商品关键字", trigger: "blur" },
        ],
        unit_name: [{ required: true, message: "请输入单位", trigger: "blur" }],
        store_info: [
          { required: true, message: "请输入商品简介", trigger: "blur" },
        ],
        image: [
          { required: true, message: "请上传商品封面图", trigger: "change" },
        ],
        slider_image: [
          {
            required: true,
            message: "请上传商品轮播图",
            type: "array",
            trigger: "change",
          },
        ],
        spec_type: [
          { required: true, message: "请选择商品规格", trigger: "change" },
        ],
        is_virtual: [
          { required: true, message: "请选择商品类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getCategoryList();
    this.getRuleList();
    this.getUserLabelList();
    if (this.isEdit) {
      this.getPrdoctInfo();
    }
  },
  methods: {
    // 获取商品详情
    async getPrdoctInfo() {
      const {
        data: { productInfo, cateList, tempList },
      } = await fetchProductInfo(this.EditId);

      console.log(productInfo, cateList, tempList);
      productInfo.content_image = JSON.parse(productInfo.content_image);

      // 表单常规数据
      Object.keys(this.formData).forEach((key) => {
        this.$set(this.formData, key, productInfo[key]);
      });

      // 多规格数据
      this.attrsInfo = {
        items: productInfo.items, // 规格项
        attrs: productInfo.attrs, // 规格属性列表
      };

      // 单规格数据
      this.sigleSpecInfo = productInfo.attr;

      // 规格属性列表
      this.attrsList = JSON.parse(JSON.stringify(productInfo.items));
    },

    // 获取商品分类列表
    async getCategoryList() {
      const { data } = await getFisrtLevelClassifyApi();
      console.log(data);
      const categoryList = data;
      this.categoryList = categoryList;
      // const req = {
      //   page: "1",
      //   limit: "9999",
      // };
      // const {
      //   data: { list },
      // } = await getClassifyListApi(req);
      // const categoryList = [];
      // list.forEach((i) => {
      //   if (i.children) {
      //     i.children.forEach((j) => {
      //       categoryList.push({
      //         cate_name: j.cate_name,
      //         id: j.id,
      //       });
      //     });
      //   } else {
      //     categoryList.push({
      //       cate_name: i.cate_name,
      //       id: i.id,
      //     });
      //   }
      // });

      // this.categoryList = categoryList;
    },

    // 获取规格列表
    async getRuleList() {
      const req = {
        page: "1",
        limit: "9999",
      };
      const {
        data: { list },
      } = await getAttrListApi(req);
      this.ruleList = list;
    },

    // 获取用户标签列表
    async getUserLabelList() {
      // const userLabelList = await fetchUserLabel();

      this.userLabelList = [];
    },

    // 添加优惠券
    handleAddCoupon() {
      console.log("handleAddCoupon");
    },

    // 关闭优惠券
    handleCloseCoupon(item) {
      console.log("handleCloseCoupon", item);
    },

    // 删除视频
    handleDelVideo() {
      this.$set(this.formData, "video_link", "");
    },

    // 选择规格
    async handleChooseRule() {
      if (this.formData.selectRule.trim().length <= 0) {
        return this.$message.warning("请选择规格");
      }

      const {
        data: { info },
      } = await fetchRule(this.formData.selectRule);

      console.log(info);
      this.didChooseRule = true;
      this.attrsList = info.spec;

      // this.ruleList.forEach((item) => {
      //   if (item.rule_name === this.formData.selectRule) {

      //   }
      // });
      return false;
    },

    // 添加规格模板
    handleAddRuleTemplate() {},

    // 检测规格移动
    handleAttrsMove(evt) {
      this.moveIndex = evt.draggedContext.index;
    },

    // 规格移动结束
    handleAttrsEndMove() {
      this.moveIndex = "";
    },

    // 删除规格
    handleRemoveRole(index) {
      this.attrsList.splice(index, 1);
    },

    // 删除规格的单个标签
    handleRemoveAttr(item, index) {
      item.splice(index, 1);
    },

    // 添加规格属性
    handleAddAttr(newAttr, idx) {
      if (newAttr) {
        if (!this.attrsList[idx].detail.includes(newAttr)) {
          this.attrsList[idx].detail.push(newAttr);
        }
        this.attrsList[idx].detail.attrsVal = "";
      } else {
        this.$message.warning("请添加属性");
      }
    },

    // 添加新规格
    handleAddNewRule() {
      this.formNewRule = {
        attrsName: "",
        attrsVal: "",
      };
      this.showAddNewRule = true;
    },

    // 确定添加新规格
    handleConfirmNewRule() {
      if (
        /^\s*$/.test(this.formNewRule.attrsName) ||
        /^\s*$/.test(this.formNewRule.attrsVal)
      ) {
        this.$message.warning("请添加完整的规格！");
      } else {
        const existAttrs = this.attrsList.filter((item) => {
          return item.value === this.formNewRule.attrsName;
        });
        if (existAttrs.length === 0) {
          this.attrsList.push({
            value: this.formNewRule.attrsName,
            detail: [this.formNewRule.attrsVal],
          });
        }
        this.showAddNewRule = false;
      }
    },

    // 取消添加新规格
    handleCancelNewRule() {
      this.showAddNewRule = false;
    },

    // 立即生成
    handleGenerate() {
      console.log("this.attrsList", this.attrsList);
      this.attrsInfo = {
        items: [], // 规格项
        attrs: [], // 规格属性列表
      };
      if (this.attrsList.length > 0) {
        this.attrsInfo.items = this.attrsList.map((item) => {
          return JSON.parse(JSON.stringify(item));
        });
        const values = this.makeAttrsDeep(0, []);
        this.attrsInfo.attrs = values.map((item) => {
          return { ...BASE_SPEC_ATTR, detail: item };
        });
      }
    },

    // 递归生成规格属性列表
    makeAttrsDeep(index, values) {
      let result = [];
      const attrsItem = this.attrsList[index] || { detail: [] };

      if (values.length > 0) {
        if (attrsItem.detail.length > 0) {
          values.forEach((valueItem) => {
            attrsItem.detail.forEach((attrsLabel) => {
              const newValue = { ...valueItem };
              newValue[attrsItem.value] = attrsLabel;
              result.push(newValue);
            });
          });
        } else {
          result = values;
        }
      } else {
        attrsItem.detail.forEach((attrsLabel) => {
          result.push({ [attrsItem.value]: attrsLabel });
        });
      }

      if (this.attrsList.length - 1 > index) {
        return this.makeAttrsDeep(index + 1, result);
      }
      return result;
    },

    // 批量添加规格属性
    handleBatchAdd() {
      this.attrsInfo.attrs.forEach((item) => {
        Object.keys(this.sigleSpecInfo).forEach((key) => {
          this.$set(item, key, this.sigleSpecInfo[key]);
        });
      });
    },

    // 清空批量表单
    handleBatchDel() {
      this.sigleSpecInfo = { ...BASE_SPEC_ATTR };
    },

    // 删除规格属性
    handleDeleteAttrsInfoValue(index) {
      this.attrsInfo.attrs.splice(index, 1);
    },

    // 下一步
    handNextStep() {
      this.stepValue = `${Number(this.stepValue) + 1}`;
    },

    // 上一步
    handPrevStep() {
      this.stepValue = `${Number(this.stepValue) - 1}`;
    },

    // 保存
    handSave() {
      this.$refs.instForm.validate((valid) => {
        if (valid) {
          if (
            this.formData.spec_type === "1" &&
            this.attrsInfo.attrs.length === 0
          ) {
            return this.$message.warning("商品信息-请点击生成多规格");
          }
          this.saveFormData();
        } else {
          if (!this.formData.store_name) {
            return this.$message.warning("商品信息-商品名称不能为空");
          }
          if (!this.formData.cate_id.length) {
            return this.$message.warning("商品信息-商品分类不能为空");
          }
          if (!this.formData.keyword) {
            return this.$message.warning("商品信息-商品关键字不能为空");
          }
          if (!this.formData.unit_name) {
            return this.$message.warning("商品信息-商品单位不能为空");
          }
          if (!this.formData.store_info) {
            return this.$message.warning("商品信息-商品简介不能为空");
          }
          if (!this.formData.image) {
            return this.$message.warning("商品信息-商品封面图不能为空");
          }
          if (!this.formData.slider_image.length) {
            return this.$message.warning("商品信息-商品轮播图不能为空");
          }
        }
        return true;
      });
    },

    // 保存表单数据
    async saveFormData() {
      console.log("saveFormData-formData", this.formData); // 表单常规数据
      console.log("saveFormData-attrsInfo", this.attrsInfo); // 多规格数据
      console.log("saveFormData-sigleSpecInfo", this.sigleSpecInfo); // 单规格数据

      const sendData = { ...this.formData };
      sendData.items = this.attrsInfo.items;

      console.log("saveFormData-sendData", sendData);

      if (sendData.spec_type === "0") {
        sendData.attrs = [this.sigleSpecInfo];
        sendData.attr = this.sigleSpecInfo;
      } else {
        sendData.attrs = this.attrsInfo.attrs;
        sendData.attr = this.sigleSpecInfo;
      }

      try {
        const res = await postProductInfo(sendData, this.EditId);
        this.$message.success(
          this.isEdit ? "编辑商品成功！" : "添加商品成功！"
        );
        this.$router.back();
      } catch (err) {
        this.$message.error(this.isEdit ? "编辑失败！" : "添加失败！");
      }
    },
  },
};
</script>

<style lang="scss">
// 视频播放
.video-box {
  width: 40%;
  height: 180px;
  border-radius: 10px;
  background-color: #707070;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}
.video-box-mark {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.video-box-icon {
  color: #fff;
  line-height: 180px;
  width: 50px;
  height: 50px;
  display: inherit;
  font-size: 26px;
  position: absolute;
  top: -74px;
  left: 50%;
  margin-left: -25px;
}

// 边框样式
.borderStyle {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}
.content-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
// 移动端预览
.mobile-preview {
  flex-shrink: 0;
  box-sizing: content-box;
  width: 375px;
  height: 667px + 30px;
  border: 1px solid #ddd;
  margin-left: 20px;
}
.mobile-preview-title {
  height: 30px;
  background-color: #409eff;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.mobile-preview-cont {
  width: 100%;
  height: 667px;
  overflow: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
