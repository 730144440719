import request from "../utils/request";

export function loginByUsername(data) {
  return request({
    url: "/adminapi/login",
    method: "post",
    data
  });
}

export function CaptchaDpread() {
  return request({
    url: "/adminapi/captcha_pro",
    method: "get",
    responseType: "arraybuffer"
  });
}
