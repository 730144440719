<template>
  <div class="main-head">
    <h1 class="main-head-title">
      {{ titleD }} <small>{{ titleX }}</small>
    </h1>
  </div>
</template>

<script type="es6">
export default {
  props: {
    titleD: String,
    titleX: String
  },
  name: "cc-title",
  data() {
    return {
    };
  },
  components:{
  }
};
</script>