<template>
  <div class="config-cont">
    <div class="config-prop">
      <div class="config-label">背景颜色</div>
      <el-color-picker v-model="currentValue.bgColor" show-alpha size="mini"></el-color-picker>
    </div>
    <div class="config-prop">
      <div class="config-label">组件高度</div>
      <div class="rowmid">
        <el-slider v-model="currentValue.height"  />
        <el-input-number v-model="currentValue.height" size="mini" controls-position="right" :min="0" :max="100" />
      </div>
    </div>
  </div>
</template>

<script>
  // 默认配置
  const DEFAULT_DATA = {
    // 样式设置
    bgColor: '#F5F5F5',
    height: 10,
  }

  export default {
    name: "ConfigBlank",
    components: {
    },
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';
</style>
