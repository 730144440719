<template>
  <div class="config-cont">
    <el-row class="config-item">
      <el-col :span="4" class="config-label">模板名称</el-col>
      <el-col :span="19">
        <el-input
          v-model="currentValue.templateName"
          placeholder="模板名称"
          size="small"
        />
      </el-col>
    </el-row>
    <el-row class="config-item">
      <el-col :span="4" class="config-label">页面标题</el-col>
      <el-col :span="19">
        <el-input
          v-model="currentValue.pageTitle"
          placeholder="页面标题"
          size="small"
        />
      </el-col>
    </el-row>
    <el-row class="config-item row-top">
      <el-col :span="4" class="config-label">背景设置</el-col>
      <el-col :span="19">
        <div class="config-item">
          <el-checkbox v-model="currentValue.bgColorOpen">背景色</el-checkbox>
          <el-color-picker v-model="currentValue.bgColorValue" size="mini"></el-color-picker>
        </div>
        <div class="config-item">
          <el-checkbox v-model="currentValue.bgImageOpen">背景图</el-checkbox>
          <el-radio-group v-model="currentValue.bgImageType" size="mini">
            <el-radio-button label="default">默认</el-radio-button>
            <el-radio-button label="repeat">平铺</el-radio-button>
            <el-radio-button label="full">全屏</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="currentValue.bgImageOpen" class="config-col-item">
          <div class="config-tips">建议尺寸：750 * 500px</div>
          <UploadFile
            v-model="currentValue.bgImagePath"
            acceptType="image"
            iconClass="el-icon-picture-outline"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  // 默认配置
  const DEFAULT_DATA = {
    templateName: '',
    pageTitle: '',
    bgColorOpen: false,
    bgColorValue: '#f5f5f5',
    bgImageOpen: false,
    bgImageType: 'default',
    bgImagePath: '',
  }

  export default {
    name: "ConfigPage",
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA))
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';
</style>
