<template>
  <div
    :class="{
      'comp-blank': true,
    }"
    :style="layoutStyle"
  >
  </div>
</template>

<script>
  export default {
    name: "WidgetBlank",
    props: {
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
      }
    },
    computed: {
      // 容器样式
      layoutStyle() {
        const styleObj = {}
        const {bgColor, height} = this.config

        if (bgColor) {
          styleObj.backgroundColor = bgColor
        }

        if (height) {
          styleObj.height = `${height}px`
        }

        return styleObj;
      },
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
</style>
