<template>
  <div class="config-cont">
    <el-tabs value="content" class="main-tabs-side">
      <el-tab-pane name="content" label="内容设置">
        <div class="config-section">
          <div class="config-section-msg">最多可添加{{maxSlideNums}}张图片，建议宽度750px；鼠标拖拽左侧圆点可调整图片顺序。</div>
          <el-divider></el-divider>
          <vuedraggable
            class="slide-list"
            handle=".move-icon"
            :list="currentValue.slideList"
          >
            <div v-for="(item, index) in currentValue.slideList" :key="item.id" class="slide-item">
              <div class="rowmid rel">
                <div class="move-icon mgr10">
                  <span class="iconfont icon_diandiandian"></span>
                </div>
                <UploadFile
                  v-model="item.image"
                  acceptType="image"
                  iconClass="el-icon-picture-outline"
                />
                <div class="delete-icon" @click="handleDeleteItem(item, index)">
                  <i class="el-icon-error"></i>
                </div>
              </div>
              <div class="rowmid">
                <div class="text-muted mgr10">链接</div>
                <el-input
                  v-model="item.page"
                  placeholder="请输入链接"
                  size="small"
                />
              </div>
            </div>
          </vuedraggable>
          <el-divider></el-divider>
          <el-button v-if="currentValue.slideList.length < maxSlideNums" class="add-content-button" type="primary" plain size="medium" @click="handleAddItem">添加轮播图</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane name="style" label="样式设置">
        <div class="config-prop">
          <div class="config-label">轮播图样式</div>
          <el-radio-group v-model="currentValue.swiperRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">指示器样式</div>
          <el-radio-group v-model="currentValue.indicatorType" size="mini">
            <el-radio-button label="dot">圆形</el-radio-button>
            <el-radio-button label="line">直线</el-radio-button>
            <el-radio-button label="num">数字</el-radio-button>
            <el-radio-button label="none">无</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">指示器位置</div>
          <el-radio-group v-model="currentValue.indicatorAlign" size="mini">
            <el-radio-button label="left">居左</el-radio-button>
            <el-radio-button label="center">居中</el-radio-button>
            <el-radio-button label="right">居右</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">指示器颜色</div>
          <el-color-picker v-model="currentValue.indicatorColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">指示器选中的颜色</div>
          <el-color-picker v-model="currentValue.indicatorActiveColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginHorizontal" />
            <el-input-number v-model="currentValue.marginHorizontal" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">顶部边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginTop"  />
            <el-input-number v-model="currentValue.marginTop" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane name="features" label="功能设置">
        <div class="config-prop">
          <div class="config-label">自动切换</div>
          <el-switch v-model="currentValue.isAutoPlay"></el-switch>
        </div>
        <div class="config-prop">
          <div class="config-label">无缝斜街</div>
          <el-switch v-model="currentValue.isLoop"></el-switch>
        </div>
        <div class="config-prop">
          <div class="config-label">自动切换时间间隔 (毫秒)</div>
          <div class="rowmid">
            <el-input-number v-model="currentValue.inteval" size="mini" controls-position="right" :min="1000" :step="1000" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">动画切换速度 (毫秒)</div>
          <div class="rowmid">
            <el-input-number v-model="currentValue.duration" size="mini" controls-position="right" :min="100" :step="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧留白动画</div>
          <el-switch v-model="currentValue.isSpaceMargin" @change="handleChangeSpaceMarginStatus"></el-switch>
        </div>
        <div v-if="currentValue.isSpaceMargin" class="config-prop">
          <div class="config-label">两侧留白距离</div>
          <div class="rowmid">
            <el-input-number v-model="currentValue.spaceMarginValue" size="mini" controls-position="right" :min="0" :max="100" :step="1" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import vuedraggable from "vuedraggable";
  import designUtils from '../utils/designUtils'

  // 生成默认的内容项
  const makeDefaultItem = () => {
    return { id: designUtils.createRandomId(), image: '', page: ''}
  }

  // 默认配置
  const DEFAULT_DATA = {
    // 内容设置
    slideList: [makeDefaultItem()],
    // 样式设置
    swiperRadiusType: 'radius',
    indicatorType: 'dot',
    indicatorAlign: 'center',
    indicatorColor: '#FFFFFF',
    indicatorActiveColor: '#F85555',
    marginHorizontal: 12,
    marginTop: 0,
    // 功能设置
    isLoop: false,
    isAutoPlay: false,
    inteval: 3000,
    duration: 500,
    isSpaceMargin: false,
    spaceMarginValue: 50,
  }

  export default {
    name: "ConfigSwiper",
    components: {
      vuedraggable,
    },
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
        // 最多允许的轮播图数量
        maxSlideNums: 10,
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
      // 变更两侧留白状态
      handleChangeSpaceMarginStatus(state) {
        if (state) {
          this.currentValue.isLoop = true
        }
      },

      // 添加内容项
      handleAddItem() {
        if (this.currentValue.slideList.length < this.maxSlideNums) {
          this.currentValue.slideList.push(makeDefaultItem())
        }
      },

      // 删除内容项
      handleDeleteItem(item, index) {
        this.currentValue.slideList.splice(index, 1);
        if (this.currentValue.slideList.length === 0) {
          this.handleAddItem()
        }
      },
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';

.slide-item {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 210px;
  }
}

.delete-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;

  i {
    font-size: 20px;
    color: #999;
  }
}

.add-content-button {
  width: 100%;
}
</style>
