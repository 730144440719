<template>
  <div class="screenfull-svg" @click="handleClick">
    <svg-icon :iconName="isFullscreen ? 'exit-fullscreen' : 'fullscreen'" />
  </div>
</template>

<script>
/**
 * @fileoverview 全屏组件
*/

import screenfull from 'screenfull'

export default {
  name: 'Screenfull',
  data() {
    return {
      isFullscreen: false,
    }
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {
    this.destroy()
  },
  methods: {
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    },
    handleClick() {
      console.log('screenfull.isEnabled', screenfull.isEnabled)
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器暂不支持全屏操作",
          type: "warning"
        });
        return
      }
      screenfull.toggle()
    },
  },
}
</script>

<style scoped>
  .screenfull-svg {
    display: inline-block;
    font-size: 18px;
    vertical-align: 10px;
    cursor: pointer;
    fill: #5a5e66;
  }
</style>
