var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"memberPool"},[_c('cc-title',{attrs:{"titleD":'商户审核',"titleX":''}}),_c('div',{staticClass:"filter-container"}),_c('div',{staticClass:"filter-container"}),_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","fit":""}},[_c('el-table-column',{attrs:{"type":"index","align":"center","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"name","label":"用户名"}}),_c('el-table-column',{attrs:{"prop":"shopname","label":" 店铺名称"}}),_c('el-table-column',{attrs:{"prop":"email","label":"邮箱"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"手机号"}}),_c('el-table-column',{attrs:{"prop":"state","label":"状态"}}),_c('el-table-column',{attrs:{"label":"控制","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"width":"60px"},attrs:{"size":"mini","type":"primary"},on:{"click":() => {
                _vm.showAddMarkingDialog({
                  type: 'updata',
                  ...scope.row,
                  mark_type: '1'
                });
              }}},[_vm._v("通过")]),_c('el-button',{staticStyle:{"width":"60px"},attrs:{"size":"mini","type":"danger"},on:{"click":() => {
                _vm.markDelBtn({
                  mark_id: scope.row.mark_id
                });
              }}},[_vm._v("停用")])]}}])})],1),_c('div',{staticClass:"footpagination"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }