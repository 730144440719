<template>
  <div class="memberDetial">
    <nav class="nav">
      <div class="title">娇兰会员池</div>

      <div class="right-menu">
        <el-dropdown class="avatar-container right-menu-item" trigger="click">
          <div class="avatar-wrapper">
            <img class="user-avatar" src="../assets/icon-384x384.png" />
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span style="display:block;" @click="clogout()">用户登出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>

    <div class="memberDetialcontent">
      <cc-title :titleD="'Infoware会员信息'" :titleX="''"></cc-title>

      <el-table
        :data="tableDataBase"
        style="width: 100%"
        border
        fit
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column prop="MemberCode" label="MemberCode" align="center">
        </el-table-column>
        <el-table-column prop="MemberName" label="Member Name" align="center">
        </el-table-column>
        <el-table-column prop="Age" label="Age" align="center">
        </el-table-column>
        <el-table-column prop="AgeGroup" label="Age Group" align="center">
        </el-table-column>
        <el-table-column prop="BirthDate" label="Birthday" align="center">
        </el-table-column>
        <el-table-column prop="Province" label="Province" align="center">
        </el-table-column>
        <el-table-column prop="District" label="District" align="center">
        </el-table-column>
        <el-table-column prop="City" label="City" align="center">
        </el-table-column>
        <el-table-column prop="MobileTel" label="Mobile Tel" align="center">
        </el-table-column>
        <el-table-column prop="Address" label="Address" align="center">
        </el-table-column>
      </el-table>
      <div class="tableBox"></div>
      <cc-title
        :titleD="'Social会员信息'"
        :titleX="'SocialMemberInfo'"
      ></cc-title>

      <el-table
        :data="tableDataWecom"
        style="width: 100%"
        border
        fit
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column prop="Name" label="微信名" align="center">
        </el-table-column>
        <el-table-column prop="" label="性别" align="center">
          <template slot-scope="scope">
            <!-- {{ scope.row.Gender === 1 ? "男" : "女" }} -->
            {{ scope.row.Gender }}
          </template>
        </el-table-column>
        <el-table-column label="微信头像" align="center">
          <template slot-scope="scope">
            <el-avatar size="large" :src="scope.row.AvatarUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="Province" label="居住省" align="center">
        </el-table-column>
        <el-table-column prop="City" label="居住市" align="center">
        </el-table-column>
        <el-table-column prop="Province" label="授权地址" align="center">
        </el-table-column>
        <el-table-column prop="MobileTel" label="授权電話" align="center">
        </el-table-column>
      </el-table>
      <div class="tableBox"></div>
      <cc-title :titleD="'会员积分信息'" :titleX="'BDPoints'"></cc-title>

      <el-table
        :data="tableBpPoints"
        style="width: 100%"
        border
        fit
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column
          prop="CurrentMemberTier"
          label="CurrentMemberTier"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="AvailableBP" label="AvailableBP" align="center">
        </el-table-column>
        <el-table-column prop="BeExpiredBP" label="BeExpiredBP" align="center">
        </el-table-column>
      </el-table>
      <div class="tableBox"></div>
      <cc-title :titleD="'BC和柜台信息'" :titleX="''"></cc-title>

      <el-table
        :data="tableDatabcInfo"
        style="width: 100%"
        border
        fit
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column
          prop="CurrentCounter"
          label="Current Counter"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="CounterShortName"
          label="Counter Short Name"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="Region" label="Region" align="center">
        </el-table-column>
        <el-table-column prop="CreateBC" label="Create BC" align="center">
        </el-table-column>
        <el-table-column prop="BCName" label="BC Name" align="center">
        </el-table-column>
        <el-table-column prop="CounterCode" label="Counter Code" align="center">
        </el-table-column>
        <el-table-column
          prop="CounterShortName"
          label="Counter Short Name"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="tableBox"></div>

      <el-row>
        <el-col :span="14"
          ><div class="">
            <cc-title :titleD="'注册信息'" :titleX="'MemberMaster'"></cc-title>

            <el-table
              :data="tableRegister"
              style="width: 100%"
              border
              fit
              @selection-change="handleSelectionChange"
              ref="table"
            >
              <el-table-column prop="JoinDate" label="Join Date" align="center">
              </el-table-column>
              <el-table-column
                prop="JoinCounter"
                label="Join Counter"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="CounterShortName"
                label="Counter Short Name"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="CreateUser"
                label="Create User"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="BCName" label="BC Name" align="center">
              </el-table-column>
            </el-table></div
        ></el-col>
        <el-col :span="10"
          ><div class="jianju">
            <cc-title
              :titleD="'会员交易历史总结'"
              :titleX="'MemberMaster'"
            ></cc-title>

            <el-table
              :data="tableDataBase"
              style="width: 100%"
              border
              fit
              @selection-change="handleSelectionChange"
              ref="table"
            >
              <el-table-column
                prop="AccumulatedSpending"
                label="Accumulated Spending"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="AccumulatedFreq"
                label="Accumulated Freq"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="LastPurchaseDate"
                label="Last Purchase Date"
                align="center"
              >
              </el-table-column>
            </el-table></div
        ></el-col>
      </el-row>

      <div class="tableBox"></div>

      <cc-title
        :titleD="'会员交易历史'"
        :titleX="'Transaction Header + Transaction Detail'"
      ></cc-title>

      <el-table
        :data="tableBuylist"
        style="width: 100%"
        border
        fit
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column
          prop="TransactionDate"
          label="Transaction Date"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="TransactionTime"
          label="Transaction Time"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="CounterCode" label="ShopID" align="center">
        </el-table-column>
        <el-table-column prop="CounterName" label="CounterName" align="center">
        </el-table-column>
        <el-table-column prop="BCCode" label="BC Code" align="center">
        </el-table-column>
        <el-table-column prop="BCName" label="BC Name" align="center">
        </el-table-column>
        <el-table-column
          prop="ShortDescription"
          label="Short Description"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="TransactionType"
          label="TransactionType"
          align="center"
        >
        </el-table-column>
        <el-table-column label="购买商品" align="center">
          <el-table-column prop="name" label="Item Code" align="center">
            <template slot-scope="scope">
              <div v-for="(i, idx) in scope.row.Item" :key="idx">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="i.ItemCode"
                  placement="top"
                >
                  <div class="shopsbox">{{ i.ItemCode }}</div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ItemDescription"
            label="Item Description"
            align="center"
            width="230"
          >
            <template slot-scope="scope">
              <div v-for="(i, idx) in scope.row.Item" :key="idx">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="i.ItemDescription"
                  placement="top"
                >
                  <div class="shopsbox">{{ i.ItemDescription }}</div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Total Sales Qty" align="center">
            <template slot-scope="scope">
              <div
                class="shopsbox"
                v-for="(i, idx) in scope.row.Item"
                :key="idx"
              >
                {{ i.TotalSalesQty }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="TagPrice" align="center">
            <template slot-scope="scope">
              <div
                class="shopsbox"
                v-for="(i, idx) in scope.row.Item"
                :key="idx"
              >
                {{ i.TagPrice }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Distribute Amount" align="center">
            <template slot-scope="scope">
              <div
                class="shopsbox"
                v-for="(i, idx) in scope.row.Item"
                :key="idx"
              >
                {{ i.DistributeAmount }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="TotalQty" label="TotalQty" align="center">
        </el-table-column>
        <el-table-column prop="TotalAmount" label="TotalAmount" align="center">
        </el-table-column>
      </el-table>

      <div class="footpagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="tableBuylistTotal"
          :page-size="tableBuylistQuery.limit"
          @current-change="pagingBuylist"
        >
        </el-pagination>
      </div>
      <div class="tableBox"></div>

      <cc-title :titleD="'会员被访历史'" :titleX="'Followup'"></cc-title>

      <el-table
        :data="tableFollowupList"
        style="width: 100%"
        border
        fit
        ref="table"
      >
        <el-table-column prop="CreatedAt" label="CreatedAt" align="center">
        </el-table-column>
        <el-table-column
          prop="TargetListID"
          label="TargetListID"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="CampaignID" label="CampaignID" align="center">
        </el-table-column>
        <el-table-column prop="BCCode" label="BCCode" align="center">
        </el-table-column>
        <el-table-column prop="BCName" label="BCName" align="center">
        </el-table-column>

        <el-table-column prop="CounterCode" label="CounterCode" align="center">
        </el-table-column>
        <el-table-column
          prop="CounterName"
          label="CounterShortName"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="Region" label="Region" align="center">
        </el-table-column>
        <el-table-column
          prop="ClientelingChannel"
          label="ClientelingChannel"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="FollowupType"
          label="FollowupType"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="FollowupStatus"
          label="FollowupStatus"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="FollowupRemark"
          label="FollowupRemark"
          align="center"
        >
        </el-table-column>
      </el-table>

      <div class="footpagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="FollowupListTotal"
          :page-size="FollowupListQuery.limit"
          @current-change="pagingFollowupList"
        >
        </el-pagination>
      </div>

      <div class="tableBox"></div>

      <cc-title
        :titleD="'会员被访转化历史'"
        :titleX="'FollowupConversionByTargetList'"
      ></cc-title>

      <el-table
        :data="tableFollowupTargetList"
        style="width: 100%"
        border
        fit
        ref="table"
      >
        <el-table-column
          prop="ConversionDate"
          label="ConversionDate"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="TargetListID"
          label="TargetListID"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ResponsibleBCCode"
          label="Responsible BC Code"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="BCName" label="BC Name" align="center">
        </el-table-column>

        <el-table-column prop="CounterCode" label="CounterCode" align="center">
        </el-table-column>
        <el-table-column
          prop="CounterShortName"
          label="CounterShortName"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="Region" label="Region" align="center">
        </el-table-column>
        <el-table-column
          prop="TargetListID"
          label="Target List ID"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="TargetListStartDate"
          label="Target List Start Date"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="TargetListEndDate"
          label="Target List End Date"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ConversionDate"
          label="ConversionDate"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="CommercialAmount"
          label="Commercial Amount"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ClientelingChannel"
          label="ClientelingChannel"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="FollowupType"
          label="FollowupType"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="footpagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="FollowupTargetListTotal"
          :page-size="FollowupTargetListQuery.limit"
          @current-change="pagingFollowupTargetList"
        >
        </el-pagination>
      </div>
      <div class="tableBox"></div>

      <cc-title
        :titleD="'会员被访转化历史'"
        :titleX="'FollowupConversionByScope'"
      ></cc-title>

      <el-table
        :data="tableFollowupScope"
        style="width: 100%"
        border
        fit
        ref="table"
      >
        <el-table-column
          prop="ConversionDate"
          label="ConversionDate"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="BCCode" label="BCCode" align="center">
        </el-table-column>
        <el-table-column prop="BCName" label="BCName" align="center">
        </el-table-column>

        <el-table-column prop="CounterCode" label="CounterCode" align="center">
        </el-table-column>
        <el-table-column
          prop="CounterShortName"
          label="CounterShortName"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="Region" label="Region" align="center">
        </el-table-column>
        <el-table-column
          prop="CommercialAmount"
          label="Commercial Amount"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ClientelingChannel"
          label="ClientelingChannel"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="FollowupType"
          label="FollowupType"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="footpagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="FollowupScopeTotal"
          :page-size="FollowupScopeQuery.limit"
          @current-change="pagingFollowupScope"
        >
        </el-pagination>
      </div>
      <div class="tableBox"></div>
    </div>
  </div>
</template>
<script>
import {
  memberPoolHistory,
  memberPoolFollowupList,
  memberPoolRegister,
  memberPoolmMmberDetail,
  memberPoolWecom,
  memberPoolBpPoints,
  memberPoolBcInfo,
  memberPoolFollowupTargetList,
  memberPoolFollowupScope
} from "@/api/memberPool";

// eslint-disable-next-line no-unused-vars
const i18n = {
  "19p": "19P 黑",
  "19d": "19D 钻",
  "19g": "19G 金",
  "19s": "19S 银",
  c: "C 潜",
  i: "I 睡"
};

export default {
  name: "memberPool",
  data() {
    return {
      MemberCode: "",
      i18n,
      value1: [],
      tableData: [],
      totalSize: 0,
      dialogV: false,
      contentDialogV: false,
      tableDataBase: [],
      tableDataWecom: [],
      tableBpPoints: [],
      tableDatabcInfo: [],
      tableBuylist: [],
      tableFollowupTargetList: [],
      tableFollowupScope: [],

      tableBuylistTotal: 0,
      FollowupListTotal: 0,
      FollowupTargetListTotal: 0,
      FollowupScopeTotal: 0,

      tableAssignList: [],
      tableFollowupList: [],
      tableRegister: [],
      tableBuylistQuery: {
        page: 1,
        limit: 10
      },
      FollowupListQuery: {
        page: 1,
        limit: 10
      },
      FollowupTargetList: {
        page: 1,
        limit: 10
      },
      FollowupScopeQuery: {
        page: 1,
        limit: 10
      },
      FollowupTargetListQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  created() {},
  mounted() {
    this.getTableData();

    // this.getDetail();
  },
  methods: {
    async getTableData() {
      const { MemberCode } = this.$router.currentRoute.query;
      this.MemberCode = MemberCode;
      await this.showMemberDetail();
      // console.log();
    },

    async showMemberDetail() {
      this.contentDialogV = true;
      const { MemberCode } = this;

      const { data: tableDataBase } = await memberPoolmMmberDetail({
        MemberCode
      });
      this.$set(this.tableDataBase, 0, tableDataBase);

      // 微信信息
      const { data: tableDataWecom } = await memberPoolWecom({
        MemberCode
      });

      this.$set(this.tableDataWecom, 0, tableDataWecom);

      // // 积分信息
      const { data: tableBpPoints } = await memberPoolBpPoints({
        MemberCode
      });

      if (tableBpPoints.CurrentMemberTier) {
        tableBpPoints.CurrentMemberTier =
          i18n[tableBpPoints.CurrentMemberTier.toLowerCase()];
      }

      this.$set(this.tableBpPoints, 0, tableBpPoints);

      // bc信息

      const { data: bcinof } = await memberPoolBcInfo({
        MemberCode
      });
      if (bcinof) {
        this.$set(this.tableDatabcInfo, 0, bcinof);
      }

      const { data: tableRegister } = await memberPoolRegister({
        MemberCode
      });
      this.$set(this.tableRegister, 0, tableRegister);

      const { data: tableBuylist } = await memberPoolHistory({
        MemberCode,
        ...this.tableBuylistQuery
      });

      this.tableBuylist = tableBuylist.list;
      this.tableBuylistTotal = Number(tableBuylist.count);

      // 顾客回访记录
      const { data: tableFollowupList } = await memberPoolFollowupList({
        MemberCode,
        ...this.FollowupListQuery
      });
      this.FollowupListTotal = Number(tableFollowupList.count);
      this.tableFollowupList = tableFollowupList.list;

      // 会员被访转化历史
      const {
        data: tableFollowupTargetList
      } = await memberPoolFollowupTargetList({
        MemberCode,
        ...this.FollowupTargetListQuery
      });
      this.FollowupTargetListTotal = Number(tableFollowupTargetList.count);

      this.tableFollowupTargetList = tableFollowupTargetList.list;

      // 会员被访转化历史2
      const { data: tableFollowupScope } = await memberPoolFollowupScope({
        MemberCode,
        ...this.FollowupScopeQuery
      });

      this.tableFollowupScope = tableFollowupScope.list;
      this.FollowupScopeTotal = Number(tableFollowupScope.count);
    },
    search() {
      this.searchData.page = 1;
      this.getTableData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async pagingBuylist(val) {
      this.tableBuylistQuery.page = val;

      const { data: tableBuylist } = await memberPoolHistory({
        MemberCode: this.MemberCode,
        ...this.tableBuylistQuery
      });

      this.tableBuylist = tableBuylist.list;
      this.tableBuylistTotal = Number(tableBuylist.count);
    },
    async pagingFollowupList(val) {
      this.FollowupListQuery.page = val;
      const { data: tableFollowupList } = await memberPoolFollowupList({
        MemberCode: this.MemberCode,
        ...this.FollowupListQuery
      });
      this.FollowupListTotal = Number(tableFollowupList.count);
      this.tableFollowupList = tableFollowupList.list;
    },
    async pagingFollowupTargetList(val) {
      this.FollowupTargetListQuery.page = val;
      const {
        data: tableFollowupTargetList
      } = await memberPoolFollowupTargetList({
        MemberCode: this.MemberCode,
        ...this.FollowupTargetListQuery
      });
      this.FollowupTargetListTotal = Number(tableFollowupTargetList.count);

      this.tableFollowupTargetList = tableFollowupTargetList.list;
    },

    async pagingFollowupScope(val) {
      this.FollowupScopeQuery.page = val;
      const { data: tableFollowupScope } = await memberPoolFollowupScope({
        MemberCode: this.MemberCode,
        ...this.FollowupScopeQuery
      });

      this.tableFollowupScope = tableFollowupScope.list;
      this.FollowupScopeTotal = Number(tableFollowupScope.count);
    },

    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    bianji() {
      this.dialogV = true;
    },
    charudelete() {
      this.contentDialogV = true;
    },
    deleteContent() {
      this.contentDialogV = false;
    },
    dingdanxiugai() {}
  }
};
</script>
<style lang="scss">
.grid-content {
  margin-bottom: 40px;
  height: 40px;
  line-height: 40px;
}
.footpagination {
  padding: 20px 0 30px 0;
}

.foot-box {
  padding-top: 20px;
}
.shopsbox {
  border-bottom: 1px solid #ebeef5;
  height: 40px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shopsbox:last-child {
  border-bottom: 0px solid #ebeef5;
}
.memberDetial {
  background: #ccc;
}
.memberDetialcontent {
  max-width: 1600px;
  margin: 0 auto;
  background: #fff;
  padding: 80px 40px;
}
.jianju {
  padding-left: 20px;
}
.tableBox {
  padding-bottom: 60px;
}
.nav {
  height: 56px;
  background-color: #000000;
  width: 100%;
  position: fixed;
  z-index: 99;
  .title {
    color: #fff;

    text-align: left;
    height: 56px;
    font-size: 26px;
    text-transform: uppercase;
    line-height: 56px;
    padding: 0 20px;
    position: absolute;
    left: 0;
  }
  .right-menu {
    float: right;
    height: 100%;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }
    .screenfull {
      height: 20px;
    }
    .international {
      vertical-align: top;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      height: 50px;
      margin-right: 30px;
      .avatar-wrapper {
        cursor: pointer;
        margin-top: 8px;
        position: relative;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
