import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  // 数据状态
  // 在组件中可以使用 this.$store.state.isCollapseMenu 访问
  state: {
    // 是否折叠导航菜单
    isCollapseMenu: localStorage.getItem('isCollapseMenu') === '1',
    // 设备展示类型（默认为PC桌面，当宽度低于992时认定为移动端mobile）
    deviceType: 'desktop',
    // 用户信息
    userInfo: false,
  },

  // 变更 state 的处理函数 (同步事务)
  // mutation 接受state作为第一个参数
  // mutation 的第二个参数接受一个载荷对象 payload
  // 通过 this.$store.commit('changeMenuCollapse', payload) 方法触发
  mutations: {
    // 变更导航菜单的折叠状态
    CHANGE_MENU_COLLAPSE(state, isCollapseMenu) {
      if (isCollapseMenu !== undefined) {
        state.isCollapseMenu = isCollapseMenu
      } else {
        state.isCollapseMenu = !state.isCollapseMenu
      }
      localStorage.setItem('isCollapseMenu', state.isCollapseMenu ? 1 : 0)
    },

    // 变更设备类型
    CHANGE_DEVICE_TYPE(state, deviceType) {
      state.deviceType = deviceType
    },
  },

  // Action 类似于 mutation，不同在于
  // Action 提交的是 mutation，而不是直接变更状态。
  // Action 可以包含任意异步操作。
  // Action 函数接受一个与 store 实例具有相同方法和属性的 context 对象
  // Action 的第二个参数接受一个载荷对象 payload
  // Action 通过 this.$store.dispatch('increment', payload) 方法触发
  actions: {
    // 变更导航菜单的折叠状态
    changeMenuCollapse(context, isCollapseMenu) {
      context.commit('CHANGE_MENU_COLLAPSE', isCollapseMenu);
    },

    // 变更设备类型
    changeDeviceType(context, device) {
      context.commit('CHANGE_DEVICE_TYPE', device);
    },
  },
})

export default store
