/* eslint-disable prefer-destructuring */

/**
 * @fileoverview 表单验证生成器
 * @author 阳团
 */

/**
 * 默认的错误提示内容
 */
const DEF_MSG = '#{title}输入有误，请重新输入';

/**
 * 获取传入值的类型
 */
const getTypeOf = (value) => {
  return value == null
    ? String(value)
    : new RegExp('\\[object\\s+(.*)\\]').exec(
        Object.prototype.toString.call(value).toLowerCase()
      )[1];
};

// 表单验证
const Validator = {
  /**
   * 预设规则
   */
  rules: {
    /**
     * 必填项（不允许仅包含空格）
     * @param {String} value 字段内容
     */
    required: value => {
      return value !== undefined && !/^\s*$/.test(value);
    },
    requiredMsg: '请输入#{title}',

    /**
     * 长度限制
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置，相关值表示如下：
     * rule: 6  要求输入6个字符
     * rule: [6]  至少输入6个字符
     * rule: [0,20]  最多输入20个字符
     * rule: [6,20]  要求输入6~20个字符
     */
    length: (value, config) => {
      const hasValue = Validator.checkHasValue(value);
      if (getTypeOf(config) === 'array') {
        const min = config[0];
        const max = config[1];
        const len = hasValue ? value.length : 0;

        if (max) {
          return len <= max && (!min || len >= min);
        }
        if (min) {
          return len >= min && (!max || len <= max);
        }
        return true;
      }

      const len = Number.parseInt(config, 10);
      return hasValue && value.length === len;
    },

    /**
     * 长度限制的错误提示
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置
     * @param {String} title 字段label
     */
    lengthMsg: (value, config, title) => {
      if (getTypeOf(config) === 'array') {
        const min = config[0];
        const max = config[1];

        if (max !== undefined && min !== undefined) {
          return `${title}要求输入${min}~${max}个字符`;
        }
        if (max !== undefined) {
          return `${title}最多输入${max}个字符`;
        }
        if (min !== undefined) {
          return `${title}至少输入${min}个字符`;
        }
      }
      return `${title}要求输入${config}个字符`;
    },

    /**
     * 最小长度限制
     * @param {String} value 字段内容
     * @param {String} config 规则-最小长度
     */
    minLength: (value, config) => {
      const hasValue = Validator.checkHasValue(value);
      return hasValue && value.length >= Number.parseInt(config, 10);
    },

    /**
     * 最小长度限制的错误提示
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置
     * @param {String} title 字段label
     */
    minLengthMsg: (value, config, title) => {
      return `${title}至少输入${config}个字符`;
    },

    /**
     * 最大长度限制
     * @param {String} value 字段内容
     * @param {String} rule 规则-最大长度
     */
    maxLength: (value, config) => {
      const hasValue = Validator.checkHasValue(value);
      return !hasValue || value.length <= Number.parseInt(config, 10);
    },

    /**
     * 最大长度限制的错误提示
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置
     * @param {String} title 字段label
     */
    maxLengthMsg: (value, config, title) => {
      return `${title}最多输入${config}个字符`;
    },

    /**
     * 禁止表情符号
     * @param {String} value 字段内容
     */
    noEmoji: value => {
      const hasEmoji = /([\uD800-\uDBFF][\uDC00-\uDFFF])+/g;
      const hasValue = Validator.checkHasValue(value);
      return !hasValue || !hasEmoji.test(value);
    },
    noEmojiMsg: '#{title}不能输入表情',

    /**
     * 姓名
     */
    name: /^[a-z·\u4e00-\u9FA5\d\s]+$/i,
    nameMsg: '#{title}只能输入汉字、‘·’和字母、数字',

    /**
     * 中文
     */
    chinese: /^[\u4e00-\u9FA5]+$/,
    chineseMsg: '#{title}只能输入中文',

    // 字母
    letter: /^[a-z]+$/i,
    letterMsg: '#{title}只能输入字母',

    /**
     * 数字字符串
     */
    number: /^[0-9]+$/,
    numberMsg: '#{title}只能输入数字',

    /**
     * 整数
     */
    integer: /^-?([1-9]\d*|0)$/,
    integerMsg: (value, config, title) => {
      if (typeof value === 'string' && value.indexOf('.') !== -1) {
        return `${title}不支持小数`;
      }
      return `${title}只能输入数字`;
    },

    /**
     * 数字（不区分整数和浮点数）
     */
    digit: /^-?([1-9]\d*|0)(\.\d*)?$/,
    digitMsg: '#{title}只能输入数字',

    /**
     * 最小值限制
     * @param {String} value 字段内容
     * @param {String} config 规则-最小值
     */
    min: (value, config) => {
      const hasValue = Validator.checkHasValue(value);
      return hasValue && value >= config;
    },

    /**
     * 最小值限制的错误提示
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置
     * @param {String} title 字段label
     */
    minMsg: (value, config, title) => {
      return `${title}最小值为${config}`;
    },

    /**
     * 最大值限制
     * @param {String} value 字段内容
     * @param {String} config 规则-最小值
     */
    max: (value, config) => {
      const hasValue = Validator.checkHasValue(value);
      return hasValue && value <= config;
    },

    /**
     * 最大值限制的错误提示
     * @param {String} value 字段内容
     * @param {String|Array} config 规则配置
     * @param {String} title 字段label
     */
    maxMsg: (value, config, title) => {
      return `${title}最大值为${config}`;
    },

    /**
     * 手机号码（简单模式）
     */
    mobile: /^1\d{10}$/,
    mobileMsg: DEF_MSG,

    /**
     * 手机号码（严格模式-大陆手机号码）
     */
    mobileStrict: /^(((13[0-9]{1})|(14[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[5-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/,
    mobileStrictMsg: DEF_MSG,

    /**
     * 电话号码
     */
    phone: /^(?:(?:0\d{2,3}[- ]?[1-9]\d{6,7})|(?:[48]00[- ]?[1-9]\d{6}))$/,
    phoneMsg: DEF_MSG,

    /**
     * ip地址
     */
    ip: /((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)/,
    ipMsg: DEF_MSG,

    /**
     * 网页Url
     */
    url: /^(http|https):\/\/([\w-]+\.)+[\w-]+.*?$/,
    urlMsg: DEF_MSG,

    /**
     * 身份证号码
     */
    idcard: /^\d{6}(19|2\d)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)?$/,
    idcardMsg: DEF_MSG,

    /**
     * 邮箱
     */
    email: /^[\w+-]+(\.[\w+-]+)*@[a-z\d-]+(\.[a-z\d-]+)*\.([a-z]{2,4})$/i,
    emailMsg: DEF_MSG,

    /**
     * QQ号码
     */
    qq: /^[1-9]\d{4,}$/,
    qqMsg: DEF_MSG,

    /**
     * 邮政编码
     */
    zipcode: /^\d{6}$/,
    zipcodeMsg: DEF_MSG,
  },

  /**
   * 检测对应字段是否存在输入值
   * @param {string} value 传入的字段内容
   */
  checkHasValue(value) {
    const hasValue = value !== '' && value !== undefined;
    return hasValue;
  },

  /**
   * 获取规则的错误提示文案
   * @param {Object} args 参数
   * @param {String} args.ruleName 规则名称
   * @param {Object} args.options 传入的配置选项
   * @param {String} args.title 字段label
   * @param {Object} args.ruleConfig 规则配置
   * @param {Object} args.value 字段值
   */
  getRuleErrorMsg({ ruleName, options, title, ruleConfig, value } = {}) {
    let errMsg;
    const msgField = `${ruleName}Msg`;
    const optionsMsg = options[msgField];

    if (optionsMsg !== undefined) {
      if (getTypeOf(optionsMsg) === 'function') {
        errMsg = optionsMsg(value);
      } else {
        errMsg = optionsMsg;
      }
    } else {
      const ruleMsg = Validator.rules[msgField];
      if (getTypeOf(ruleMsg) === 'function') {
        errMsg = ruleMsg(value, ruleConfig, title);
      } else {
        errMsg = ruleMsg.replace('#{title}', title);
      }
    }

    return errMsg || '';
  },

  /**
   * 表单校验规则生成器
   * @param {String} title 字段标题
   * @param {Object} [argOptions] 配置选项
   */
  validGenerator(title, options = {}) {
    return (rule, value, callback) => {
      let isValid = true;
      const validFunctions = [];
      const hasValue = Validator.checkHasValue(value);

      // 遍历配置项（如果存在校验失败项，则退出遍历）
      Object.keys(options).some(key => {
        if (!key.endsWith('Msg') && options[key] !== false) {
          const optionValue = options[key];

          // 如果Validator.rules中预配置的校验规则不能满足需求
          // 用户可以自行通过校验函数进行处理
          // 我们约定校验函数最后进行校验
          if (getTypeOf(optionValue) === 'function') {
            validFunctions.push(optionValue);
          }
          // 用户还可以传入正则表达式进行验证
          else if (getTypeOf(optionValue) === 'regexp') {
            if (hasValue && !optionValue.test(value)) {
              const msgField = `${key}Msg`;
              const optionsMsg = options[msgField];
              let errMsg = '正则校验失败';
              if (optionsMsg !== undefined) {
                if (getTypeOf(optionsMsg) === 'function') {
                  errMsg = optionsMsg(value);
                } else {
                  errMsg = optionsMsg;
                }
              }
              callback(errMsg);
              isValid = false;
              return true;
            }
          }
          // 从预配置的校验规则进行校验
          else {
            let ruleName;
            let ruleItem;
            let ruleConfig;
            let ruleMsg;

            if (optionValue === true) {
              // 预设规则：为配置项的key
              // 规则配置: 无
              // 错误提示：使用预设的提示方案，或者通过'xxxMsg'配置传入
              // 用例：
              // required: true
              ruleName = key;
              ruleItem = Validator.rules[ruleName];
              ruleMsg = Validator.getRuleErrorMsg({
                ruleName,
                options,
                title,
                ruleConfig,
                value,
              });
            } else if (getTypeOf(optionValue) === 'string') {
              // 预设规则：为配置项的key
              // 规则配置: 通过括号的形式从配置项的key中传进来
              // 错误提示：为配置项的value
              // 用例：
              // required: '用户名不能为空'
              // 'length(6,20)': '用户名要求输入6~20个字符'
              const match = /^(\w+)(\(([^()]*)\))?$/.exec(key);
              if (match !== null) {
                ruleName = match[1];
                ruleItem = Validator.rules[ruleName];
                ruleConfig = match[3];

                // 进一步处理规则配置，将配置拆分为String、Array两种类型
                // 没有逗号表示传入String类型，不需要处理
                // 存在逗号则表示Array类型
                if (ruleConfig && ruleConfig.indexOf(',') !== -1) {
                  ruleConfig = ruleConfig.split(',');
                }
              }
              ruleMsg = optionValue;
            } else {
              // 预设规则：为配置项的key
              // 规则配置: 为配置项的value
              // 错误提示：使用预设的提示方案，或者通过'xxxMsg'配置传入
              ruleName = key;
              ruleItem = Validator.rules[ruleName];
              ruleConfig = optionValue;
              ruleMsg = Validator.getRuleErrorMsg({
                ruleName,
                options,
                title,
                ruleConfig,
                value,
              });
            }

            if (getTypeOf(ruleItem) === 'regexp') {
              // 如果预设规则为正则表达式
              if (hasValue && !ruleItem.test(value)) {
                isValid = false;
                callback(ruleMsg);
                return true;
              }
            } else if (getTypeOf(ruleItem) === 'function' && !ruleItem(value, ruleConfig)) {
              // 如果预设规则为函数，函数执行结果：
              // 符合条件则返回true，否则false
              isValid = false;
              callback(ruleMsg);
              return true;
            }
          }
        }
        return false;
      });

      if (validFunctions.length) {
        // 执行校验函数
        validFunctions.forEach(optionValue => {
          optionValue(value, callback);
        });
      } else if (isValid) {
        // 校验通过
        callback();
      }
    };
  },

  // 生成校验规则
  makeRulus(fields) {
    const validatorRulus = {}
    fields.forEach((item) => {
      if (item.type === 'row') {
        item.cols.forEach(colItem => {
          this.setFormItemRulus(colItem, validatorRulus)
        })
      } else {
        this.setFormItemRulus(item, validatorRulus)
      }
    })
    return validatorRulus;
  },

  // 针对单项表单生成校验规则
  makeRulesItem(item) {
    const validatorRulus = {}
    this.setFormItemRulus(item, validatorRulus)
    return validatorRulus[item.name];
  },

  // 为表单项设置校验规则
  setFormItemRulus(item, validatorRulus) {
    if (item.rules) {
      validatorRulus[item.name] = [
        { validator: Validator.validGenerator(item.label || '', item.rules) },
      ];
      if (item.rules.required) {
        let messagePrefix = ['select', 'radio', 'checkbox', 'date'].includes(item.type) ? '请选择' : '请输入'
        if (item.type === 'upload') {
          messagePrefix = '请上传'
        }
        const requiredErrorMessage = typeof item.rules.required === 'string' ? item.rules.required : `${messagePrefix}${item.label || ''}`
        validatorRulus[item.name].unshift({ required: true, message: requiredErrorMessage })
      }
    }
  },
};

export default Validator
