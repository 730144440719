import BasicLayout from '@/layouts/BasicLayout.vue'

import login from "@/views/Login";
import memberDetial from "@/views/memberDetial";

import memberCondition from "@/views/member/memberCondition/memberCondition";
import memberPool from "@/views/member/memberPool/memberPool";
import memberGroup from "@/views/member/memberGroup/memberGroup";

import targetList from "@/views/targetList";
import memberRegister from "@/views/memberRegister";
import memberRegisterReview from "@/views/memberRegisterReview";

import storeList from "@/views/content/storeList/storeList";
import bannerList from "@/views/content/bannerList/bannerList";
import ProductListofPointMall from "@/views/content/ProductListofPointMall/ProductListofPointMall";

import adminList from "@/views/setting/adminList/adminList";
import ErrorLogList from "@/views/setting/errorLogList/errorLogList";
import BCList from "@/views/setting/BCList/BCList";

import ActivityList from "@/views/activity/activityList/activityList";
import RaffleMember from "@/views/activity/raffleMember/raffleMember";

import OrderList from '@/views/order/orderList/orderList';
import productList from '@/views/product/productList/productList';
import productClassify from '@/views/product/productClassify/productClassify';
import productAttr from '@/views/product/productAttr/productAttr';
import productAdd from '@/views/product/productAdd/productAdd';


import designManage from '@/views/design/designManage/designManage.vue'
import designPage from '@/views/design/designPage/designPage.vue'

import exceptionRoutes from './modules/exception'

export default [
  {
    path: '/',
    name: 'Home',
    redirect: '/memberPool',
  },

  {
    path: "/memberPool",
    component: BasicLayout,
    name: "memberPool",
    redirect: '/memberPool/index',
    meta: {
      title: '会员库',
      icon: 'el-icon-user',
    },
    children: [
      {
        path: "index",
        component: memberPool,
        name: "memberPoolIndex",
      },
    ],
  },

  {
    path: "/memberTag",
    component: BasicLayout,
    name: "memberTag",
    redirect: '/memberTag/condition',
    meta: {
      title: '会员标签',
      icon: 'el-icon-price-tag',
    },
    children: [
      {
        path: "condition",
        component: memberCondition,
        name: "memberCondition",
        meta: {
          title: '条件库',
        },
      },
      {
        path: "tag",
        component: memberGroup,
        name: "memberGroup",
        meta: {
          title: '标签库',
        },
      },
    ],
  },

  {
    path: "/contentManage",
    component: BasicLayout,
    name: "contentManage",
    redirect: '/contentManage/store',
    meta: {
      title: '内容管理',
      icon: 'el-icon-document',
    },
    children: [
      {
        path: "store",
        component: storeList,
        name: "storeList",
        meta: {
          title: '店铺管理',
        },
      },
      {
        path: "banner",
        component: bannerList,
        name: "bannerList",
        meta: {
          title: '素材管理',
        },
      },
      {
        path: "point",
        component: ProductListofPointMall,
        name: "ProductListofPointMall",
        meta: {
          title: '积分商城列表',
        },
      },
    ],
  },

  {
    path: "/activityManage",
    component: BasicLayout,
    name: "activityManage",
    redirect: '/activityManage/list',
    meta: {
      title: '运营活动',
      icon: 'el-icon-present',
    },
    children: [
      {
        path: "list",
        component: ActivityList,
        name: "ActivityList",
        meta: {
          title: '活动列表',
        },
      },
      {
        path: "raffle",
        component: RaffleMember,
        name: "RaffleMember",
        meta: {
          title: '中奖名单',
        },
      },
    ],
  },

  {
    path: "/productManage",
    component: BasicLayout,
    name: "productManage",
    redirect: '/productManage/list',
    meta: {
      title: '商品管理',
      icon: 'el-icon-goods',
    },
    children: [
      {
        path: "list",
        component: productList,
        name: "productList",
        meta: {
          title: '商品列表',
        },
      },
      {
        path: "add/:id/:MallCode",
        component: productAdd,
        name: "productAdd",
      },
      {
        path: "classify",
        component: productClassify,
        name: "productClassify",
        meta: {
          title: '商品分类',
        },
      },
      {
        path: "attr",
        component: productAttr,
        name: "productAttr",
        meta: {
          title: '商品规格',
        },
      },
    ],
  },

  {
    path: "/orderManage",
    component: BasicLayout,
    name: "orderManage",
    redirect: '/orderManage/orderList',
    meta: {
      title: '订单管理',
      icon: 'el-icon-notebook-2',
    },
    children: [
      {
        path: "orderList",
        component: OrderList,
        name: "OrderList",
        meta: {
          title: '订单列表',
        },
      },
    ],
  },
  {
    path: "/designManage",
    component: BasicLayout,
    name: "designManage",
    redirect: '/designManage/index',
    meta: {
      title: '页面装修',
      icon: 'el-icon-school',
    },
    children: [
      {
        path: "index",
        component: designManage,
        name: "designManage",
      },
    ],
  },

  {
    path: "/accountManage",
    component: BasicLayout,
    name: "accountManage",
    redirect: '/accountManage/admin',
    meta: {
      title: '账号管理',
      icon: 'el-icon-setting',
    },
    children: [
      {
        path: "admin",
        component: adminList,
        name: "adminList",
        meta: {
          title: '后台账号',
        },
      },
      {
        path: "bc",
        component: BCList,
        name: "BCList",
        meta: {
          title: 'BC账号',
        },
      },
    ],
  },

  {
    path: "/errorLog",
    component: BasicLayout,
    name: "errorLog",
    redirect: '/errorLog/index',
    meta: {
      title: '错误日志',
      icon: 'el-icon-bell',
    },
    children: [
      {
        path: "index",
        component: ErrorLogList,
        name: "ErrorLogList",
      },
    ],
  },

  {
    path: "/login",
    component: login,
    name: "login",
  },

  {
    path: "/designPage",
    component: designPage,
    name: "DesignPage",
  },



  {
    path: "/memberDetial",
    component: memberDetial,
    name: "memberDetial",
  },

  exceptionRoutes,
]