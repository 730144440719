<template>
  <div :class="useClassName">
    <vuedraggable
      v-if="isAcceptImage"
      :list="fileList"
      handle=".j_drag"
      :disabled="limit === 1"
      class="main-upload-images"
      @end="handleEndMove"
    >
      <div
        v-for="(item, index) in fileList"
        :key="index"
        :class="{ 'main-upload-images-item': true, j_drag: limit > 1 }"
      >
        <el-image :src="item.url" fit="cover"></el-image>
        <div class="main-upload-images-close" @click="handleRemoveImage(item)">
          <i class="el-icon-error" />
        </div>
      </div>

      <el-upload
        :action="uploadAPIUrl"
        :class="{
          'main-upload-hide-plus': limit > 1 && fileResponse.length === limit,
        }"
        :list-type="isAcceptImage ? 'picture-card' : 'text'"
        :before-upload="handleBeforeUpload"
        :on-error="handleUploadError"
        :on-success="handleUploadSuccess"
        :file-list="fileList"
        :show-file-list="showFileList"
        :accept="acceptStr"
        :disabled="disabled"
      >
        <i v-if="isAcceptImage" :class="iconClass"></i>
        <el-button
          v-else
          size="small"
          acceptType="primary"
          icon="el-icon-upload"
          >{{ title }}</el-button
        >
        <div v-if="message" slot="tip" class="el-upload__tip">
          {{ message }}
        </div>
      </el-upload>
    </vuedraggable>

    <slot></slot>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
import { apiUrl, imageBaseUrl } from "@/utils/config";

export default {
  name: "UploadFile",
  components: {
    vuedraggable,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    // 按钮标题
    title: { type: String, required: false, default: "点击上传" },
    // 信息提示
    message: { type: [Array, String], required: false, default: "" },
    // 最大上传数量
    limit: { type: Number, required: false, default: 1 },
    // 当前的文件列表（limit限制为1时，值类型为字符串，大于1时，值类型为数组）
    value: { type: [Array, String], required: false, default: undefined },
    // 接受的文件类型
    // For Excel Files 2003-2007 (.xls), use: application/vnd.ms-excel
    // For Excel Files 2010 (.xlsx), use: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    // For Text Files (.txt) use: text/plain
    // For PNG Image (.png) use: image/png
    // For JPG Image (.jpg) use: image/jpeg
    // For Image Files (.png/.jpg/.gif), use: image/*
    // For HTML Files (.htm,.html), use: text/html
    // For Video Files (.avi, .mpg, .mpeg, .mp4), use: video/*
    // For Audio Files (.mp3, .wav, etc), use: audio/*
    // For PDF Files, use: .pdf
    accept: { type: String, required: false, default: "" },
    // 预设的文件类型
    // image - 仅限png和jpg格式图片
    // allImage - 所有图片
    // excel - excel表格文件
    // video - 视频文件（仅限MP4）
    acceptType: { type: String, required: false, default: "" },
    // 不符合文件类型的提示语
    typeMessage: { type: String, required: false, default: "" },
    // 文件大小限制（单位M）
    fileSize: { type: Number, required: false, default: undefined },
    // 超出文件大小时的提示语
    fileSizeMessage: { type: String, required: false, default: "" },
    // 超出文件大小或者不符合文件类型时的统一提示语
    errorMessage: { type: String, required: false, default: "" },
    // 是否禁用
    disabled: { type: Boolean, required: false, default: false },
    // 是否显示文件列表
    showFileList: { type: Boolean, required: false, default: false },
    // 图片展示尺寸(sm-较小尺寸 md-常规尺寸 lg-较大尺寸)
    imageSize: { type: String, required: false, default: "md" },
    // 图片展示尺寸(sm-较小尺寸 md-常规尺寸 lg-较大尺寸)
    iconClass: { type: String, required: false, default: "el-icon-plus" },
    // 附加数据
    extraData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 接口url前缀
      uploadAPIUrl: `${apiUrl}/api/upload/image`,
      // 上传的文件列表
      fileResponse: this.limit > 1 ? [] : undefined,
      // 展示的文件列表
      fileList: this.limit > 1 ? [] : undefined,
    };
  },
  computed: {
    // 接受的文件类型字符串
    acceptStr() {
      if (this.acceptType === "excel") {
        return "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      if (this.acceptType === "image") {
        return "image/jpeg, image/png";
      }
      if (this.acceptType === "allImage") {
        return "image/*";
      }
      if (this.acceptType === "video") {
        return "video/mp4";
      }
      return this.accept;
    },

    // 是否接受图片文件
    isAcceptImage() {
      return this.acceptType === "image" || this.acceptType === "allImage";
    },

    // 使用的类名
    useClassName() {
      let result = "main-upload";
      result += ` main-upload--${this.imageSize}`;
      if (this.limit === 1 && this.fileResponse !== undefined) {
        result += " main-upload--single";
      }
      return result;
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.value !== undefined && this.value !== "") {
        this.fileResponse = this.value;

        if (typeof this.value === "string") {
          const fileName = this.value;
          this.fileList = [
            {
              name: fileName,
              url: this.getImageUrl(fileName),
              response: { data: fileName },
            },
          ];
        } else {
          this.fileList = this.value.map((fileName) => {
            return {
              name: fileName,
              url: this.getImageUrl(fileName),
              response: { data: fileName },
            };
          });
        }
      } else {
        this.fileResponse = this.limit > 1 ? [] : undefined;
        this.fileList = this.limit > 1 ? [] : undefined;
      }
    },

    // 获取图片url
    getImageUrl(fileName) {
      if (fileName.indexOf("http") === 0) {
        return fileName;
      }
      return `${imageBaseUrl}${fileName}`;
    },

    // 上传文件前置回调
    handleBeforeUpload(file) {
      let errorMessage = "";
      const sizeM = file.size / 1024 / 1024; // 文件多少M

      if (this.limit > 1 && this.fileResponse.length >= this.limit) {
        errorMessage = `最多上传${this.limit}个文件！`;
      } else if (this.fileSize && sizeM > this.fileSize) {
        errorMessage =
          this.fileSizeMessage ||
          this.errorMessage ||
          `上传文件大小不能超过 ${this.fileSize}MB!`;
      } else if (
        this.acceptType === "image" &&
        !/(image\/jpeg|image\/png)$/.test(file.type)
      ) {
        errorMessage =
          this.typeMessage ||
          this.errorMessage ||
          "上传图片只能是 JPG/PNG 格式!";
      } else if (
        this.acceptType === "allImage" &&
        !/^image\//.test(file.type)
      ) {
        errorMessage =
          this.typeMessage || this.errorMessage || "只能上传图片文件！";
      } else if (
        this.acceptType === "excel" &&
        !/(\.xls|\.xlsx)$/.test(file.name)
      ) {
        errorMessage =
          this.typeMessage || this.errorMessage || "只能上传excel文件！";
      } else if (this.acceptType === "video" && !/(\.mp4)$/.test(file.name)) {
        errorMessage =
          this.typeMessage || this.errorMessage || "上传视频只能是 MP4 格式!";
      }

      if (errorMessage) {
        this.$message({ message: errorMessage, type: "error" });
        return false;
      }
      return true;
    },

    // 上传文件失败（服务器响应失败）
    // eslint-disable-next-line no-unused-vars
    handleUploadError(err, file, fileList) {
      this.$message({
        message: "上传文件失败",
        type: "error",
      });
    },

    // 上传文件成功（服务器响应成功）
    // eslint-disable-next-line no-unused-vars
    handleUploadSuccess(response, file, fileList) {
      if (response.status !== 200 || !response.data) {
        this.$message({
          message: "上传文件失败",
          type: "error",
        });
        fileList.pop();
        return;
      }

      if (this.limit > 1) {
        this.fileResponse.push(response.data.image_url);
      } else {
        this.fileResponse = response.data.image_url;
      }
      this.$emit("change", this.fileResponse, "success", this.extraData);
    },

    // 移除已上传的文件
    handleRemoveImage(item) {
      const findIndex = this.fileList.indexOf(item);
      this.fileList.splice(findIndex, 1);
    },

    // 拖拽结束
    handleEndMove() {},
  },
};
</script>