<template>
  <div class="main-content">
    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          class="filter-item"
          type="primary"
          size="small"
          v-on:click="
            () => showAddMarkingDialog({ type: 'add', mark_type: '1' })
          "
          icon="el-icon-circle-plus-outline"
          >添加账号</el-button
        >
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          fit
          ref="table"
          v-loading="loading"
        >
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>

          <el-table-column prop="account" label="account"></el-table-column>
          <el-table-column prop="MallCode" label="MallCode"> </el-table-column>
          <el-table-column prop="MallName" label="MallName"> </el-table-column>
          <el-table-column prop="add_time" label="AddTime"> </el-table-column>

          <el-table-column label="控制" align="center" width="200">
            <!--  -->
            <template slot-scope="scope">
              <el-button
                v-on:click="
                  () => {
                    showAddMarkingDialog({
                      type: 'updata',
                      ...scope.row,
                      mark_type: '1',
                    });
                  }
                "
                size="mini"
                type="primary"
                style="width: 60px"
                >编辑</el-button
              >
              <el-button
                v-on:click="
                  () => {
                    markDelBtn({
                      account: scope.row.account,
                    });
                  }
                "
                size="mini"
                type="danger"
                style="width: 60px"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      class="main-dialog-form"
      :title="MarkingDialogType === 'add' ? '创建账号' : '修改账号'"
      :visible.sync="MarkingDialog"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">账号</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.account"
          ></el-input>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">密码</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.pwd"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">管理店铺</div>
        </div>
        <div class="dialog-right">
          <el-select
            style="width: 250px"
            class="filter-item"
            placeholder="店铺选择"
            v-model="formData.MallCode"
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.MallName"
              :value="item.MallCode"
            ></el-option>
          </el-select>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCounterList } from "@/api/storeList";

import { settingAdmin, adminCreate, settingAdminDel } from "@/api/adminList";

export default {
  name: "adminList",
  data() {
    return {
      tableData: [],
      storeList: [],
      behavioralConditionsList: [],
      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",

      options: [
        {
          value: "1",
          label: "全部",
        },
      ],
      formData: {
        account: "",
        pwd: "",
        MallCode: "",
      },
      searchData: {
        limit: 25,
        page: 1,
      },
    };
  },
  created() {
    this.getTableData();
    this.getstoreList();
  },
  mounted() {},
  methods: {
    async getstoreList() {
      this.loading = true;
      const tableData = await getCounterList({
        limit: 999,
        page: 1,
      });
      this.storeList = tableData.data.list;
    },
    async getTableData() {
      this.loading = true;
      const tableData = await settingAdmin(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    async showAddMarkingDialog(res) {
      const { type } = res;

      if (type === "add") {
        this.formData = {
          account: "",
          pwd: "",
          MallCode: "",
        };
      } else {
        this.formData = {
          CounterName1: res.account,
          MallCode: res.MallCode,
        };
        console.log(res);
      }
      this.MarkingDialogType = type;
      this.MarkingDialog = true;
    },
    async markDelBtn(res) {
      this.$confirm("此操作将永久删除该账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await settingAdminDel(res);
          this.getTableData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      console.log(res);
    },
    async addMarkingEvent() {
      const data = this.formData;

      await adminCreate(data);

      this.getTableData();

      this.MarkingDialog = false;
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
