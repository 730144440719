<template>
  <div class="user-center">
    敬请期待
  </div>
</template>

<script>
  import { getClassifyListApi, editClassifyApi } from '@/api/product'

  export default {
    name: "UserCenter",
    data() {
      return {
        // 模板列表
        listData: [],
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
.user-center {
  min-height: 560px;
}
</style>
