import request from "@/utils/request";

/**
 * 获取商品列表数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchProdctList(data) {
  return request({
    url: "/adminapi/product/product",
    method: "get",
    params: data
  });
}


/**
 * 保存商品数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function postProductInfo(data, id) {
  return request({
    url: `/adminapi/product/product/${id}`,
    method: "POST",
    data
  });
}


/**
 * 获取商品数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchProductInfo(data) {
  return request({
    url: `/adminapi/product/product/${data}`,
    method: "get"
  });
}




/**
 * 获取商品分类列表
 * @param {Object}} data 参数传递
 * @returns
 */
export function getClassifyListApi(data) {
  return request({
    url: "/adminapi/product/category",
    method: "get",
    params: data
  });
}




/**
 * 编辑商品分类
 * @param {Object}} data 参数传递
 * @returns
 */
export function editClassifyApi(data, id) {
  return request({
    url: `/adminapi/product/category/${id}`,
    method: "post",
    data
  });
}


/**
 * 设置商品分类的显示状态
 * @param {Object}} data 参数传递
 * @returns
 */
export function setClassifyStatusApi({ id, is_show }) {
  return request({
    url: `/adminapi/product/category/set_show/${id}/${is_show}`,
    method: "post",

  });
}


/**
 * 删除商品分类记录
 * @param {Object}} data 参数传递
 * @return
 */
export function deleteClassifyApi({ id }) {
  return request({
    url: `/adminapi/product/category/${id}`,
    method: "delete"

  });
}

/**
 * 获取商品规格列表
 * @param {Object}} data 参数传递
 * @returns
 */
export function getAttrListApi(data) {
  return request({
    url: "/adminapi/product/product/rule",
    method: "get",
    params: data
  });
}


/**
 * 编辑商品规格
 * @param {Object}} data 参数传递
 * @returns
 */
export function editAttrApi(data, id) {
  return request({
    url: `/adminapi/product/product/rule/${id}`,
    method: "post",
    data
  });
}

/**
 * 获取规格列表详情
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchRule(id) {
  return request({
    url: `/adminapi/product/product/rule/${id}`,
    method: "get"
  });
}

/**
 * 删除商品规格
 * @param {Object}} data 参数传递
 * @returns
 */
export function deleteAttrApi(data) {
  return request({
    url: "/adminapi/product/product/rule/delete",
    method: "delete",
    params: data
  });
}


/**
 * 获取type_header
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchGetTypeHeader(data) {
  return request({
    url: "/adminapi/product/product/type_header",
    method: "get",
    params: data
  });
}


/**
 * 设置上架
 * @param {Object}} data 参数传递
 * @returns
 */
export function setProductShow({ id, is_show }) {
  return request({
    url: `/adminapi/product/product/set_show/${id}/${is_show}`,
    method: "post"
  });
}


/**
 * 回收站
 * @param {Object}} data 参数传递
 * @returns
 */
export function deleteProduct(id) {
  return request({
    url: `/adminapi/product/product/${id}`,
    method: "delete"
  });
}


/**
 * 获取一级分类列表
 * @param {Object}} data 参数传递
 * @returns
 */
export function getFisrtLevelClassifyApi(data) {
  return request({
    url: "/adminapi/product/category/tree/1",
    method: "get",
    params: data
  });
}














// 以上为调完的


/**
 * 获取用户标签
 * @param {Object}} data 参数传递
 * @returns
 */
// export function fetchUserLabel(data) {
//   return request({
//     url: "/adminapi/counter/counter/list",
//     method: "get",
//     params: data
//   });
// }












