<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <div class="filter-title">店铺选择</div>
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="店铺选择"
          v-model="searchData.MallCode"
          @change="MallChange"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <div class="bannerListTitle">启动页</div>
      <div class="bannerListContent">
        <template v-for="(i, idx) in startBannerList">
          <div :key="idx" class="addStartPage on">
            <div class="Mask on">
              <div class="btnBox">
                <div
                  class="btn"
                  v-on:click="() => showAddMarkingDialog({ type: 'up', ...i })"
                >
                  <i class="el-icon-edit"></i>
                </div>
                <div class="btn" v-on:click="() => markDelBtn({ id: i.id })">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <el-image
              class="pageImage"
              :src="baseImageUrl + i.BannerUrl"
              fit="cover"
            ></el-image>
          </div>
        </template>

        <div
          class="addStartPage"
          v-if="startBannerList.length < 1"
          v-on:click="
            () => showAddMarkingDialog({ type: 'add', BannerType: 1 })
          "
        >
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <div class="bannerListTitle">头部轮播banner</div>
      <div class="bannerListContent">
        <template v-for="(i, idx) in swipeBannerList">
          <div :key="idx" class="addRotationPage on">
            <div class="Mask on">
              <div class="btnBox">
                <div
                  class="btn"
                  v-on:click="() => showAddMarkingDialog({ type: 'up', ...i })"
                >
                  <i class="el-icon-edit"></i>
                </div>
                <div class="btn" v-on:click="() => markDelBtn({ id: i.id })">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <el-image
              class="pageImage"
              :src="baseImageUrl + i.BannerUrl"
              fit="cover"
            ></el-image>
          </div>
        </template>

        <div
          v-if="swipeBannerList.length < 10"
          class="addRotationPage"
          v-on:click="
            () => showAddMarkingDialog({ type: 'add', BannerType: 2 })
          "
        >
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <!-- <div class="bannerListTitle">活动banner</div>
      <div class="bannerListContent">
        <template v-for="(i, idx) in BannerList">
          <div v-if="i.BannerType == 3" :key="idx" class="addlotteryPage on">
            <div class="Mask on">
              <div class="btnBox">
                <div
                  class="btn"
                  v-on:click="() => showAddMarkingDialog({ type: 'up', ...i })"
                >
                  <i class="el-icon-edit"></i>
                </div>
                <div class="btn" v-on:click="() => markDelBtn({ id: i.id })">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <el-image
              class="pageImage"
              :src="baseImageUrl + i.BannerUrl"
              fit="cover"
            ></el-image>
          </div>
        </template>

        <div
          class="addlotteryPage"
          v-on:click="() => showAddMarkingDialog({ type: 'add', BannerType: 3 })"
        >
          <i class="el-icon-plus"></i>
        </div>
      </div> -->
      <div class="bannerListTitle">活动banner</div>
      <div class="bannerListContent">
        <template v-for="(i, idx) in activityBannerList">
          <div :key="idx" class="addActivityPage on">
            <div class="Mask on">
              <div class="btnBox">
                <div
                  class="btn"
                  v-on:click="() => showAddMarkingDialog({ type: 'up', ...i })"
                >
                  <i class="el-icon-edit"></i>
                </div>
                <div class="btn" v-on:click="() => markDelBtn({ id: i.id })">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <div class="footerText" v-if="i.BannerTitle">
              <div class="ActivitiesName">{{ i.BannerTitle }}</div>
              <div class="ActivitiesTime">
                {{ i.StartDate }}～{{ i.EndDate }}
              </div>
            </div>
            <el-image
              class="pageImage"
              :src="baseImageUrl + i.BannerUrl"
              fit="cover"
            ></el-image>
          </div>
        </template>
        <div
          class="addActivityPage"
          v-on:click="
            () => showAddMarkingDialog({ type: 'add', BannerType: 4 })
          "
        >
          <i class="el-icon-plus"></i>
        </div>
      </div>

      <div class="footpagination">
        <!-- <div class="addStartPage"></div> -->
      </div>
    </div>

    <el-dialog
      :title="
        formData.BannerType == '1'
          ? '启动页'
          : formData.BannerType == '2'
          ? '头部轮播banner'
          : formData.BannerType == '3'
          ? '抽奖banner'
          : formData.BannerType == '4'
          ? '活动banner'
          : ''
      "
      :visible.sync="MarkingDialog"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">标题</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.BannerTitle"
          ></el-input>
        </div>
      </div>

      <div class="dialog-box" v-if="formData.BannerType != 1">
        <div class="dialog-letf">
          <div class="dialog-title">文章地址</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.BannerPage"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box" v-if="formData.BannerType == 4">
        <div class="dialog-letf">
          <div class="dialog-title">显示日期</div>
        </div>
        <div class="dialog-right">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="formData.ShowDate"
            type="date"
            placeholder="显示日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="dialog-box" v-if="formData.BannerType == 4">
        <div class="dialog-letf">
          <div class="dialog-title">活动日期</div>
        </div>
        <div class="dialog-right">
          <el-date-picker
            v-model="tempStartDateEndDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">图片上传</div>
        </div>
        <div class="dialog-right">
          <el-upload
            class="avatar-uploader"
            :action="baseImageUrl + '/api/upload/image'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image
              v-if="formData.BannerUrl"
              :src="baseImageUrl + formData.BannerUrl"
              class="avatar"
              fit="cover"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div
            v-if="formData.BannerType == 1"
            class="el-upload__tip"
            slot="tip"
          >
            推荐尺寸750px1623px，只能上传jpg/png文件，且不超过2MB
          </div>
          <div
            v-if="formData.BannerType == 2"
            class="el-upload__tip"
            slot="tip"
          >
            推荐尺寸448px577p，只能上传jpg/png文件，且不超过1MB
          </div>
          <div
            v-if="formData.BannerType == 4"
            class="el-upload__tip"
            slot="tip"
          >
            推荐尺寸667px326px，只能上传jpg/png文件，且不超过1MB
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { getCounterList } from "@/api/storeList";
import { bannerSave, bannerList, bannerDel } from "@/api/bannerList";
import { imageBaseUrl } from "@/utils/config";

export default {
  name: "bannerList",
  data() {
    return {
      baseImageUrl: imageBaseUrl,
      shopList: [],
      BannerList: [],
      startBannerList: [],
      swipeBannerList: [],
      activityBannerList: [],

      totalSize: 0,
      MarkingDialog: false,
      tempStartDateEndDate: "",
      // MarkingDialogType: "add",
      formData: {
        BannerUrl: "",
        BannerTitle: "",
        BannerPage: "",
        BannerType: "",
        ShowDate: "",
        StartDate: "",
        EndDate: "",
        MallCode: "",
      },
      searchData: {
        MallCode: "",
      },
    };
  },
  created() {
    this.getSelectData();
  },
  mounted() {},
  destroyed() {},
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");

      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.MallCode = tableData.data.list[0].MallCode;
      await this.getBannerList();
    },
    async MallChange() {
      // const tableData = await bannerList(this.searchData);
      // this.BannerList = tableData.data.list;
      this.getBannerList();
    },
    async getBannerList() {
      const tableData = await bannerList(this.searchData);
      this.BannerList = tableData.data.list;

      const startBannerList = [];
      const swipeBannerList = [];
      const activityBannerList = [];

      tableData.data.list.forEach((i) => {
        if (i.BannerType === "1") {
          startBannerList.push(i);
        }
        if (i.BannerType === "2") {
          swipeBannerList.push(i);
        }

        if (i.BannerType === "4") {
          activityBannerList.push(i);
        }
      });
      this.startBannerList = startBannerList;
      this.swipeBannerList = swipeBannerList;
      this.activityBannerList = activityBannerList;
    },
    async setBannerSave() {
      await bannerSave(this.formData);
    },
    async showAddMarkingDialog(res) {
      const { type, BannerType } = res;

      if (type === "add") {
        this.tempStartDateEndDate = "";
        this.formData = {
          BannerUrl: "",
          BannerTitle: "",
          BannerPage: "",
          BannerType,
          ShowDate: "",
          StartDate: "",
          EndDate: "",
          MallCode: this.searchData.MallCode,
        };
      } else {
        this.tempStartDateEndDate = [res.StartDate, res.EndDate];
        this.formData = res;
      }

      this.MarkingDialog = true;
    },
    async addMarkingEvent() {
      await this.setBannerSave(this.formData);
      await this.getBannerList();
      this.$message({
        type: "success",
        message: "保存成功!",
      });
      this.MarkingDialog = false;
    },
    handleAvatarSuccess(res) {
      this.formData.BannerUrl = res.data.image_url;
      console.log(res.data.image_url);
    },
    beforeAvatarUpload(file) {
      let limitSize = 1;

      if (this.formData.BannerType === "1") limitSize = 2;

      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < limitSize;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${limitSize}MB!`);
      }
      return isJPG && isLt2M;
    },
    async markDelBtn(res) {
      this.$confirm("此操作将永久删除该Banner, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await bannerDel(res);
          await this.getBannerList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      console.log(res);
    },
    timeChange() {
      this.formData.StartDate = moment(this.tempStartDateEndDate[0]).format(
        "YYYY-MM-DD"
      );
      this.formData.EndDate = moment(this.tempStartDateEndDate[1]).format(
        "YYYY-MM-DD"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
.bannerListTitle {
  padding: 30px 0;
}
.bannerListContent {
  display: flex;
  flex-wrap: wrap;
}
.addStartPage {
  width: 180px;
  height: 300px;
  border: 2px dashed #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 20px 20px 0;
  &.on {
    border: 0;
  }
  .Mask {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 8;
    display: none;
  }
  .el-icon-plus {
    cursor: pointer;
    color: #409eff;
    font-size: 30px;
  }
}
.addStartPage:hover .Mask.on {
  display: block;
}
.addRotationPage {
  width: 150px;
  height: 180px;
  border: 2px dashed #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 20px 20px 0;
  &.on {
    border: 0;
  }
  .Mask {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 8;
    display: none;
  }

  .el-icon-plus {
    cursor: pointer;
    color: #409eff;
    font-size: 30px;
  }
}
.addRotationPage:hover .Mask.on {
  display: block;
}
.addlotteryPage {
  width: 350px;
  height: 60px;
  border: 2px dashed #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 20px 20px 0;
  &.on {
    border: 0;
  }
  .Mask {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 8;
    display: none;
  }
  .el-icon-plus {
    cursor: pointer;
    color: #409eff;
    font-size: 30px;
  }
}
.addlotteryPage:hover .Mask.on {
  display: block;
}
.addActivityPage {
  width: 350px;
  height: 180px;
  border: 2px dashed #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 20px 20px 0;
  &.on {
    border: 0;
  }
  .Mask {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 8;
    display: none;
  }
  .el-icon-plus {
    cursor: pointer;
    color: #409eff;
    font-size: 30px;
  }
}
.addActivityPage:hover .Mask.on {
  display: block;
}

.avatar-uploader {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.pageImage {
  width: 100%;
  height: 100%;
}
.btnBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .btn {
    cursor: pointer;
    font-size: 30px;
    color: #fff;
  }
}
.footerText {
  position: absolute;
  width: 100%;
  bottom: 0px;
  font-size: 14px;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  background-color: #0082c3;
  z-index: 2;
}
</style>
