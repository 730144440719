<template>
  <div
    class="empty-banner"
    :class="full ? 'is-full' : false"
    :style="'width:' + width + 'px; height:' + height + 'px;'"
  >
    <i
      class="empty-banner-icon el-icon-picture-outline"
      :style="'font-size:' + iconSize + 'px; color:' + iconColor + ';'"
    />
  </div>
</template>

<script>
  export default {
    name: "EmptyBanner",
    props: {
      // 宽度设置
      width: { type: Number, default: 375 },
      // 高度设置
      height: { type: Number, default: 375 },
      // 宽高百分百
      full: { type: Boolean, default: false },
      // 图标大小
      iconSize: { type: Number, default: 50 },
      // 图标颜色
      iconColor: { type: String, default: '#999999' },
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
.empty-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 375px;
  background-color: #f3f5f7;
  border-radius: 0;

  &.is-full {
    width: 100% !important;
    height: 100% !important;
  }
}

.empty-banner-icon {
  font-size: 50px;
  color: #999;
}
</style>
