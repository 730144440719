<template>
  <el-dialog
    :visible.sync="isVisible"
    title="订单详情"
    destroy-on-close
    @close="handleClose"
  >
    <div v-if="detail">
      <el-descriptions title="收货信息" :column="2">
        <el-descriptions-item label="用户昵称">{{
          detail.userInfo.WeChatName
        }}</el-descriptions-item>
        <el-descriptions-item label="收货人">{{
          detail.userInfo.MemberName
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          detail.userInfo.WeChatPhoneNumber
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="收货地址">{{
          detail.orderInfo.user_address
        }}</el-descriptions-item> -->
      </el-descriptions>

      <el-divider></el-divider>
      <el-descriptions title="订单信息" :column="2">
        <el-descriptions-item label="订单ID">{{
          detail.orderInfo.order_id
        }}</el-descriptions-item>
        <el-descriptions-item label="订单状态" contentClassName="text-danger">{{
          detail.orderInfo._status._title
        }}</el-descriptions-item>
        <el-descriptions-item label="商品总数">{{
          detail.orderInfo.total_num
        }}</el-descriptions-item>
        <el-descriptions-item label="商品总价">{{
          detail.orderInfo.total_price
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="交付邮费">{{
          detail.orderInfo.pay_postage
        }}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="优惠券金额">{{
          detail.orderInfo.coupon_price
        }}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="会员商品优惠">{{
          detail.orderInfo.vip_true_price || 0.0
        }}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="积分抵扣" contentClassName="text-info">{{
          detail.orderInfo.deduction_price || 0.0
        }}</el-descriptions-item> -->
        <el-descriptions-item label="实际支付">{{
          detail.orderInfo.pay_price
        }}</el-descriptions-item>
        <!-- <el-descriptions-item
          v-if="parseFloat(detail.orderInfo.refund_price)"
          label="退款金额"
          >{{ detail.orderInfo.refund_price }}</el-descriptions-item
        > -->
        <el-descriptions-item
          v-if="parseFloat(detail.orderInfo.use_integral)"
          label="使用积分"
          >{{ detail.orderInfo.pay_price }}</el-descriptions-item
        >
        <!-- <el-descriptions-item
          v-if="parseFloat(detail.orderInfo.back_integral)"
          label="退款金额"
          >{{ detail.orderInfo.refund_price }}</el-descriptions-item
        > -->
        <el-descriptions-item label="创建时间">{{
          detail.orderInfo._add_time
        }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{
          detail.orderInfo._pay_time
        }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{
          detail.orderInfo._status._payType
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="推广人">{{
          (detail.orderInfo.spread_name || "") +
          "/" +
          detail.orderInfo.spread_uid
        }}</el-descriptions-item> -->
        <el-descriptions-item
          label="门店名称"
          v-if="
            detail.orderInfo.shipping_type === 2 &&
            detail.orderInfo.refund_status === 0 &&
            detail.orderInfo.paid === 1
          "
        >
          {{ detail.orderInfo._store_name }}
        </el-descriptions-item>
        <!-- <el-descriptions-item
          label="核销码"
          v-if="
            detail.orderInfo.shipping_type === 2 &&
            detail.orderInfo.refund_status === 0 &&
            detail.orderInfo.paid === 1
          "
        >
          {{ detail.orderInfo.verify_code }}
        </el-descriptions-item> -->
        <el-descriptions-item label="商家备注">{{
          detail.orderInfo.remark
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="虚拟发货备注">{{
          detail.orderInfo.fictitious_content
        }}</el-descriptions-item> -->
      </el-descriptions>

      <!-- <template v-if="detail.orderInfo.delivery_type === 'express'">
        <el-divider></el-divider>
        <el-descriptions title="物流信息" :column="2">
          <el-descriptions-item label="快递公司">{{
            detail.orderInfo.delivery_name
          }}</el-descriptions-item>
          <el-descriptions-item label="快递单号">
            {{ detail.orderInfo.delivery_id }}
            <el-button
              type="primary"
              size="mini"
              class="mgl10"
              @click="handleViewLogistics"
              >物流查询</el-button
            >
          </el-descriptions-item>
        </el-descriptions>
      </template> -->

      <!-- <template v-if="detail.orderInfo.delivery_type === 'send'">
        <el-divider></el-divider>
        <el-descriptions title="配送信息" :column="2">
          <el-descriptions-item label="送货人姓名">{{
            detail.orderInfo.delivery_name
          }}</el-descriptions-item>
          <el-descriptions-item label="送货人电话">{{
            detail.orderInfo.delivery_id
          }}</el-descriptions-item>
        </el-descriptions>
      </template> -->

      <template v-if="detail.orderInfo.mark">
        <el-divider></el-divider>
        <el-descriptions title="备注信息" :colon="false" :column="1">
          <el-descriptions-item
            labelClassName="none"
            contentClassName="text-remark"
            >{{ detail.orderInfo.mark }}</el-descriptions-item
          >
        </el-descriptions>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { fetchOrderDetail } from "@/api/order";

export default {
  name: "OrderDetail",
  data() {
    return {
      // 是否显示弹框
      isVisible: false,
      // 订单详情数据
      detail: false,
    };
  },
  methods: {
    // 由外部调用显示弹框
    show({ orderId } = {}) {
      if (orderId) {
        this.detail = false;

        this.getOrderDetail(orderId);
      }
    },

    // 关闭弹框
    handleClose() {
      this.isVisible = false;
    },

    // 获取订单详情数据
    async getOrderDetail(orderId) {
      const orderDetail = await fetchOrderDetail(orderId);

      this.detail = orderDetail.data;
      this.isVisible = true;
      // setTimeout(() => {
      //   const mockData = {
      //     status: 200,
      //     msg: "ok",
      //     data: {
      //       orderInfo: {
      //         id: 4230,
      //         pid: 0,
      //         order_id: "wx193240074798759936",
      //         trade_no: "",
      //         uid: 23017,
      //         real_name: "张三",
      //         user_phone: "18203196895",
      //         user_address: "河北省 石家庄市 市辖区 团结路",
      //         cart_id: ["23017193240026547486720"],
      //         total_num: 1,
      //         total_price: "0.01",
      //         freight_price: "0.00",
      //         total_postage: "0.00",
      //         pay_price: "0.00",
      //         pay_postage: "0.00",
      //         deduction_price: "0.01",
      //         coupon_id: 0,
      //         coupon_price: "0.00",
      //         paid: 1,
      //         pay_time: 1637358424,
      //         pay_type: "yue",
      //         add_time: 1637358424,
      //         status: 2,
      //         refund_status: 0,
      //         refund_type: 0,
      //         refund_express: "",
      //         refund_reason_wap_img: null,
      //         refund_reason_wap_explain: null,
      //         refund_reason_time: null,
      //         refund_reason_wap: null,
      //         refund_reason: null,
      //         refund_price: "0.00",
      //         delivery_name: "泰捷达国际物流",
      //         delivery_code: "ztjieda",
      //         delivery_type: "express",
      //         delivery_id: "wx193240074798759936",
      //         fictitious_content: "",
      //         delivery_uid: 0,
      //         gain_integral: "0.00",
      //         use_integral: "1.00",
      //         back_integral: null,
      //         spread_uid: 23017,
      //         spread_two_uid: 0,
      //         one_brokerage: "0.00",
      //         two_brokerage: "0.00",
      //         mark: "订单未支付已超过系统预设时间",
      //         is_del: 0,
      //         unique: "193240028191653888",
      //         remark: "这是一个由用户发起的备注内容，仅供测试！",
      //         mer_id: 0,
      //         is_mer_check: 0,
      //         combination_id: 0,
      //         pink_id: 0,
      //         cost: "10.00",
      //         seckill_id: 0,
      //         bargain_id: 0,
      //         advance_id: 0,
      //         verify_code: "",
      //         store_id: 0,
      //         shipping_type: 1,
      //         clerk_id: 0,
      //         is_channel: 1,
      //         is_remind: 1,
      //         is_system_del: 0,
      //         channel_type: "routine",
      //         province: "北京",
      //         express_dump: "",
      //         virtual_type: 0,
      //         virtual_info: "",
      //         cartInfo: [
      //           {
      //             id: "23017193240026547486720",
      //             type: 0,
      //             seckill_id: 0,
      //             bargain_id: 0,
      //             combination_id: 0,
      //             advance_id: 0,
      //             product_id: 61,
      //             product_attr_unique: "42fbcf73",
      //             cart_num: 1,
      //             productInfo: {
      //               id: 61,
      //               mer_id: 0,
      //               image:
      //                 "https://demo26.crmeb.net/uploads/thumb_water/eaa938547ca336a01afa07d74df0a8cc.jpg",
      //               recommend_image: "",
      //               slider_image: [
      //                 "http://demo26.crmeb.net/uploads/attach/2020/10/14/29c7a967af5ac151cbaff6e5d35e871a.jpg",
      //                 "http://demo26.crmeb.net/uploads/attach/2020/10/14/7c320ab0ba0187cd8654104c9030dd4d.jpg",
      //                 "http://demo26.crmeb.net/uploads/attach/2020/10/14/52cb04810b1f6eb470c2ac3315b04c85.jpg",
      //                 "http://demo26.crmeb.net/uploads/attach/2020/10/14/d11d6a962466677d8e78a65d9c4b752e.jpg",
      //                 "http://demo26.crmeb.net/uploads/attach/2020/10/14/7aab7720993aea3592dfdbc7c039862c.jpg",
      //               ],
      //               store_name:
      //                 "阿迪达斯官网adidas LIN CORE ORG男女训练运动小肩包DT4822 如图 NS",
      //               store_info:
      //                 "阿迪达斯官网adidas LIN CORE ORG男女训练运动小肩包DT4822 如图 NS",
      //               keyword: "",
      //               bar_code: "",
      //               cate_id: "54",
      //               price: "0.01",
      //               vip_price: "0.00",
      //               ot_price: "1600.00",
      //               postage: "0.00",
      //               unit_name: "件",
      //               sort: 0,
      //               sales: 174,
      //               stock: 826,
      //               is_show: 1,
      //               is_hot: 0,
      //               is_benefit: 0,
      //               is_best: 1,
      //               is_new: 0,
      //               is_virtual: 0,
      //               virtual_type: 0,
      //               add_time: 1602646091,
      //               is_postage: 0,
      //               is_del: 0,
      //               mer_use: 0,
      //               give_integral: "0.00",
      //               cost: "10.00",
      //               is_seckill: 0,
      //               is_bargain: null,
      //               is_good: 0,
      //               is_sub: 0,
      //               is_vip: 0,
      //               ficti: 0,
      //               browse: 0,
      //               code_path: "",
      //               soure_link: "https://item.jd.com/51019765571.html",
      //               temp_id: 65,
      //               spec_type: 1,
      //               activity: "0,1,2,3",
      //               spu: "9851485333907",
      //               label_id: "0",
      //               video_link: "",
      //               command_word: "",
      //               recommend_list: "",
      //               attrInfo: {
      //                 product_id: 61,
      //                 suk: "如图,NS",
      //                 stock: 826,
      //                 sales: 1,
      //                 price: "0.01",
      //                 image:
      //                   "https://img30.360buyimg.com/popWaterMark/jfs/t1/56263/40/3410/170836/5d135777E2a114999/1b9d7b36b29814ba.jpg",
      //                 bar_code: "",
      //                 ot_price: "1600.00",
      //                 volume: "0.00",
      //                 weight: "0.00",
      //                 brokerage: "0.00",
      //                 brokerage_two: "0.00",
      //                 type: 0,
      //                 unique: "42fbcf73",
      //                 cost: "10.00",
      //                 quota: 0,
      //                 quota_show: 0,
      //                 vip_price: "0.00",
      //                 is_virtual: 0,
      //                 coupon_id: 0,
      //               },
      //             },
      //             sum_price: "0.01",
      //             truePrice: 0,
      //             trueStock: 826,
      //             costPrice: "10.00",
      //             attrStatus: true,
      //             vip_truePrice: 0,
      //             price_type: "level",
      //             is_valid: 1,
      //             use_integral: "1",
      //             integral_price: "0.01",
      //             coupon_price: 0,
      //             one_brokerage: "0.00",
      //             two_brokerage: "0.00",
      //             postage_price: "0.00",
      //             sum_true_price: "0.00",
      //             unique: "09afb4a668bf610f2825e3d78458fe7d",
      //             is_reply: 0,
      //             vip_sum_truePrice: "0.00",
      //           },
      //         ],
      //         _status: {
      //           _type: 3,
      //           _title: "待评价",
      //           _msg: "已收货,快去评价一下吧",
      //           _class: "state-ypj",
      //           _payType: "余额支付",
      //           _deliveryType: "快递配送",
      //         },
      //         _pay_time: "2021-11-20 05:47:04",
      //         _add_time: "2021-11-20 05:47:04",
      //         stop_time: 1637444824,
      //         status_pic:
      //           "http://datong.crmeb.net/public/uploads/attach/2019/03/28/5c9ccca178a67.gif",
      //         offlinePayStatus: 1,
      //         order_log: {
      //           create: "2021-11-20",
      //           pay: "2021-11-20",
      //           delivery: "2021-11-20",
      //           take: "2021-11-20",
      //           complete: "",
      //         },
      //         vip_true_price: 0,
      //         _store_name: "",
      //       },
      //       userInfo: {
      //         uid: 23017,
      //         account: "wx34481633255446",
      //         nickname: "vigoss",
      //         real_name: "张三",
      //         avatar:
      //           "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKCNIqkDic3ibzGcfCzDbG8v4hnpxoyqjTTL5ArbBGaZNWGdOjNluRoNHkueyPlvlhye3miayYJjmlhA/132",
      //         phone: "182****6895",
      //         add_time: 1633255446,
      //         last_time: 1636623814,
      //         now_money: "0.00",
      //         brokerage_price: "0.00",
      //         integral: "64.00",
      //         exp: "120.00",
      //         sign_num: 1,
      //         status: 1,
      //         level: 0,
      //         agent_level: 0,
      //         spread_open: 1,
      //         spread_uid: "",
      //         spread_time: 0,
      //         user_type: "routine",
      //         is_promoter: 0,
      //         pay_count: 8,
      //         spread_count: 0,
      //         clean_time: 0,
      //         birthday: "",
      //         card_id: "",
      //         mark: "",
      //         group_id: 0,
      //         addres: "",
      //         adminid: 0,
      //         record_phone: "18203196895",
      //         is_money_level: 0,
      //         is_ever_level: 0,
      //         overdue_time: 1635165406,
      //         uniqid: "",
      //         spread_name: "无",
      //       },
      //     },
      //   };
    },

    // 查看物流
    handleViewLogistics() {
      console.log("handleViewLogistics");
    },
  },
};
</script>

<style lang="scss" scope>
</style>
