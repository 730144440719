import request from "@/utils/request";

// 抽奖活动列表
export function CampaignList(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/CampaignList",
    method: "get",
    params: data
  });
}

// 添加、修改抽奖活动
export function addCampaign(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/addCampaign",
    method: "post",
    data
  });
}
// 删除抽奖活动
export function delActivity(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/delCampaign",
    method: "get",
    params: data
  });
}

// 中间活动名单
export function PrizeMemberList(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/PrizeMemberList",
    method: "get",
    params: data
  });
}
// 获动详情
export function CampaignDetail(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/CampaignDetail",
    method: "get",
    params: data
  });
}

// 兑换状态修改
export function ExchangePrizeMember(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/ExchangePrizeMember",
    method: "get",
    params: data
  });
}

// 删除奖品
export function delCampaignPrize(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/delCampaignPrize",
    method: "get",
    params: data
  });
}
