<template>
  <div class="config-cont">
    <el-tabs value="content" class="main-tabs-side">
      <el-tab-pane name="content" label="内容设置">
        <div class="config-section">
          <div class="config-section-title">选择模板</div>
          <div class="config-section-cont">
            <el-radio-group v-model="currentValue.chooseType" size="mini">
              <el-radio-button label="auto">自动选择</el-radio-button>
              <el-radio-button label="custom">手动选择</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-divider></el-divider>
        <template v-if="currentValue.chooseType === 'custom'">

          <vuedraggable
            class="choose-goods-box"
            filter=".choose-add"
            :list="currentValue.chooseGoodsList"
          >
            <div v-for="(item, index) in currentValue.chooseGoodsList" :key="item.id" class="choose-item">
              <img class="choose-item-img" :src="imageBaseUrl + item.image" alt="" />
              <div class="delete-icon" @click="handleDeleteItem(item, index)">
                <i class="el-icon-error"></i>
              </div>
            </div>
            <div class="choose-add" @click="handleChooseGoods"><i class="el-icon-plus"></i></div>
          </vuedraggable>
          <el-divider></el-divider>
        </template>
        <el-row v-if="currentValue.chooseType === 'auto'" class="config-item">
          <el-col :span="4" class="config-label">商品分类</el-col>
          <el-col :span="19">
            <el-select
              placeholder="商品分类"
              clearable
              size="small"
              v-model="currentValue.classifyValue"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.id"
                :value="item.id"
                :label="item.html + item.cate_name"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="currentValue.chooseType === 'auto'" class="config-item">
          <el-col :span="4" class="config-label">商品数量</el-col>
          <el-col :span="19">
            <el-input-number v-model="currentValue.goodsNum" size="small" controls-position="right" :min="1" />
          </el-col>
        </el-row>
        <div v-if="currentValue.chooseType === 'auto'" class="config-prop">
          <div class="config-label">商品排序</div>
          <el-radio-group v-model="currentValue.sortType" size="mini">
            <el-radio-button label="default">综合</el-radio-button>
            <el-radio-button label="sell">销量</el-radio-button>
            <el-radio-button label="price">价格</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">是否显示名称</div>
          <el-switch v-model="currentValue.showGoodsName"></el-switch>
        </div>
        <div class="config-prop">
          <div class="config-label">是否显示价格</div>
          <el-switch v-model="currentValue.showPrice"></el-switch>
        </div>
        <div class="config-prop">
          <div class="config-label">是否显示划线价</div>
          <el-switch v-model="currentValue.showOldPrice"></el-switch>
        </div>
      </el-tab-pane>

      <el-tab-pane name="style" label="样式设置">
        <div class="config-prop">
          <div class="config-label">背景颜色</div>
          <el-color-picker v-model="currentValue.bgColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">标题颜色</div>
          <el-color-picker v-model="currentValue.titleColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">价格颜色</div>
          <el-color-picker v-model="currentValue.priceColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">划线价颜色</div>
          <el-color-picker v-model="currentValue.oldPriceColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">显示类型</div>
          <el-radio-group v-model="currentValue.layoutType" size="mini">
            <el-radio-button label="single">单列</el-radio-button>
            <el-radio-button label="double">两列</el-radio-button>
            <el-radio-button label="triple">三列</el-radio-button>
            <el-radio-button label="banner">大图</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">背景样式</div>
          <el-radio-group v-model="currentValue.bgRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">内容样式</div>
          <el-radio-group v-model="currentValue.contRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="currentValue.layoutType === 'double' || currentValue.layoutType === 'triple'" class="config-prop">
          <div class="config-label">标题行数</div>
          <el-radio-group v-model="currentValue.titleRows" size="mini">
            <el-radio-button label="1">1行</el-radio-button>
            <el-radio-button label="2">2行</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginHorizontal" />
            <el-input-number v-model="currentValue.marginHorizontal" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">顶部边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginTop"  />
            <el-input-number v-model="currentValue.marginTop" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 选择商品弹框 -->
    <ChooseGoodsDialog v-model="isShowGoodsDialog" @complete="handleChooseComplete" />
  </div>
</template>

<script>
  import vuedraggable from "vuedraggable";
  import { getFisrtLevelClassifyApi, fetchProdctList } from "@/api/product";
  import { imageBaseUrl } from "@/utils/config";

  import ChooseGoodsDialog from '@/components/ChooseGoodsDialog/ChooseGoodsDialog.vue'

  // 默认配置
  const DEFAULT_DATA = {
    // 内容设置
    chooseType: 'auto',
    classifyValue: '',
    goodsNum: 6,
    chooseGoodsList: [],
    chooseGoodsIds: [],
    sortType: 'default',
    showGoodsName: true,
    showOldPrice: true,
    showPrice: true,
    // 样式设置
    bgColor: '',
    titleColor: '#333333',
    priceColor: '#e93323',
    oldPriceColor: '#aaaaaa',
    layoutType: 'single',
    bgRadiusType: 'rect',
    contRadiusType: 'radius',
    titleRows: '1',
    marginHorizontal: 0,
    marginTop: 0,
  }

  export default {
    name: "ConfigGoodsList",
    components: {
      vuedraggable,
      ChooseGoodsDialog,
    },
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
        // 商品分类列表
        categoryList: [],
        // 是否显示商品弹框
        isShowGoodsDialog: false,
        // 图片资源路径
        imageBaseUrl,
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
        this.getCategoryList()
      }

      // 首次加载手动选择商品模块，需要根据配置的商品id加载商品数据
      const { chooseType, chooseGoodsList, chooseGoodsIds } = this.currentValue
      if (
        chooseType === 'custom'
        && chooseGoodsList.length === 0
        && chooseGoodsIds.length > 0
      ) {
        this.getGoodsList()
      }
    },
    methods: {
      // 获取商品分类列表
      async getCategoryList() {
        const { data } = await getFisrtLevelClassifyApi();
        this.categoryList = data;
      },

      // 根据商品id获取商品数据
      async getGoodsList() {
        const sendData = {
          page: 1,
          limit: 999,
          MallCode: '',
          type: 1,
          is_virtual: 2,
          id: this.currentValue.chooseGoodsIds.join(','),
        };
        const res = await fetchProdctList(sendData);
        this.currentValue.chooseGoodsList = res.data.list || []
      },

      // 点击选择商品
      handleChooseGoods() {
        this.isShowGoodsDialog = true
      },

      // 选择商品完成事件
      handleChooseComplete(goodsList) {
        const allList = [...this.currentValue.chooseGoodsList, ...goodsList]
        const result = []
        const checkRepeat = {}
        allList.forEach((item) => {
          if (!checkRepeat[item.id]) {
            checkRepeat[item.id] = 1
            result.push(item)
          }
        })
        this.currentValue.chooseGoodsList = result
      },

      // 删除内容项
      handleDeleteItem(item, index) {
        this.currentValue.chooseGoodsList.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';

.choose-goods-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.choose-item {
  position: relative;
  width: 78px;
  height: 78px;
  margin-right: 12px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.choose-item-img {
  display: block;
  width: 100%;
  height: 100%;
}

.delete-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;

  i {
    font-size: 20px;
    color: #999;
  }
}

.choose-add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  margin-right: 12px;
  margin-bottom: 20px;
  background-color: #f7f7f7;

  i {
    font-size: 20px;
    color: #d8d8d8;
  }
}
</style>
