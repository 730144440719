<template>
  <div
    :class="layoutClass"
    :style="layoutStyle"
  >
    <div class="comp-title-cont">
      <div class="comp-title-main" :style="titleStyle">{{config.title || '标题'}}</div>
      <div v-if="config.subTitle" class="comp-title-sub" :style="subTitleStyle">{{config.subTitle}}</div>
    </div>
    <div
      v-if="config.direction === 'row' && config.align === 'left' && config.showMore"
      class="comp-title-side"
      :style="subTitleStyle"
    >
      {{config.moreText || '更多'}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "WidgetTitle",
    props: {
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
      }
    },
    computed: {
      // 容器样式
      layoutStyle() {
        const styleObj = {}
        const {bgColor, marginHorizontal, marginTop} = this.config

        if (bgColor) {
          styleObj.backgroundColor = bgColor
        }

        if (marginHorizontal) {
          styleObj.marginLeft = `${marginHorizontal}px`
          styleObj.marginRight = `${marginHorizontal}px`
        }

        if (marginTop) {
          styleObj.marginTop = `${marginTop}px`
        }

        return styleObj;
      },

      // 容器类名
      layoutClass() {
        const classValues = []
        const { direction, align, titleStyle, showMore } = this.config
        classValues.push('comp-title')
        classValues.push(`direction-${direction}`)
        classValues.push(`align-${align}`)
        classValues.push(`title-${titleStyle}`)
        if (showMore) {
          classValues.push(`show-more`)
        }
        return classValues.join(' ');
      },

      // 标题样式
      titleStyle() {
        const styleObj = {}
        const {titleColor, titleSize} = this.config

        if (titleColor) {
          styleObj.color = titleColor
        }

        if (titleSize) {
          styleObj.fontSize = `${titleSize}px`
          styleObj.lineHeight = `${titleSize + 2}px`
        }

        return styleObj;
      },

      // 副标题样式
      subTitleStyle() {
        const styleObj = {}
        const {subTitleColor, titleSize} = this.config

        if (subTitleColor) {
          styleObj.color = subTitleColor
        }

        if (titleSize) {
          styleObj.fontSize = `${Math.floor(titleSize * 0.6)}px`
          styleObj.lineHeight = `${Math.floor(titleSize * 0.6) + 2}px`
        }

        return styleObj;
      },
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>

.comp-title {
  &.direction-column {
    display: flex;
    flex-direction: column;

    .comp-title-cont {
      display: flex;
      flex-direction: column;
    }

    &.align-left,
    &.align-left .comp-title-cont {
      align-items: flex-start;
    }
    &.align-center,
    &.align-center .comp-title-cont {
      align-items: center;
    }
    &.align-right,
    &.align-right .comp-title-cont {
      align-items: flex-end;
    }

    .comp-title-sub {
      margin-top: 10px;
    }
  }

  &.direction-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .comp-title-cont {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    &.align-left,
    &.align-left .comp-title-cont {
      justify-content: flex-start;
    }
    &.align-center,
    &.align-center .comp-title-cont {
      justify-content: center;
    }
    &.align-right,
    &.align-right .comp-title-cont {
      justify-content: flex-end;
    }

    &.show-more {
      justify-content: space-between;
      align-items: center;
    }

    .comp-title-sub {
      position: relative;
      top: -2px;
      margin-left: 10px;
    }
  }

  &.title-bold {
    .comp-title-main {
      font-weight: bold;
    }
  }

  &.title-italic {
    .comp-title-main {
      font-style: italic;
    }
  }
}
</style>
