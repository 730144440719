import request from "@/utils/request";

// 表格数据的混合对象
export default {
  data() {
    return {
      // 是否自动加载列表数据
      isAutoload: true,
      // 是否已经挂载完毕
      isReady: false,
      // 是否处于数据加载中
      isLoading: false,
      // 数据是否已经加载完毕
      isDone: false,
      // 列表接口的函数（fetchAction、fetchUrl二选一）
      fetchAction: undefined,
      // 列表接口的url（fetchAction、fetchUrl二选一）
      fetchUrl: '',
      // 列表数据
      listData: [],
      // 搜索条件
      searchData: {},
      // 分页组件：每页条数
      pageSize: 10,
      // 分页组件：当前页码
      currentPage: 1,
      // 分页组件：数据总条数
      totalElements: 0,
      // 分页组件：每页展示条数
      paginationSizes: [10, 25, 50, 100],
      // 分页组件：布局规则
      paginationLayout: 'total, sizes, prev, pager, next, jumper',
    }
  },
  created() {
    if (this.isAutoload) {
      this.reloadListData()
    }
  },
  mounted() {
    this.isReady = true
  },
  methods: {
    // 重新加载列表内容
    reloadListData() {
      this.currentPage = 1
      this.getListData()
    },

    // 更新当前页码的内容
    updateListData() {
      console.log('loading')
      this.getListData()
    },

    // 获取列表数据
    async getListData() {
      console.log('searchData', this.searchData)
      this.isLoading = true

      let res = { data: { count: 1, list: [] }, msg: "ok", status: 200 }
      if (this.setMockData) {
        // mock数据配置
        res = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.setMockData())
          }, 300)
        })
      } else if (this.fetchAction) {
        // 请求接口数据
        const fetchData = await this.fetchAction(this.searchData);
        res = fetchData
      } else if (this.fetchUrl) {
        // 请求接口数据
        const fetchData = await request({ url: this.fetchUrl, data: this.searchData, method: "get" });
        res = fetchData
      }

      this.isLoading = false
      this.isDone = true
      this.totalElements = res.data.count || 0

      if (res && res.status === 200) {
        this.listData = (res.data && res.data.list) || (res.data && res.data.count !== undefined ? [] : res.data) || []
        if (typeof this.onListComplete === 'function') {
          this.onListComplete({ listData: this.listData, sourceData: res })
        }
      }
    },

    // 筛选表单：搜索重置
    handleReset() {
      this.$refs.mainFilterForm.resetFields()
      this.reloadListData()
    },

    // 筛选表单：搜索查询
    handleSearch() {
      this.reloadListData()
    },

    // 表格分页：变更每页展示条数
    handleSizeChange(pageSize) {
      this.searchData.limit = pageSize
      this.reloadListData()
    },

    // 表格分页：当前的页码变更
    handleCurrentChange(pageNo) {
      this.searchData.page = pageNo
      this.reloadListData()
    },
  }
}
