// "dev" | "staging" | "prod"
let env = "staging";
// let apiUrls = {
//   dev: `https://clienteling-backend.yuyiux.com`,
//   staging: "https://clienteling-backend.yuyiux.com",
//   prod: "https://clienteling-backend.yuyiux.com"
// };
// let imageBaseUrls = {
//   dev: "https://clienteling-backend.yuyiux.com",
//   staging: "https://clienteling-backend.yuyiux.com",
//   prod: "https://clienteling-backend.yuyiux.com"
// };
let apiUrls = {
  dev: `https://hj-backend-dev.idigitalforce.com`,
  staging: "https://hj-backend-staging.idigitalforce.com",
  prod: "https://hj-backend-prod.idigitalforce.com"
};
let imageBaseUrls = {
  dev: "https://hj-backend-dev.idigitalforce.com",
  staging: "https://hj-backend-staging.idigitalforce.com",
  prod: "https://hj-backend-prod.idigitalforce.com"
};

if (window.location.origin.toLowerCase().includes("idigitalforce")) {
  apiUrls = {
    dev: `https://hj-backend-dev.idigitalforce.com`,
    staging: "https://hj-backend-staging.idigitalforce.com",
    prod: "https://hj-backend-prod.idigitalforce.com"
  };
  imageBaseUrls = {
    dev: "https://hj-backend-dev.idigitalforce.com",
    staging: "https://hj-backend-staging.idigitalforce.com",
    prod: "https://hj-backend-prod.idigitalforce.com"
  };
}

if (window.location.origin.toLowerCase().includes("-dev")) env = "dev";

if (window.location.origin.toLowerCase().includes("-staging")) env = "staging";

if (window.location.origin.toLowerCase().includes("-prod")) env = "prod";

console.log({ env });

export const apiUrl = apiUrls[env];
export const imageBaseUrl = imageBaseUrls[env];
