<template>
  <div class="main-content">
    <!-- 主体内容区 -->
    <div class="main-section">
      <el-tabs class="main-tabs-side" tab-position="left">
        <el-tab-pane label="商城首页">
          <MallIndex />
        </el-tab-pane>
        <el-tab-pane label="商品分类">
          <GoodsCategory />
        </el-tab-pane>
        <el-tab-pane label="个人中心">
          <UserCenter />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import MallIndex from './modules/MallIndex'
import GoodsCategory from './modules/GoodsCategory'
import UserCenter from './modules/UserCenter'

export default {
  name: "DesignManage",
  components: {
    MallIndex,
    GoodsCategory,
    UserCenter
  },
  data() {
    return { };
  },
  computed: {
  },
  created() {},
  methods: {
  },
};
</script>

<style lang="scss" scope>
.main-tabs-side {
  &.el-tabs--left {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .el-tabs__header {
      float: none;
    }

    .el-tabs__content {
      min-width: 100px;
      flex-grow: 1;
    }
  }
}
</style>
