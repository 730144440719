<template>
  <div class="config-cont">
    <el-tabs value="content" class="main-tabs-side">
      <el-tab-pane name="content" label="内容设置">
        <div class="config-section">
          <div class="config-section-title">选择样式 <span class="config-section-title-value">{{styleTypeInfo[currentValue.styleType].title}}</span></div>
          <div class="banner-style">
            <el-radio-group v-model="currentValue.styleType" size="mini" @change="handleStyleTypeChange">
              <el-radio-button label="single">
                <div class="radio-layout-single">
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
              <el-radio-button label="two">
                <div class="radio-layout-two">
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
              <el-radio-button label="three">
                <div class="radio-layout-three">
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
              <el-radio-button label="threePalaces">
                <div class="radio-layout-threePalaces">
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
              <el-radio-button label="four">
                <div class="radio-layout-four">
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
              <el-radio-button label="fourPalaces">
                <div class="radio-layout-fourPalaces">
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                  <div class="radio-layout-block"></div>
                </div>
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="config-section">
          <div class="config-section-title">布局</div>
          <div class="config-section-msg">选定布局区域，在下方添加图片，建议添加比例一致的图片</div>
          <div class="banner-layout">
            <div v-if="currentValue.styleType === 'single'" class="banner-layout-single">
              <div class="banner-layout-item">
                <div class="banner-layout-text">尺寸不限</div>
              </div>
            </div>
            <div v-else-if="currentValue.styleType === 'two'" class="banner-layout-two">
              <div class="banner-layout-item" :class="bannerIndex === 0 ? 'active' : ''" @click="handleChooseBanner(0)">
                <div class="banner-layout-text">宽375像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 1 ? 'active' : ''" @click="handleChooseBanner(1)">
                <div class="banner-layout-text">宽375像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
            </div>
            <div v-else-if="currentValue.styleType === 'three'" class="banner-layout-three">
              <div class="banner-layout-item" :class="bannerIndex === 0 ? 'active' : ''" @click="handleChooseBanner(0)">
                <div class="banner-layout-text">宽250像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 1 ? 'active' : ''" @click="handleChooseBanner(1)">
                <div class="banner-layout-text">宽250像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 2 ? 'active' : ''" @click="handleChooseBanner(2)">
                <div class="banner-layout-text">宽250像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
            </div>
            <div v-else-if="currentValue.styleType === 'threePalaces'" class="banner-layout-threePalaces">
              <div class="banner-layout-item" :class="bannerIndex === 0 ? 'active' : ''" @click="handleChooseBanner(0)">
                <div class="banner-layout-text">375*375像素或同比例</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 1 ? 'active' : ''" @click="handleChooseBanner(1)">
                <div class="banner-layout-text">375*188像素或同比例</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 2 ? 'active' : ''" @click="handleChooseBanner(2)">
                <div class="banner-layout-text">188像素或同比例</div>
              </div>
            </div>
            <div v-else-if="currentValue.styleType === 'four'" class="banner-layout-four">
              <div class="banner-layout-item" :class="bannerIndex === 0 ? 'active' : ''" @click="handleChooseBanner(0)">
                <div class="banner-layout-text">宽188像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 1 ? 'active' : ''" @click="handleChooseBanner(1)">
                <div class="banner-layout-text">宽188像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 2 ? 'active' : ''" @click="handleChooseBanner(2)">
                <div class="banner-layout-text">宽188像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 3 ? 'active' : ''" @click="handleChooseBanner(3)">
                <div class="banner-layout-text">宽188像素</div>
                <div class="banner-layout-text">高度不限</div>
              </div>
            </div>
            <div v-else-if="currentValue.styleType === 'fourPalaces'" class="banner-layout-fourPalaces">
              <div class="banner-layout-item" :class="bannerIndex === 0 ? 'active' : ''" @click="handleChooseBanner(0)">
                <div class="banner-layout-text">375*188像素或同比例</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 1 ? 'active' : ''" @click="handleChooseBanner(1)">
                <div class="banner-layout-text">375*188像素或同比例</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 2 ? 'active' : ''" @click="handleChooseBanner(2)">
                <div class="banner-layout-text">375*188像素或同比例</div>
              </div>
              <div class="banner-layout-item" :class="bannerIndex === 3 ? 'active' : ''" @click="handleChooseBanner(3)">
                <div class="banner-layout-text">375*188像素或同比例</div>
              </div>
            </div>
          </div>
          <div class="banner-setting">
            <UploadFile
              v-model="currentValue.imageList[bannerIndex].image"
              acceptType="image"
              iconClass="el-icon-picture-outline"
            />
            <div class="rowmid">
              <div class="text-muted mgr10">链接</div>
              <el-input
                v-model="currentValue.imageList[bannerIndex].page"
                placeholder="请输入链接"
                size="small"
              />
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="style" label="样式设置">
        <div class="config-prop">
          <div class="config-label">背景颜色</div>
          <el-color-picker v-model="currentValue.bgColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">背景圆角</div>
          <el-radio-group v-model="currentValue.bgRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">图片圆角</div>
          <el-radio-group v-model="currentValue.imgRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginHorizontal" />
            <el-input-number v-model="currentValue.marginHorizontal" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">顶部边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginTop"  />
            <el-input-number v-model="currentValue.marginTop" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div v-if="currentValue.styleType !== 'single'" class="config-prop">
          <div class="config-label">内部间距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.paddingValue"  />
            <el-input-number v-model="currentValue.paddingValue" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import designUtils from '../utils/designUtils'

  // 生成默认的内容项
  const makeDefaultItem = () => {
    return { id: designUtils.createRandomId(), image: '', page: ''}
  }

  // 默认配置
  const DEFAULT_DATA = {
    // 内容设置
    styleType: 'single',
    imageList: [makeDefaultItem()],
    // 样式设置
    bgColor: '',
    bgRadiusType: 'rect',
    imgRadiusType: 'rect',
    marginHorizontal: 0,
    marginTop: 0,
    paddingValue: 0,
  }

  export default {
    name: "ConfigPictureCube",
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
        // 当前选择的banner索引
        bannerIndex: 0,
        // 样式类型信息
        styleTypeInfo: {
          single: { num: 1, title: '单张布局'},
          two: { num: 2, title: '两列布局'},
          three: { num: 3, title: '三列布局'},
          threePalaces: { num: 3, title: '三宫格'},
          four: { num: 4, title: '四列布局'},
          fourPalaces: { num: 4, title: '四宫格'},
        }
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
      // 变更布局类型
      handleStyleTypeChange(value) {
        const nums = (this.styleTypeInfo[value] || {}).num || 1
        const imageListLen = this.currentValue.imageList.length

        if (nums < imageListLen) {
          this.currentValue.imageList = this.currentValue.imageList.slice(0, nums)
        } else {
          for (let i = imageListLen; i < nums; i += 1) {
            this.currentValue.imageList.push(makeDefaultItem())
          }
        }
        this.bannerIndex = 0
      },

      // 选择banner图片项
      handleChooseBanner(index) {
        this.bannerIndex = index
      },
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';

// 图片样式
.banner-style {
  margin-top: 15px;

  .el-radio-group {
    display: flex;
    flex-direction: row;
  }

  .el-radio-button {
    margin-right: 9px !important;
  }

  .el-radio-button__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 34px !important;
    border: 1px solid #dcdfe6 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #fff !important;

    .radio-layout-block {
      background-color: #1890ff;
    }
  }

  .radio-layout-block {
    width: 15px;
    height: 10px;
    background-color: #666;
  }

  .radio-layout-two {
    display: flex;
    flex-direction: row;

    .radio-layout-block {
      width: 6px;
      height: 10px;

      &:nth-child(2) {
        margin-left: 1px;
      }
    }
  }

  .radio-layout-three {
    display: flex;
    flex-direction: row;

    .radio-layout-block {
      width: 5px;
      height: 10px;

      &:nth-child(2),
      &:nth-child(3) {
        margin-left: 1px;
      }
    }
  }

  .radio-layout-threePalaces {
    position: relative;
    width: 20px;
    padding-left: 5px;

    .radio-layout-block {
      width: 10px;
      height: 5px;

      &:first-child {
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 11px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        margin-left: 1px;
      }

      &:nth-child(3) {
        margin-top: 1px;
      }
    }
  }

  .radio-layout-four {
    display: flex;
    flex-direction: row;

    .radio-layout-block {
      width: 4px;
      height: 10px;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4)  {
        margin-left: 1px;
      }
    }
  }

  .radio-layout-fourPalaces {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 12px;

    .radio-layout-block {
      width: 5px;
      height: 5px;

      &:nth-child(2),
      &:nth-child(4)  {
        margin-left: 1px;
      }

      &:nth-child(3),
      &:nth-child(4)  {
        margin-top: 1px;
      }
    }
  }
}

// 图片布局
.banner-layout {
  .banner-layout-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background-color: #eee;
    cursor: pointer;

    &.active {
      border: 1px solid #1890ff !important;

      .banner-layout-text {
        color: #1890ff;
      }
    }
  }
  .banner-layout-text {
    font-size: 12px;
    color: #8c8c8c;
  }
}

// 单张布局
.banner-layout-single {
  width: 100%;
  height: 180px;

  .banner-layout-item {
    width: 100%;
    height: 100%;
  }
}

// 两列布局
.banner-layout-two {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 180px;

  .banner-layout-item {
    width: 50%;
    height: 100%;
  }
}

// 三列布局
.banner-layout-three {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 180px;

  .banner-layout-item {
    width: 33.33%;
    height: 100%;

    &:nth-child(2),
    &:nth-child(3) {
      border-left-color: transparent;
    }
  }
}

// 三宫格布局
.banner-layout-threePalaces {
  position: relative;
  width: 100%;
  height: 180px;
  padding-left: 50%;

  .banner-layout-item {
    width: 100%;
    height: 90px;

    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      border-left-color: transparent;
    }

    &:nth-child(3) {
      border-top-color: transparent;
    }
  }
}

// 四列布局
.banner-layout-four {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 180px;

  .banner-layout-item {
    width: 25%;
    height: 100%;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-left-color: transparent;
    }
  }
}

// 四宫格布局
.banner-layout-fourPalaces {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 180px;

  .banner-layout-item {
    width: 50%;
    height: 90px;

    &:nth-child(2n) {
      border-left-color: transparent;
    }
    &:nth-child(3),
    &:nth-child(4) {
      border-top-color: transparent;
    }
  }
}

// 图片设置
.banner-setting {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 240px;
  }
}
</style>
