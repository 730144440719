import request from "@/utils/request";

export function memberPoolList(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/list",
    method: "get",
    params: data
  });
}

//

export function counterList(data) {
  return request({
    url: "/counter/list",
    method: "get",
    params: data
  });
}

export function memberPoolmMmberDetail(data) {
  return request({
    url: "/memberPool/memberDetail",
    method: "get",
    params: data
  });
}

export function memberPoolBcInfo(data) {
  return request({
    url: "/memberPool/bcInfo",
    method: "get",
    params: data
  });
}

export function memberPoolSummary(data) {
  return request({
    url: "/memberPool/summary",
    method: "get",
    params: data
  });
}

export function memberPoolHistory(data) {
  return request({
    url: "/memberPool/history",
    method: "get",
    params: data
  });
}

export function memberPoolBpPoints(data) {
  return request({
    url: "/memberPool/bpPoints",
    method: "get",
    params: data
  });
}
export function memberPoolMemberBuylist(data) {
  return request({
    url: "/memberPool/memberBuylist",
    method: "get",
    params: data
  });
}
export function memberPoolBuylist(data) {
  return request({
    url: "/memberPool/memberBuylist",
    method: "get",
    params: data
  });
}
export function memberPoolAssignList(data) {
  return request({
    url: "/memberPool/AssignList",
    method: "get",
    params: data
  });
}

export function memberPoolWecom(data) {
  return request({
    url: "/memberPool/wecom",
    method: "get",
    params: data
  });
}

export function memberPoolFollowupList(data) {
  return request({
    url: "/memberPool/followupList",
    method: "get",
    params: data
  });
}

export function memberPoolRegister(data) {
  return request({
    url: "/memberPool/register",
    method: "get",
    params: data
  });
}

// /memberPool/followupTargetList
export function memberPoolFollowupTargetList(data) {
  return request({
    url: "/memberPool/followupTargetList",
    method: "get",
    params: data
  });
}
// /memberPool/followupScope
export function memberPoolFollowupScope(data) {
  return request({
    url: "/memberPool/followupScope",
    method: "get",
    params: data
  });
}
