<template>
  <div class="main-content">
    <!-- tab切换 -->
    <div class="main-tab">
      <el-tabs v-model="tabValue">
        <el-tab-pane
          v-for="(item) in tabList"
          :key="item.value"
          :name="item.value"
          :label="item.label"
        />
      </el-tabs>
    </div>

    <!-- 会员标签 -->
    <div v-show="tabValue === 'memberTag'">
      <!-- 筛选表单 -->
      <div class="main-section">
        <div class="filter-container">
          <el-input
            style="width: 200px"
            class="filter-item"
            v-on:focus="stopTimeFn"
            v-on:blur="startTimeFn"
            v-model="searchData.mark_name"
            clearable
            placeholder="标签名称"
          ></el-input>

          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="search"
            icon="el-icon-search"
            >搜索标签</el-button
          >
        </div>
      </div>

      <!-- 主体内容区 -->
      <div class="main-section">
        <!-- 操作按钮 -->
        <div class="list-operate">
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="() => showAddMarkingDialog({ type: 'add', mark_type: '1' })"
            icon="el-icon-circle-plus-outline"
            >创建标签</el-button
          >
        </div>

        <!-- 列表数据 -->
        <div class="list-table">
          <el-table :data="tableData" style="width: 100%" border fit ref="table">
            <el-table-column type="index" align="center" label="序号" width="50">
            </el-table-column>

            <el-table-column prop="mark_name" label="标签名称">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  :type="scope.row.status == 4 ? 'primary' : 'info'"
                  v-on:click="JumpMemberPoll(scope.row)"
                  >{{ scope.row.mark_name }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="orderTime" label="关联条件" width="250">
              <template slot-scope="scope">
                <div
                  class="mark-tag"
                  v-for="item in scope.row.condition_info"
                  :key="item.condition_name"
                >
                  {{ item.condition_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addtime" label="创建日期"> </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.status === 1 || scope.row.status === 3
                    ? "执行中"
                    : scope.row.status === 2
                    ? "停用"
                    : "启用"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="take_effect_number" label="生效人数">
            </el-table-column>

            <el-table-column label="执行" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="primary"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '1'
                      });
                    }
                  "
                  >运行</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="danger"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '2'
                      });
                    }
                  "
                  >停用</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '3' || scope.row.status == '1'"
                  :loading="true"
                  type="info"
                  >等待</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="控制" align="center" width="200">
              <!--  -->
              <template slot-scope="scope">
                <el-button
                  v-on:click="
                    () => {
                      showAddMarkingDialog({
                        type: 'updata',
                        ...scope.row,
                        mark_type: '1'
                      });
                    }
                  "
                  size="mini"
                  type="primary"
                  style="width: 60px"
                  >编辑</el-button
                >
                <el-button
                  v-on:click="
                    () => {
                      markDelBtn({
                        mark_id: scope.row.mark_id
                      });
                    }
                  "
                  size="mini"
                  type="danger"
                  style="width: 60px"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 会员名单 -->
    <div v-show="tabValue === 'memberPeople'">
      <!-- 筛选表单 -->
      <div class="main-section">
        <div class="filter-container">
          <el-input
            style="width: 200px"
            class="filter-item"
            v-model="searchData2.mark_name"
            v-on:focus="stopTimeFn"
            v-on:blur="startTimeFn"
            placeholder="名单名称"
            clearable
          ></el-input>

          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="search"
            icon="el-icon-search"
            >搜索名单</el-button
          >
        </div>
      </div>

      <!-- 主体内容区 -->
      <div class="main-section">
        <!-- 操作按钮 -->
        <div class="list-operate">
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="() => showAddMarkingDialog({ type: 'add', mark_type: '2' })"
            icon="el-icon-circle-plus-outline"
            >创建名单</el-button
          >
        </div>

        <!-- 列表数据 -->
        <div class="list-table">
          <el-table :data="tableData2" style="width: 100%" border fit ref="table">
            <el-table-column type="index" align="center" label="序号" width="50">
            </el-table-column>

            <el-table-column prop="mark_name" label="名单名称">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  v-on:click="JumpMemberPoll(scope.row)"
                  >{{ scope.row.mark_name }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="orderTime" label="关联条件" width="250">
              <template slot-scope="scope">
                <div
                  class="mark-tag"
                  v-for="item in scope.row.condition_info"
                  :key="item.condition_name"
                >
                  {{ item.condition_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addtime" label="创建日期"> </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.status === 1 || scope.row.status === 3
                    ? "执行中"
                    : scope.row.status === 2
                    ? "停用"
                    : "启用"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="take_effect_number" label="生效人数">
            </el-table-column>

            <el-table-column label="执行" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="primary"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '1'
                      });
                    }
                  "
                  >运行</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="danger"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '2'
                      });
                    }
                  "
                  >停用</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '3' || scope.row.status == '1'"
                  :loading="true"
                  type="info"
                  >等待</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="控制" align="center" width="200">
              <!--  -->
              <template slot-scope="scope">
                <el-button
                  v-on:click="
                    () => {
                      showAddMarkingDialog({
                        type: 'updata',
                        ...scope.row,
                        mark_type: '2'
                      });
                    }
                  "
                  size="mini"
                  type="primary"
                  style="width: 60px"
                  >编辑</el-button
                >
                <el-button
                  v-on:click="
                    () => {
                      markDelBtn({
                        mark_id: scope.row.mark_id
                      });
                    }
                  "
                  size="mini"
                  type="danger"
                  style="width: 60px"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 会员等级 -->
    <div v-show="tabValue === 'memberGrade'">
      <!-- 筛选表单 -->
      <div class="main-section">
        <div class="filter-container">
          <el-input
            style="width: 200px"
            class="filter-item"
            v-model="searchData2.mark_name"
            v-on:focus="stopTimeFn"
            v-on:blur="startTimeFn"
            placeholder="卡级名称"
            clearable
          ></el-input>

          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="search"
            icon="el-icon-search"
            >搜索卡级</el-button
          >
        </div>
      </div>

      <!-- 主体内容区 -->
      <div class="main-section">
        <!-- 操作按钮 -->
        <div class="list-operate">
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            v-on:click="() => showAddMarkingDialog({ type: 'add', mark_type: '2' })"
            icon="el-icon-circle-plus-outline"
            >创建卡级</el-button
          >
        </div>

        <!-- 列表数据 -->
        <div class="list-table">
          <el-table :data="tableData2" style="width: 100%" border fit ref="table">
            <el-table-column type="index" align="center" label="序号" width="50">
            </el-table-column>

            <el-table-column prop="mark_name" label="卡级名称">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  v-on:click="JumpMemberPoll(scope.row)"
                  >{{ scope.row.mark_name }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="orderTime" label="关联条件" width="250">
              <template slot-scope="scope">
                <div
                  class="mark-tag"
                  v-for="item in scope.row.condition_info"
                  :key="item.condition_name"
                >
                  {{ item.condition_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addtime" label="创建日期"> </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.status === 1 || scope.row.status === 3
                    ? "执行中"
                    : scope.row.status === 2
                    ? "停用"
                    : "启用"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="take_effect_number" label="生效人数">
            </el-table-column>

            <el-table-column label="执行" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="primary"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '1'
                      });
                    }
                  "
                  >运行</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '2' || scope.row.status == '4'"
                  type="danger"
                  style="width: 60px"
                  v-on:click="
                    () => {
                      setMarkStatusbtn({
                        marking_id: scope.row.mark_id,
                        status: '2'
                      });
                    }
                  "
                  >停用</el-button
                >
                <el-button
                  size="mini"
                  v-if="scope.row.status == '3' || scope.row.status == '1'"
                  :loading="true"
                  type="info"
                  >等待</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="控制" align="center" width="200">
              <!--  -->
              <template slot-scope="scope">
                <el-button
                  v-on:click="
                    () => {
                      showAddMarkingDialog({
                        type: 'updata',
                        ...scope.row,
                        mark_type: '2'
                      });
                    }
                  "
                  size="mini"
                  type="primary"
                  style="width: 60px"
                  >编辑</el-button
                >
                <el-button
                  v-on:click="
                    () => {
                      markDelBtn({
                        mark_id: scope.row.mark_id
                      });
                    }
                  "
                  size="mini"
                  type="danger"
                  style="width: 60px"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog
      :title="MarkingDialogType === 'add' ? '创建标签' : '修改标签'"
      :visible.sync="MarkingDialog"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">标签名称：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px;"
            placeholder=""
            v-model="formData.mark_name"
          ></el-input>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">动态条件：</div>
        </div>
        <div class="dialog-right">
          <el-checkbox-group v-model="formData.condition_id">
            <template v-for="item in behavioralConditionsList">
              <el-checkbox
                :label="item.id"
                :key="item.id"
                v-if="item.condition_type == '1'"
                border
                >{{ item.condition_name }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">静态条件：</div>
        </div>
        <div class="dialog-right">
          <el-checkbox-group v-model="formData.condition_id">
            <template v-for="item in behavioralConditionsList">
              <el-checkbox
                :label="item.id"
                :key="item.id"
                v-if="item.condition_type == '2'"
                border
                >{{ item.condition_name }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="MarkingDialog = false">取 消</el-button>
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  behavioralConditionsList,
  addMarking,
  markingList,
  setMarkStatus,
  updateMarking,
  markDel
} from "@/api/memberGroup";

let timeFn;
export default {
  name: "memberPool",
  data() {
    return {
      // tab列表
      tabList: [
        { label: '会员标签', value: 'memberTag' },
        { label: '会员名单', value: 'memberPeople' },
        { label: '会员卡级', value: 'memberGrade' },
      ],
      // 当前tab
      tabValue: 'memberTag',
      tableData: [],
      tableData2: [],
      behavioralConditionsList: [],

      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",

      options: [
        {
          value: "1",
          label: "全部"
        }
      ],
      formData: {
        mark_name: "",
        condition_id: [],
        mark_id: "",
        mark_type: ""
      },
      searchData: {
        mark_name: "",
        mark_type: "1"
      },
      searchData2: {
        mark_name: "",
        mark_type: "2"
      }
    };
  },
  created() {},
  mounted() {
    // this.getMarkingList();
    // this.getBehavioralConditionsList();
    // timeFn = setInterval(() => {
    //   this.getMarkingList();
    // }, 15000);
  },
  destroyed() {
    clearInterval(timeFn);
  },
  methods: {
    stopTimeFn() {
      clearInterval(timeFn);
    },
    startTimeFn() {
      timeFn = setInterval(() => {
        this.getMarkingList();
      }, 15000);
    },
    async getMarkingList() {
      const tableData = await markingList(this.searchData);
      this.tableData = tableData.data.list;

      const tableData2 = await markingList(this.searchData2);
      this.tableData2 = tableData2.data.list;

      console.log(tableData);
    },
    search() {
      this.getMarkingList();
    },
    async getBehavioralConditionsList() {
      const listdata = await behavioralConditionsList();
      this.behavioralConditionsList = listdata.data.list;
    },
    async showAddMarkingDialog(res) {
      const { type } = res;
      console.log(res);
      this.MarkingDialogType = type;
      let formData;
      if (type === "add") {
        formData = {
          mark_name: "",
          condition_id: [],
          mark_id: "",
          mark_type: res.mark_type
        };
      } else {
        const condition_id = res.condition_id.split(",").map(i => Number(i));
        formData = {
          mark_name: res.mark_name,
          condition_id,
          mark_id: res.mark_id,
          mark_type: res.mark_type
        };
      }
      this.formData = formData;
      this.MarkingDialog = true;
    },
    async addMarkingEvent() {
      const data = {
        mark_name: this.formData.mark_name,
        condition_id: this.formData.condition_id.toString(),
        mark_id: this.formData.mark_id,
        mark_type: this.formData.mark_type
      };
      if (data.mark_id) {
        await updateMarking(data);
      } else {
        await addMarking(data);
      }

      this.getMarkingList();

      this.MarkingDialog = false;
      this.$message({
        message: "保存成功",
        type: "success"
      });
    },
    async setMarkStatusbtn(res) {
      console.log(res);
      await setMarkStatus(res);
      this.getMarkingList();
      if (res.status === "1") {
        this.$message({
          message: "已开始执行，请耐心等待！",
          type: "success"
        });
      } else {
        this.$message({
          message: "标签已停用！",
          type: "warning"
        });
      }
    },
    async markDelBtn(res) {
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          await markDel(res);
          this.getMarkingList();
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });

      console.log(res);
    },
    JumpMemberPoll(res) {
      const { mark_id, status } = res;

      if (status !== 4) return;

      const routeData = this.$router.resolve({
        name: "memberPool",
        query: { mark_id }
      });
      window.open(routeData.href, "_blank");
      console.log(mark_id);
    }
  }
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
