import BasicLayout from '@/layouts/BasicLayout.vue'

// 异常页
export default {
  path: '/exception',
  name: 'Exception',
  redirect: '/exception/404',
  component: BasicLayout,
  meta: {
    title: '异常页',
    icon: 'el-icon-warning-outline',
  },
  children: [
    // 403 无权限访问
    {
      path: '403',
      name: 'Exception403',
      component: () => import('@/views/exception/403.vue'),
      meta: {
        title: '403',
      },
    },
    // 404 页面未找到
    {
      path: '404',
      name: 'Exception404',
      component: () => import('@/views/exception/404.vue'),
      meta: {
        title: '404',
      },
    },
    // 500 服务器错误
    {
      path: '500',
      name: 'Exception500',
      component: () => import('@/views/exception/500.vue'),
      meta: {
        title: '500',
      },
    },
  ],
}
