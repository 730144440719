var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'comp-goods',
      'bg-' + _vm.config.bgRadiusType,
      'cont-' + _vm.config.contRadiusType
    ],style:(_vm.layoutStyle)},[_c('div',{class:'goods-' + _vm.config.layoutType},_vm._l((_vm.goodsList),function(item,index){return _c('div',{key:item.id,class:[
          'goods-item',
          (_vm.config.layoutType === 'double' && index >= 2) || (_vm.config.layoutType === 'triple' && index >= 3) || (_vm.config.layoutType === 'banner' && index >= 1) ? 'is-mgt' : '',
          (_vm.config.layoutType === 'double' && index % 2 !== 0) || (_vm.config.layoutType === 'triple' && index % 3 !== 0) ? 'is-mgl' : '',
        ]},[_c('div',{staticClass:"goods-pic",style:(_vm.goodsImgStyle)},[(item.image)?_c('img',{staticClass:"goods-pic-img",attrs:{"src":_vm.imageBaseUrl + item.image,"alt":""}}):_c('EmptyBanner',{attrs:{"full":_vm.config.layoutType !== 'banner',"iconSize":24,"iconColor":"#aaa"}})],1),_c('div',{staticClass:"goods-cont"},[(_vm.config.showGoodsName)?_c('div',{staticClass:"goods-title",class:['is-rows-' + _vm.config.titleRows],style:(_vm.titleStyle)},[_vm._v(_vm._s(item.store_name))]):_vm._e(),_c('div',{staticClass:"goods-price"},[(_vm.config.showPrice)?_c('div',{staticClass:"price-value",style:(_vm.priceStyle)},[_vm._v("¥"+_vm._s(item.price))]):_vm._e(),(item.oldPrice && _vm.config.showOldPrice)?_c('div',{staticClass:"price-old",style:(_vm.oldPriceStyle)},[_vm._v("¥"+_vm._s(item.oldPrice))]):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }