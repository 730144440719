<template>
  <el-submenu v-if="info.children && info.children.length > 0" :index="info.name">
    <template #title>
      <i v-if="!isSubNav" :class="info.icon || 'el-icon-menu'"></i>
      <span>{{info.title}}</span>
    </template>
    <SideNavItem
      v-for="(navItem) in info.children"
      :key="navItem.name"
      :info="navItem"
      :isSubNav="true"
    />
  </el-submenu>
  <router-link v-else :class="{'page-side-menu-link': true, 'hide-sub-menu': info.hideSubMenu}" :to="{name: info.name}">
    <el-menu-item :index="info.name">
      <i v-if="!isSubNav" :class="info.icon || 'el-icon-menu'"></i>
      <template #title>
        {{info.title}}
      </template>
    </el-menu-item>
  </router-link>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import SideNavItem from './SideNavItem.vue'

export default {
  name: 'SideNavItem',
  props: {
    // 菜单信息
    info: { type: Object, default: () => {} },
    // 是否为二级菜单
    isSubNav: { type: Boolean, default: false },
  },
  components: {
    SideNavItem,
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
