<template>
  <el-dialog
    :visible.sync="isVisible"
    title="订单记录"
    width="700px"
    destroy-on-close
    @close="handleClose"
  >
    <el-table
      v-loading="isLoading"
      element-loading-background="rgba(255,255,255,0.6)"
      border
      :data="recordList"
    >
      <el-table-column prop="oid" label="订单ID" align="center" min-width="60"></el-table-column>
      <el-table-column prop="change_message" label="操作记录" align="center" min-width="200"></el-table-column>
      <el-table-column prop="change_time" label="操作时间" align="center" min-width="100"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import { fetchOrderRecord } from '@/api/order'

  export default {
    name: "OrderRecord",
    data() {
      return {
        // 是否显示弹框
        isVisible: false,
        // 是否在加载数据
        isLoading: false,
        // 订单记录列表
        recordList: [],
      };
    },
    methods: {
      // 由外部调用显示弹框
      show({orderId} = {}) {
        if (orderId) {
          this.recordList = []
          this.isLoading = true
          this.isVisible = true
          this.getOrderRecord(orderId)
        }
      },

      // 关闭弹框
      handleClose() {
        this.isVisible = false
      },

      // 获取订单记录数据
      async getOrderRecord(record) {
        const orderRecord = await fetchOrderRecord({id: record.id});
        setTimeout(() => {
          const mockData = ({
            "status":200,
            "msg":"ok",
            "data":[
              {"oid":4334,"change_type":"cache_key_create_order","change_message":"订单生成","change_time":"2021-11-28 11:56:22"},
              {"oid":4334,"change_type":"pay_success","change_message":"用户付款成功","change_time":"2021-11-28 11:56:22"}
            ]
          })
          this.recordList = mockData.data
          this.isLoading = false
        }, 600)
      },
    },
  };
</script>

<style lang="scss" scope>

</style>
