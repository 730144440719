import request from "@/utils/request";

export function bannerSave(data) {
  return request({
    url: "/adminapi/banner/banner/save",
    method: "post",
    data
  });
}

//

export function bannerList(data) {
  return request({
    url: "/adminapi/banner/banner/list",
    method: "get",
    params: data
  });
}

export function bannerDel(data) {
  return request({
    url: "/adminapi/banner/banner/del",
    method: "get",
    params: data
  });
}



// 之后按照目录合并咯

export function fetchGetProductItemList(data) {
  return request({
    url: "/adminapi/product/product/item_list",
    method: "get",
    params: data
  });
}

export function addProductItemList(data) {
  return request({
    url: "/adminapi/product/product/item_save",
    method: "post",
    data
  });
}

