import request from "@/utils/request";
// /memberPool/addBehavioralConditions
export function addBehavioralConditions(data) {
  return request({
    url: "/memberPool/addBehavioralConditions",
    method: "post",
    data
  });
}

//

export function behavioralConditionsList(data) {
  return request({
    url: "/memberPool/behavioralConditionsList",
    method: "get",
    params: data
  });
}

// /memberPool/conditions_del

export function conditionsDel(data) {
  return request({
    url: "/memberPool/conditions_del",
    method: "get",
    params: data
  });
}
export function updateBehavioralConditions(data) {
  return request({
    url: "/memberPool/updateBehavioralConditions",
    method: "post",
    data
  });
}

// /memberPool/conditions_notice

export function conditions_notice(data) {
  return request({
    url: "/memberPool/conditions_notice",
    method: "get",
    params: data
  });
}
