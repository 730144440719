<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      class="login-form"
      autocomplete="on"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
    >
      <div class="login-form-logo">
        <img src="../assets/login-logo-title.png" />
      </div>

      <el-form-item prop="username">
        <span class="login-form-icon">
          <i class="iconfont icon_mine"></i>
        </span>
        <el-input
          name="username"
          type="text"
          v-model="loginForm.username"
          autocomplete="on"
          placeholder="username"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="login-form-icon">
          <i class="iconfont icon_password"></i>
        </span>
        <el-input
          name="password"
          :type="passwordType"
          @keyup.enter.native="handleLogin"
          v-model="loginForm.password"
          autocomplete="on"
          placeholder="password"
        />
        <span class="login-form-icon is-right is-cursor" @click="handleChangePwdVisible">
          <i v-show="passwordType === 'password'" class="iconfont icon_eye_show"></i>
          <i v-show="passwordType !== 'password'" class="iconfont icon_eye_hide"></i>
        </span>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="captch">
            <span class="login-form-icon">
              <i class="iconfont icon_yanzhengma"></i>
            </span>
            <el-input
              name="captch"
              type="text"
              v-model="loginForm.captch"
              autocomplete="on"
              placeholder="验证码"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="captcha-dpread-box" v-on:click="GetCaptchaDpread">
            <img :src="captchaDpreadUrl" />
          </div>
        </el-col>
      </el-row>

      <el-button
        class="login-form-confirm"
        type="primary"
        :loading="loading"
        @click.native.prevent="handleLogin"
      >
        登录
      </el-button>
    </el-form>
  </div>
</template>

<script type="es6">
import { loginByUsername ,CaptchaDpread} from "../api/login";

export default {
  name: "login",
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
     const validateCaptcha= (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        username: "",
        password: "",
        captch:"",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername }
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ],
        captch: [
          { required: true, trigger: "blur", validator: validateCaptcha }
        ],
      },
      captchaDpreadUrl: '',
      passwordType: "password",
      loading: false,
      showDialog: false
    };
  },
  mounted() {
    this.GetCaptchaDpread()
  },
  methods: {
    // 获取验证码
    async GetCaptchaDpread(){
      const data =  await CaptchaDpread()
      console.log('data', data)
      const imgUrl = `data:image/png;base64,${  btoa(
        new Uint8Array(data).reduce((i, byte) => i + String.fromCharCode(byte), '')
      )}`;
      this.captchaDpreadUrl=imgUrl
    },

    // 切换密码的可视化
    handleChangePwdVisible() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },

    // 点击登录
    handleLogin()  {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.fetchLogin()
        }
      });
    },

    // 提交登录
    async fetchLogin() {
      const {username,password,captch} = this.loginForm;
      const {data:{token,MallCode}} =  await loginByUsername({account:username,pwd:password,imgcode:captch})
      console.log(token)
      localStorage.setItem('MallCode', MallCode)
      localStorage.setItem('token', token);
      this.$router.push({ path: '/' })
    }
  },
};
</script>

<style type="text/scss" lang="scss">
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background: url(https://hj-clienteling-mp-assets.idigitalforce.com/admin/login_page_bg.png) no-repeat 0 0;
  background-size: cover;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    position: absolute;
    top: 52%;
    right: 13.5%;
    width: 550px;
    padding: 76px 50px;
    background: rgba(0,0,0,0.7);
    transform: translateY(-50%);
  }

  .login-form-logo {
    width: 320px;
    margin: 0 auto 46px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    margin-bottom: 24px;
    color: #FFFFFF;
    background: #041034;
  }

  .el-form-item__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .el-input__inner {
    height: 52px;
    padding: 12px 5px 12px 15px;
    border: 0px;
    border-radius: 0px;
    color: rgba(255,255,255,0.9);
    background: transparent;
    -webkit-appearance: none;

    // 自动填充
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #041034 inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }

  .login-form-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    width: 40px;
    font-size: 20px;
    color: #CBCDCE;

    &.is-right {
      width: 35px;
      justify-content: flex-start;
    }

    &.is-cursor {
      cursor: pointer;
    }

    i {
      font-size: inherit;
    }
  }

  .captcha-dpread-box {
    height: 52px;
    text-align: right;

    img {
      height: 100%;
      border-radius: 5px;
    }
  }

  .login-form-confirm {
    margin-top: 6px;
    width: 100%;
    height: 40px;
  }
}
</style>
