<template>
  <div class="main-content">
    <!-- 内容区-tab切换 -->
    <div class="main-tab">
      <el-tabs v-model="searchData.type" @tab-click="handleTabChange">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.type"
          :name="item.type"
          :label="item.name"
        />
      </el-tabs>
    </div>

    <!-- 列表筛选表单 -->
    <div class="main-section list-filter">
      <el-form :model="searchData" ref="mainFilterForm" size="small">
        <el-row :gutter="24">
          <el-col :span="6" :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
            <el-form-item label="店铺选择:" class="main-form-row">
              <el-select
                class="filter-item"
                placeholder="店铺选择"
                v-model="searchData.MallCode"
                @change="MallChange"
              >
                <el-option
                  v-for="item in shopList"
                  :key="item.id"
                  :label="item.MallName"
                  :value="item.MallCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
            <el-form-item label="商品类别:" class="main-form-row">
              <el-select placeholder="商品类别" v-model="searchData.is_virtual" @change="handleSearch">
                <el-option
                  v-for="item in productTypeList"
                  :key="item.val"
                  :value="item.val"
                  :label="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="6" :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
            <el-form-item label="商品分类:" class="main-form-row">
              <el-select
                placeholder="商品分类"
                clearable
                v-model="searchData.cate_id"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.html + item.cate_name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
            <el-form-item label="商品搜索:" class="main-form-row">
              <el-input
                v-model="searchData.keywords"
                placeholder="请输入商品名称,关键字,ID"
                class="input-append-button"
                clearable
                @keyup.enter.native="handleSearch"
              >
                <div slot="append" @click="handleSearch">
                  <i class="el-icon-search" />
                </div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 列表内容展示区 -->
    <div class="main-section">
      <!-- 列表操作按钮 -->
      <div class="list-operate">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="handleAdd"
          >添加商品</el-button
        >
        <!-- <el-button icon="el-icon-download" size="small" @click="handleExport">导出报表</el-button> -->
      </div>

      <!-- 列表数据展示 -->
      <div class="list-table">
        <el-table
          v-loading="isLoading"
          element-loading-background="rgba(255,255,255,0.6)"
          :data="listData"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-row :gutter="20">
                <el-col :span="8">商品分类：{{ props.row.cate_name }}</el-col>
                <el-col :span="8"
                  >商品市场价格：{{ props.row.ot_price }}</el-col
                >
                <el-col :span="8">成本价：{{ props.row.cost }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">收藏：{{ props.row.collect }}</el-col>
                <el-col :span="8">虚拟销量：{{ props.row.ficti }}</el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            type="selection"
            align="center"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="商品id"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="image"
            label="商品图"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <div class="goods-img" v-viewer>
                <img :src="imageBaseUrl + scope.row.image" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="store_name"
            label="商品名称"
            align="left"
            min-width="250"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="商品售价"
            align="center"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="sales"
            label="销量"
            align="center"
            min-width="80"
            sortable
          ></el-table-column>
          <el-table-column
            prop="stock"
            label="库存"
            align="center"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            align="center"
            min-width="100"
            v-if="searchData.type != 6"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                active-text="上架"
                inactive-text="下架"
                :active-value="'1'"
                :inactive-value="'0'"
                :width="60"
                @change="handleStateChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="250"
            fixed="right"
            v-if="searchData.type != 6"
          >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <!-- <el-divider direction="vertical"></el-divider> -->
              <!-- <el-button
                type="text"
                size="mini"
                @click="handleViewComment(scope.row)"
                >查看评论</el-button
              > -->
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                size="mini"
                @click="handleDrecycleBin(scope.row)"
                >移到回收站</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 列表分页处理 -->
      <div class="list-page">
        <el-pagination
          v-if="isDone && listData.length > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="totalElements"
          :current-page="currentPage"
          :page-sizes="paginationSizes"
          :page-size="pageSize"
          :layout="paginationLayout"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchProdctList,
  getFisrtLevelClassifyApi,
  fetchGetTypeHeader,
  setProductShow,
  deleteProduct,
} from "@/api/product";
import { getCounterList } from "@/api/storeList";
import { imageBaseUrl } from "@/utils/config";
import mixinTable from "@/utils/mixinsTable";

export default {
  name: "ProductList",
  mixins: [mixinTable],
  data() {
    return {
      imageBaseUrl,
      shopList: [],
      isAutoload: false,
      fetchAction: fetchProdctList,
      listData: [],
      // 搜索条件
      searchData: {
        cate_id: "", // 商品分类
        keywords: "", // 商品搜索
        page: 1,
        limit: 15,
        MallCode: "",
        type: 1,
        is_virtual: "2",
      },
      productTypeList: [
        {
          val: "2",
          name: "积分商品",
        },
        {
          val: "0",
          name: "普通商品",
        },
        {
          val: "1",
          name: "虚拟商品",
        },
      ],

      // 选择的列表数据项
      selectedList: [],
      // tab列表
      tabList: [],
      // 当前tab
      tabValue: "1",
      // 商品分类列表
      categoryList: [],
    };
  },
  async created() {
    await this.getCategoryList();
    await this.getSelectData();
    await this.GetTypeHeader();
    await this.reloadListData();
  },
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");

      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.MallCode = tableData.data.list[0].MallCode;
    },
    // 获取商品分类列表
    async getCategoryList() {
      const { data } = await getFisrtLevelClassifyApi();
      console.log(data);
      const categoryList = data;
      this.categoryList = categoryList;
    },
    async GetTypeHeader() {
      const {
        data: { list },
      } = await fetchGetTypeHeader();
      console.log("tabList", list);
      this.tabList = list;
    },

    // 头部tab变更
    handleTabChange() {
      this.reloadListData();
    },
    async MallChange() {
      // const tableData = await bannerList(this.searchData);
      // this.BannerList = tableData.data.list;
      this.reloadListData();
    },

    // 添加商品
    handleAdd() {
      this.$router.push({
        name: "productAdd",
        params: { id: 0, MallCode: this.searchData.MallCode },
      });
    },

    // 导出报表
    handleExport() {
      console.log("handleExport");
    },

    // 排序变更
    handleSortChange({ column, prop, order }) {
      console.log("handleSortChange", { column, prop, order });
    },

    // 多选变更
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList;
    },

    // 状态变更
    async handleStateChange(record) {
      const params = { id: record.id, is_show: record.is_show };
      const res = await setProductShow(params);
      this.updateListData();

      if (res.status !== 200) {
        this.$message.error(res.msg);
        setTimeout(() => {
          record.is_show = record.is_show === 0 ? 1 : 0;
        }, 600);
      }
      console.log("handleStateChange", record.is_show);
    },

    // 编辑商品
    handleEdit(record) {
      this.$router.push({
        name: "productAdd",
        params: { id: record.id, MallCode: this.searchData.MallCode },
      });
    },

    // 查看评论
    handleViewComment(record) {
      console.log("handleViewComment", record);
    },

    // 移至回收站
    async handleDrecycleBin(record) {
      const self = this;
      this.$confirm(
        "确定要移入回收站吗？移入回收站后将无法恢复，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await deleteProduct(record.id);
          self.updateListData();
          if (res.status !== 200) {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });

      console.log("handleDrecycleBin", record);
    },
  },
};
</script>

<style lang="scss" scope>
// 商品图
.goods-img {
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
