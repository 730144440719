import request from "@/utils/request";
// /memberPool/addBehavioralConditions

// getlist
export function behavioralConditionsList(data) {
  return request({
    url: "/memberPool/behavioralConditionsList",
    method: "get",
    params: data
  });
}

// /memberPool/addMarking
export function addMarking(data) {
  return request({
    url: "/memberPool/addMarking",
    method: "post",
    data
  });
}

export function markingList(data) {
  return request({
    url: "/memberPool/markingList",
    method: "get",
    params: data
  });
}

// /memberPool/mark_status

export function setMarkStatus(data) {
  return request({
    url: "/memberPool/mark_status",
    method: "get",
    params: data
  });
}
// /memberPool/updateMarking

export function updateMarking(data) {
  return request({
    url: "/memberPool/updateMarking",
    method: "post",
    data
  });
}
// /memberPool/mark_del
export function markDel(data) {
  return request({
    url: "/memberPool/mark_del",
    method: "get",
    params: data
  });
}
