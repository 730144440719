<template>
  <div class="content-page">
    <!-- 页面头部 -->
    <div class="main-head">
      <div class="rowbetmid">
        <div class="rowmid">
          <router-link :to="{ name: 'designManage' }" replace><i class="el-icon-arrow-left"></i><span class="text-sm">返回</span></router-link>
          <el-divider direction="vertical"></el-divider>
          <h1 class="text-lg text-bold">{{pageTitle}}设计</h1>
        </div>
        <div class="rowmid">
          <template v-if="isEdit">
            <el-button type="primary" plain size="small" class="w80" @click="handleSaveAnother">另存为</el-button>
            <el-divider direction="vertical" class="mgl20 mgr20"></el-divider>
          </template>
          <el-button type="primary" size="small" class="w80" @click="handleSave">保存</el-button>
          <el-button type="default" size="small" class="w80" @click="handleReset">重置</el-button>
        </div>
      </div>
    </div>

    <!-- 页面主体 -->
    <div class="design-body">
      <!-- 装修控件 -->
      <div class="design-comp">
        <div class="design-comp-title">组件库</div>
        <div class="comp-scroll">
            <vuedraggable
              class="comp-list"
              :list="widgetList"
              :group="{ name: 'people', pull: 'clone', put: false }"
              :clone="handleCompClone"
            >
              <div v-for="(item) in widgetList" :key="item.type" class="comp-item">
                <div class="comp-item-position">
                  释放鼠标将组建添加到此处
                </div>
                <div class="comp-item-info">
                  <i class="comp-item-icon" :class="item.iconClass" />
                  <div class="comp-item-text">{{item.name}}</div>
                </div>
              </div>
            </vuedraggable>
        </div>
      </div>

      <!-- 装修布局 -->
      <div class="design-layout">
        <div class="design-layout-scroll" @click="handleClickLayout">
          <div class="design-layout-body" @click.stop="noop">
            <vuedraggable
              class="layout-list"
               :style="layoutBodyStyle"
              :list="layoutList"
              group="people"
              @change="handleChangeLayout"
              :move="handleMoveLayout"
              animation="300"
            >
              <div
                v-for="(item, index) in layoutList"
                :key="item.id"
                :class="{'layout-item': true, 'active': activeIndex === index}"
                @click.stop="handleClickLayoutItem(item, index)"
              >
                <component
                  :is="'Widget' + item.type"
                  :key="item.id"
                  :config="configInfo[item.id]"
                />
                <div class="handle-box">
                  <div class="handle-list">
                    <div
                      class="handle-icon el-icon-delete"
                      @click.stop="handleDeleteItem(item, index)"
                    />
                    <div
                      class="handle-icon el-icon-document-copy"
                      @click.stop="handleCopyItem(item, index)"
                    />
                    <div
                      class="handle-icon el-icon-arrow-up"
                      :class="index === 0 ? 'disabled' : ''"
                      @click.stop="handleMoveItem(item, index, 'up')"
                    />
                    <div
                      class="handle-icon el-icon-arrow-down"
                      :class="index === layoutList.length - 1 ? 'disabled' : ''"
                      @click.stop="handleMoveItem(item, index, 'down')"
                    />
                  </div>
                </div>
              </div>
            </vuedraggable>
          </div>
        </div>
      </div>

      <!-- 装修配置 -->
      <div class="design-config">
        <template v-if="isDone">
          <div class="design-config-title">{{activeWidgetName}}</div>
          <component
            v-show="activeWidgetId === 'page'"
            key="page"
            id="page"
            :is="'ConfigPage'"
            :config="configInfo['page']"
            @change="handleConfigChange"
          />
          <template v-for="(item) in layoutList">
            <component
              v-show="activeWidgetId === item.id"
              :key="item.id"
              :id="item.id"
              :is="'Config' + item.type"
              :config="configInfo[item.id]"
              @change="handleConfigChange"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
import { getModuleList, saveModuleData } from "@/api/design";
import { imageBaseUrl } from "@/utils/config";
import designUtils from './utils/designUtils'

import WidgetSwiper from './widget/WidgetSwiper.vue'
import WidgetNavList from './widget/WidgetNavList.vue'
import WidgetGoodsList from './widget/WidgetGoodsList.vue'
import WidgetPictureCube from './widget/WidgetPictureCube.vue'
import WidgetTitle from './widget/WidgetTitle.vue'
import WidgetBlank from './widget/WidgetBlank.vue'

import ConfigPage from './config/ConfigPage.vue'
import ConfigSwiper from './config/ConfigSwiper.vue'
import ConfigNavList from './config/ConfigNavList.vue'
import ConfigGoodsList from './config/ConfigGoodsList.vue'
import ConfigPictureCube from './config/ConfigPictureCube.vue'
import ConfigTitle from './config/ConfigTitle.vue'
import ConfigBlank from './config/ConfigBlank.vue'

export default {
  name: "ProductClassify",
  components: {
    vuedraggable,
    WidgetSwiper,
    WidgetNavList,
    WidgetGoodsList,
    WidgetPictureCube,
    WidgetTitle,
    WidgetBlank,
    ConfigPage,
    ConfigSwiper,
    ConfigNavList,
    ConfigGoodsList,
    ConfigPictureCube,
    ConfigTitle,
    ConfigBlank,
  },
  data() {
    return {
      // 页面必要数据是否加载完毕
      isDone: false,
      // 是否为编辑模式
      isEdit: false,
      // 页面标题
      pageTitle: this.$route.query.name,
      // 控件列表
      widgetList: [
        { type: 'Swiper', name: '轮播图', iconClass: 'el-icon-camera-solid' },
        { type: 'NavList', name: '导航栏', iconClass: 'el-icon-menu' },
        { type: 'GoodsList', name: '商品列表', iconClass: 'el-icon-s-goods' },
        { type: 'PictureCube', name: '图片魔方', iconClass: 'el-icon-picture' },
        { type: 'Title', name: '标题', iconClass: 'el-icon-edit-outline' },
        { type: 'Blank', name: '辅助空白', iconClass: 'el-icon-crop' },
      ],
      // 布局列表
      layoutList: [],
      // 当前激活模块的索引
      activeIndex: -1,
      // 配置信息
      configInfo: {},
      // 布局body的样式
      layoutBodyStyle: {},
    };
  },
  computed: {
    // 当前布局控件的ID
    activeWidgetId() {
      if (this.activeIndex === -1) {
        return 'page'
      }
      return this.layoutList[this.activeIndex].id
    },

    // 当前布局控件的名称
    activeWidgetName() {
      if (this.activeIndex === -1) {
        return '页面设置'
      }
      return this.layoutList[this.activeIndex].name
    },
  },
  created() {
    // console.log('this.$route', this.$route)

    // 页面的模板id
    this.moduleId = this.$route.query.id || ''

    // 是否为编辑模式
    this.isEdit = this.moduleId > 0

    // 初始化模板配置
    this.initModuleSettings()
  },
  methods: {
    // 空函数
    noop() {},

    // 初始化模板配置
    async initModuleSettings() {
      if (this.isEdit) {
        // 编辑模式
        this.getModuleData()
      } else {
        // 添加模式
        this.setInitialData()
        this.isDone = true
      }
    },

    // 设置初始化数据
    setInitialData() {
      this.layoutList = []
      this.configInfo = {}
      this.configInfo.page = {
        widgetType: 'Page',
        templateName: '首页模板',
        pageTitle: '首页',
        bgColorOpen: false,
        bgColorValue: '#f5f5f5',
        bgImageOpen: false,
        bgImageType: 'default',
        bgImagePath: '',
      }
      this.activeIndex = -1
      this.layoutBodyStyle = {}
      this.cacheIsIndex = false
      this.cacheIsValidity = false
    },

    // 获取模板数据
    async getModuleData() {
      const sendData = { ModulId: this.moduleId }
      const res = await getModuleList(sendData);
      if (res.status !== 200) {
        this.$message.error(res.msg);
      } else {
        const listData = (res.data && res.data.list) || []
        const moduleData = listData[0] || {}
        let ConfigList = []
        try {
          ConfigList = moduleData.Config ? JSON.parse(listData[0].Config) : []
        } catch(err) {
          console.warn(err)
         }
        this.setInitialData()
        this.cacheIsIndex = moduleData.IsIndex === '1'
        this.cacheIsValidity = moduleData.IsValidity === '1'
        ConfigList.forEach((item) => {
          const {info} = item
          this.configInfo[info.id] = info.config
          if (item.type === 'Page') {
            this.updateBodyStyle(info.config)
          } else {
            this.layoutList.push({
              type: item.type,
              name: item.name,
              id: info.id,
            })
          }
        })
        this.isDone = true
      }
    },

    // 模块配置发生变更
    handleConfigChange(id, config) {
      // console.log("handleConfigChange", id, config)
      if (id === 'page') {
        this.$set(this.configInfo, id, config)
        this.updateBodyStyle(config)
      } else if (id) {
        this.$set(this.configInfo, id, config)
        this.$forceUpdate()
      }
    },

    // 更新布局body的样式
    updateBodyStyle(config) {
      const styleObj = {}

      if (config.bgColorOpen && config.bgColorValue) {
        styleObj.backgroundColor = config.bgColorValue
      }

      if (config.bgImageOpen && config.bgImagePath) {
        styleObj.backgroundImage = `url(${imageBaseUrl + config.bgImagePath})`
      }

      if (config.bgImageType === 'default') {
        styleObj.backgroundRepeat = 'no-repeat';
        styleObj.backgroundSize = '100% auto';
      } else if (config.bgImageType === 'repeat') {
        styleObj.backgroundRepeat = 'repeat-y';
        styleObj.backgroundSize = '100% auto';
      } else if (config.bgImageType === 'full') {
        styleObj.backgroundRepeat = 'no-repeat';
        styleObj.backgroundSize = 'auto 100%';
      }

      this.layoutBodyStyle = styleObj
    },

    // 点击重置
    handleReset() {
      this.$confirm('是否重置当前页面数据', '提示', { type: 'warning', callback: async (action) => {
        if (action === 'confirm') {
          window.location.reload()
        }
      }})
    },

    // 另存为新的模板
    handleSaveAnother() {
      this.isSaveAnother = true
      this.handleSave()
    },

    // 点击保存
    handleSave() {
      if (this.layoutList.length === 0) {
        this.$message.error('请添加布局模块')
        return
      }

      const ConfigList = []

      const sendData = {
        ModulName: this.configInfo.page.templateName || '', // 模板名称
        IsIndex: this.cacheIsIndex ? 1 : 0, // 是否是首页
        IsValidity: this.cacheIsValidity ? 1 : 0, // 是否生效
        Config: '', // 布局模块配置列表
      }

      if (this.isEdit && !this.isSaveAnother) {
        // 编辑模式下传递模板id
        sendData.ModulId = this.moduleId
      }

      ConfigList.push({
        type: 'Page',
        name: '页面',
        info: {id: 'page', config: this.configInfo.page},
      })

      this.layoutList.forEach((item) => {
        const configValue = {...this.configInfo[item.id]}
        if (item.type === 'GoodsList') {
          // 商品列表模块仅传递商品id配置
          configValue.chooseGoodsIds = configValue.chooseGoodsList.map((goods) => goods.id)
          configValue.chooseGoodsList.length = 0
        }

        let canDoSave = true
        if (item.type === 'Blank' && configValue.height === 0) {
          // 辅助空白高度为0，则不保存该模块的配置数据
          canDoSave = false
        }

        if (canDoSave) {
          ConfigList.push({
            type: item.type,
            name: item.name,
            info: {id: item.id, config: configValue},
          })
        }
      })

      sendData.Config = JSON.stringify(ConfigList)
      this.fetchSaveTemplateData(sendData)
    },

    // 保存模板数据
    async fetchSaveTemplateData(sendData) {
      console.log('sendData', sendData)
      const res = await saveModuleData(sendData);
      if (res.status !== 200) {
        this.$message.error(res.msg);
      } else {
        this.$message.success({
          message: this.isEdit ? "编辑模板成功！" : "添加模板成功！",
          onClose: () => {
            this.$router.back()
          }
        });
      }
    },

    // 克隆组件
    handleCompClone(data) {
      return {
        ...data,
      };
    },

    // 布局视图拖拽控制
    handleMoveLayout(e) {
      if (e.relatedContext.element.name == "search_box") return false;
      if (e.relatedContext.element.name == "nav_bar") return false;
      return true;
    },

    // 布局视图拖拽变更
    handleChangeLayout(evt) {
      // console.log('handleChangeLayout', evt)

      // 上下移动排序
      if (evt.moved) {
        this.activeIndex = evt.moved.newIndex
      }

      // 从左向右拖拽添加模块
      if (evt.added) {
        const tempItem = {...evt.added.element}
        tempItem.id = designUtils.createRandomId();

        this.layoutList[evt.added.newIndex] = tempItem;
        this.activeIndex = evt.added.newIndex;
        this.configInfo[tempItem.id] = {}
      }
    },

    // 点击布局外围容器
    handleClickLayout() {
      this.activeIndex = -1
    },

    // 点击布局模块
    handleClickLayoutItem(item, index) {
      this.activeIndex = index
    },

    // 删除布局模块
    handleDeleteItem(item, index) {
      delete this.configInfo[item.id]
      this.layoutList.splice(index, 1);
      if (index > 0) {
        this.activeIndex = index - 1
      } else {
        this.activeIndex = -1
      }
    },

    // 拷贝布局模块
    handleCopyItem(item, index) {
      const tempItem = {...item}
      tempItem.id = designUtils.createRandomId();

      this.layoutList.splice(index + 1, 0, tempItem);
      this.activeIndex = index + 1
      this.configInfo[tempItem.id] = {}
    },

    // 移动布局模块
    handleMoveItem(item, index, direction) {
      // console.log('handleMoveItem', item, index, direction)
      const tempItem = {...item}
      if (direction === 'up' && index !== 0) {
        // 向上移动
        this.layoutList.splice(index, 1);
        this.layoutList.splice(index - 1, 0, tempItem);
        this.activeIndex = index - 1
      } else if (direction === 'down' && index !== this.layoutList.length - 1) {
        // 向下移动
        this.layoutList.splice(index, 1);
        this.layoutList.splice(index + 1, 0, tempItem);
        this.activeIndex = index + 1
      }
    },
  },
};
</script>

<style lang="scss" scope>
// 内容页面
.content-page {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  padding: 0 18px 18px;
  background-color: #f5f7f9;
}

// 装修页面主体
.design-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 64px - 16px - 18px);
  margin-top: 10px;
  border-radius: 4px;
  background-color: #fff;
}

// 装修控件
.design-comp {
  min-width: 300px;
  max-width: 300px;
  border-radius: 4px;
  height: 100%;

  .el-tabs__content {
    overflow: visible;
  }

  .comp-scroll {
    height: 300px;
    padding: 0 20px;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #e9e9e9;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e9e9e9;
      border-radius: 20px;
    }
  }

  .comp-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .comp-item {
    margin-right: 17px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 5px 0 rgba(24,144,255,0.3);
      transform: scale(1.1);
      transition: all 0.2s;
    }
  }

  .comp-item-position {
    display: none;
  }

  .comp-item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74px;
    height: 66px;
    padding: 10px 0;
  }

  .comp-item-icon {
    font-size: 24px;
    color: #1890ff;
  }

  .comp-item-text {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
    text-align: center;
  }

  .dragClass {
    background-color: #fff;
  }
}
.design-comp-title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding-left: 24px;
  color: #000;
  border-radius: 4px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

// 装修布局
.design-layout {
  flex-grow: 1;
  height: 100%;
  background-color: #f0f2f5;
}
.design-layout-scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track-piece {
    background: #e9e9e9;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 20px;
  }
}
.design-layout-body {
  width: 375px;
  height: calc(100vh - 64px - 16px - 18px);
  margin: 0 auto;
  background-color: #fff;

  .layout-list {
    border-top: 1px solid #f0f2f5;
    min-height: 100%;
    background-color: #fff;
  }

  .layout-item {
    position: relative;
    cursor: move;

    .handle-box {
      display: none;
    }
  }

  .layout-item.active {
    .handle-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      display: block;
      border: 2px solid #1890ff;
      box-shadow: 0 0 10px 0 rgba(24,144,255,0.3);
    }

    .handle-list {
      position: absolute;
      right: -43px;
      top: 0;
      width: 36px;
      border-radius: 4px;
      background-color: #1890ff;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding: 4px 0;
    }

    .handle-icon {
      display: block;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
      color: #fff;

      &:hover {
        text-shadow: 0 0 10px rgba(255,255,255,0.5);
      }

      &.disabled {
        opacity: 0.4;
      }
    }
  }

  .comp-item-position {
    display: block !important;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px dashed #1890ff;
    color: #1890ff;
    background-color: #edf4fb;
  }

  .comp-item-info {
    display: none !important;
  }
}

// 装修配置
.design-config {
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  border-radius: 4px;
  overflow: auto;

  &::-webkit-scrollbar-track-piece {
    background: #e9e9e9;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 20px;
  }
}
.design-config-title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding-left: 24px;
  color: #000;
  border-radius: 4px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}
</style>
