<template>
  <div :class="{'page-container': true, 'is-hideSide': isCollapseMenu, 'is-mobile': deviceType === 'mobile'}">
    <aside class="page-side">
      <div class="page-side-logo">
        <img class="page-side-logo-img" src="../assets/logo-circle.png" />
        <span class="page-side-logo-text">汇嘉时代</span>
      </div>
      <side-nav />
    </aside>
    <div v-if="deviceType==='mobile' && !isCollapseMenu" class="page-side-mask" @click="handleHideSideMenu" />
    <div class="page-content">
      <div class="page-header">
        <div class="page-header-main">
          <MenuFold />
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="item.path">
              <span v-if="index === breadcrumbList.length - 1" class="breadcrumb-text">{{ item.meta.title }}</span>
              <a v-else class="breadcrumb-link" @click.prevent="handleBreadcrumb(item)">{{ item.meta.title }}</a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="page-header-side">
          <template v-if="deviceType !== 'mobile'">
            <Screenfull class="side-item hover-effect" />
          </template>
          <el-dropdown class="avatar-container side-item hover-effect"  @command="handleCommand">
            <div class="avatar-box">
              <img class="avatar-img" src="../assets/logo-main.png" />
              <!-- <span class="avatar-name">admin</span> -->
              <i class="el-icon-arrow-down" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <!-- <el-dropdown-item command="userInfo">
                  <i class="el-icon-user-solid mgr10" />个人信息
                </el-dropdown-item>
                <el-dropdown-item command="editPsw">
                  <i class="el-icon-s-tools mgr10" />修改密码
                </el-dropdown-item> -->
                <el-dropdown-item command="loginOut">
                  <svg-icon iconName="login-out" class="mgr10" />退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="page-main">
        <router-view />
      </div>
      <div class="page-footer"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ResizeHandler from '@/mixin/ResizeHandler'
import MenuFold from '@/components/MenuFold/MenuFold.vue'
import Screenfull from '@/components/Screenfull/Screenfull.vue'
import SideNav from './modules/SideNav.vue'

import '@/icons/svg/clipboard.svg'
import '@/icons/svg/dashboard.svg'

export default {
  name: 'BasicLayout',
  mixins: [ResizeHandler],
  components: {
    SideNav,
    MenuFold,
    Screenfull,
  },
  data() {
    return {
      // 面包屑导航
      breadcrumbList: [],
    }
  },
  computed: {
    ...mapState({
      isCollapseMenu: (state) => state.isCollapseMenu,
      deviceType: (state) => state.deviceType,
    }),
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  mounted() {
  },
  methods: {
    // 获取面包屑导航
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.meta && item.meta.title)
      // const first = matched[0]
      // if (first && first.path !== '/dashboard') {
      //   // 非首页层级菜单需要添加首页项
      //   matched = [{ path: '/dashboard', meta: { title: '首页' } }].concat(matched)
      // }
      this.breadcrumbList = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },

    // 点击面包屑导航
    handleBreadcrumb(item) {
      if (item.path !== this.$route.path && item.redirect !== this.$route.path) {
        this.$router.push(item.path)
      }
    },

    // 隐藏导航栏
    handleHideSideMenu() {
      this.$store.dispatch('changeMenuCollapse', true)
    },

    // 点击下拉菜单
    handleCommand(command) {
      if (command === 'loginOut') {
        // 退出登录
        localStorage.removeItem("token");
        this.$router.replace("/login");
      }
    },
  },
}
</script>

<style lang="scss">
  // APP根节点
  #app {
    width: 100%;
    height: 100%;
  }

  // 页面容器
  .page-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    // 隐藏侧栏
    &.is-hideSide{
      .page-side  {
        width: 54px !important;
      }
      .page-side-logo-text {
        display: none;
      }
      .page-content {
        margin-left: 54px;
      }
    }

    &.is-mobile {
      .page-side  {
        width: 210px !important;
      }
      .page-content {
        margin-left: 0;
      }
    }
    &.is-mobile.is-hideSide {
      .page-side {
        transform: translate(-210px);
      }
      .page-content {
        margin-left: 0;
      }
    }
  }

  // 页面左侧菜单栏
  .page-side {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 210px;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all .28s;

    .el-menu,
    .el-menu--collapse {
      width: 100% !important;
    }
  }
  .page-side-mask {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .3;
  }
  .page-side-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    border-right: solid 1px#e6e6e6;
    background-color: #000;
  }
  .page-side-logo-img {
    width: 32px;
    height: 32px;
  }
  .page-side-logo-text {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    white-space: nowrap;
    color: #fff;
  }
  .page-side-menu {
    flex: 1;
    min-height: 50px;
    overflow: hidden auto;

    /* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸 */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #304156;
    }

    /* 定义滚动条轨道 内阴影+圆角 */
    &::-webkit-scrollbar-track {
      background-color: #304156;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px rgba(37,37,37,.05);
    }

    /* 定义滑块 内阴影+圆角 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144,147,153,.3);
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px hsla(0,0%,100%,.05);
    }

    // 一级菜单不显示二级菜单
    .page-side-menu-link.hide-sub-menu.router-link-active {
      .el-menu-item {
        color: #fff !important;
      }
      .el-menu-item i {
        color: inherit !important;
      }
    }

    // 一级菜单hover效果
    .el-submenu > .el-submenu__title:hover,
    .hide-sub-menu > .el-menu-item:hover {
      background-color: rgba(255,255,255,0.15) !important;
    }

    // 二级菜单背景颜色
    .el-submenu .el-menu .el-submenu__title,
    .el-submenu .el-menu .el-menu-item {
      background-color: #04254A !important;
    }

    // 二级菜单hover效果
    .el-submenu .el-menu .el-submenu__title:hover,
    .el-submenu .el-menu .el-menu-item:hover {
      background-color: rgba(255,255,255,0.15) !important;
    }

    > .page-side-menu-link > .el-menu-item,
    > .page-side-menu-link > .el-menu-item > div,
    > .el-submenu > .el-submenu__title {
      padding-left: 14px !important;
    }

    // 菜單激活文本顔色
    .el-menu-item.is-active {
      font-weight: bold;
    }

    // 菜单图标颜色
    .el-menu-item [class^=el-icon-],
    .el-submenu__title [class^=el-icon-],
    .el-menu-item .iconfont,
    .el-submenu__title .iconfont  {
      color: #D5D7DB;
    }
    .el-submenu__icon-arrow {
      color: #D5D7DB;
    }

    // 自定义图标
    .el-menu-item .iconfont {
      display: inline-block;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
    }
  }

  // 页面右侧内容区域
  .page-content {
    flex-grow: 1;
    height: 100%;
    margin-left: 210px;
    overflow: auto;
    background: #F0F2F5;

    /* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸 */
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }

    /* 定义滚动条轨道 内阴影+圆角 */
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px rgba(37,37,37,.05);
    }

    /* 定义滑块 内阴影+圆角 */
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px hsla(0,0%,100%,.05);
    }
  }

  // 头部
  .page-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
  }

  // 头部面包屑
  .page-header-main {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .el-breadcrumb__inner a,
    .el-breadcrumb__inner.is-link {
      font-weight: normal;
    }

    .breadcrumb-text {
      color: #97a8be;
    }
  }

  // 头部侧栏
  .page-header-side {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;

    .side-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 8px;
      color: #5a5e66;
      font-size: 20px;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 20px;
    }

    .avatar-box {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }

    .avatar-img {
      width: 32px;
      height: 32px;
      // border-radius: 50%;
    }

    .avatar-name {
      margin-left: 6px;
      font-size: 14px;
    }

    .el-icon-arrow-down {
      margin-left: 4px;
    }
  }

  // 内容主体区域
  .page-main {
    padding: 24px;
  }

  // 辅助类名
  .mgr10 {
    margin-right: 10px !important;
  }
</style>
