<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="店铺选择"
          v-model="searchData.CampaignMallCode"
          @change="MallChange"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>

        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="活动选择"
          v-model="searchData.CampaignId"
          @change="ConditionSearch"
        >
          <el-option
            v-for="item in CampaignListData"
            :key="item.CampaignId"
            :label="item.CampaignTitle"
            :value="item.CampaignId"
          ></el-option>
        </el-select>
        <!-- ------------------------ -->
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="是否兑换"
          clearable
          v-model="searchData.OrderStatus"
        >
          <el-option
            v-for="item in OrderStatusoptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <!-- ------------------------ -->
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="是否中奖"
          clearable
          v-model="searchData.PrizeType"
        >
          <el-option
            v-for="item in OrderStatusPrizeType"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-input
          style="width: 200px"
          class="filter-item"
          v-model="searchData.MemberCode"
          placeholder="会员ID"
          clearable
        ></el-input>

        <el-input
          style="width: 200px"
          class="filter-item"
          v-model="searchData.MemberCardNo"
          placeholder="会员卡号"
          clearable
        ></el-input>

        <el-input
          style="width: 200px"
          class="filter-item"
          v-model="searchData.WeChatPhoneNumber"
          placeholder="会员手机号"
          clearable
        ></el-input>

        <div class="filter-title">中奖时间</div>
        <el-date-picker
          class="filter-item"
          v-model="tempTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="defaultTime"
          value-format="timestamp"
        >
        </el-date-picker>

        <el-button
          v-on:click="ConditionSearch"
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          >搜索条件</el-button
        >
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          v-on:click="btnExportExcel"
          :loading="downloadLoading"
          class="filter-item"
          size="small"
          icon="el-icon-download"
          >导出excel</el-button
        >
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          fit
          ref="table"
          v-loading="loading"
        >
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>

          <el-table-column prop="AddTime" label="中奖时间"></el-table-column>
          <el-table-column prop="CampaignTitle" label="活动名称">
          </el-table-column>
          <el-table-column prop="CampaignType" label="活动类型">
            <template slot-scope="scope">
              {{
                scope.row.CampaignType == "1"
                  ? "盲盒抽实物"
                  : scope.row.CampaignType == "2"
                  ? "绑定抽积分"
                  : scope.row.CampaignType == "3"
                  ? "分享绑定"
                  : ""
              }}</template
            >
          </el-table-column>

          <el-table-column prop="MallName" label="所在门店"></el-table-column>
          <el-table-column
            prop="CampaignMallCode"
            label="所在门店code"
          ></el-table-column>
          <el-table-column prop="MemberName" label="会员姓名">
          </el-table-column>
          <el-table-column
            prop="WeChatPhoneNumber"
            label="手机号"
          ></el-table-column>

          <el-table-column prop="MemberCode" label="会员ID"></el-table-column>

          <el-table-column
            prop="MemberCardNo"
            label="会员卡号"
          ></el-table-column>
          <el-table-column
            prop="RegistMallCode"
            label="会员注册门店"
          ></el-table-column>

          <el-table-column prop="PrizeTitle" label="奖品名称">
            <template slot-scope="scope">
              {{ scope.row.PrizeTitle
              }}{{ scope.row.CampaignType == 2 ? "积分" : "" }}</template
            >
          </el-table-column>

          <el-table-column label="状态" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                v-if="
                  scope.row.OrderStatus == 1 &&
                  scope.row.PrizeType == 1 &&
                  (scope.row.CampaignType == 1 || scope.row.CampaignType == 3)
                "
                v-on:click="
                  () => {
                    setExchangePrizeMember({
                      ...scope.row,
                    });
                  }
                "
                size="mini"
                type="primary"
                style="width: 60px"
                >核销</el-button
              >
              <el-button
                v-if="
                  (scope.row.OrderStatus == 2 && scope.row.PrizeType == 1) ||
                  scope.row.CampaignType == 2
                "
                size="mini"
                type="info"
                style="width: 80px"
                >已领取</el-button
              >
              <template v-if="scope.row.PrizeType == 2">-</template>
            </template>
          </el-table-column>

          <el-table-column prop="OrderTime" label="核销时间"></el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        >
        </el-pagination>
      </div>
    </div>

    <a id="downlink"></a>
  </div>
</template>
<script>
import XLSX from "xlsx";
import moment from "moment";

import {
  PrizeMemberList,
  ExchangePrizeMember,
  CampaignList,
} from "@/api/RaffleActivityList";
import { getCounterList } from "@/api/storeList";

export default {
  name: "RaffleActivityMemberListt",
  data() {
    return {
      defaultTime: ["00:00:00", "23:59:59"],
      tempTime: null,
      tableData: [],
      CampaignListData: [],
      shopList: [],
      loading: true,
      totalSize: 0,
      downloadLoading: false,

      OrderStatusoptions: [
        {
          value: "1",
          label: "未兑换",
        },
        {
          value: "2",
          label: "已兑换",
        },
      ],
      OrderStatusPrizeType: [
        {
          value: "2",
          label: "未中奖",
        },
        {
          value: "1",
          label: "中奖",
        },
      ],
      formData: {
        MallCode: "",
      },
      searchData: {
        limit: 25,
        page: 1,
        MemberCardNo: "",
        WeChatPhoneNumber: "",
        CampaignId: "",
        MemberCode: "",
        OrderStatus: "",
        PrizeType: "",
        CampaignMallCode: "",
        outFile: "",
      },
    };
  },
  created() {
    this.getSelectData();
  },
  mounted() {
    this.outFile = document.getElementById("downlink");
  },
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");

      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.CampaignMallCode = tableData.data.list[0].MallCode;
      await this.getCampaignList();
      await this.getTableData();
    },
    async getTableData() {
      this.loading = true;
      const { tempTime } = this;
      if (tempTime != null) {
        const startdata = moment(tempTime[0]).unix();
        const enddata = moment(tempTime[1]).unix();
        this.searchData.AddTime = JSON.stringify([startdata, enddata]);
      } else {
        this.searchData.AddTime = [];
      }
      const tableData = await PrizeMemberList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    async getCampaignList() {
      const obj = {
        limit: 9999,
        page: 1,
        CampaignMallCode: this.searchData.CampaignMallCode,
      };
      let {
        data: { list },
      } = await CampaignList({ ...obj });
      if (!list) list = [];
      const allData = {
        CampaignId: "",
        CampaignTitle: "所有活动",
      };
      list.unshift(allData);
      this.CampaignListData = list;
      // console.log(list);
    },
    async MallChange() {
      this.searchData.CampaignId = "";
      await this.getTableData();
      await this.getCampaignList();
    },
    ConditionSearch() {
      this.searchData.page = 1;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
    async setExchangePrizeMember(res) {
      await ExchangePrizeMember({ PrizeMemberId: res.PrizeMemberId });
      this.getTableData();
    },
    async btnExportExcel() {
      this.downloadLoading = true;
      let exData;
      try {
        const { tempTime } = this;
        if (tempTime != null) {
          const startdata = moment(tempTime[0]).unix();
          const enddata = moment(tempTime[1]).unix();
          this.searchData.AddTime = JSON.stringify([startdata, enddata]);
        } else {
          this.searchData.AddTime = [];
        }
        const req = await PrizeMemberList({
          ...this.searchData,
          ...{ limit: 9999, page: 1 },
        });
        exData = req.data.list;
      } catch (e) {
        this.downloadLoading = false;
      }

      console.log(exData);
      const obj1 = {
        AddTime: "中奖时间",
        CampaignTitle: "活动名称",
        CampaignType: "活动类型",
        MallName: "所在门店",
        CampaignMallCode: "所在门店code",
        MemberName: "会员姓名",
        WeChatPhoneNumber: "手机号",
        MemberCode: "会员ID",
        MemberCardNo: "会员卡号",
        RegistMallCode: "会员注册门店",
        PrizeTitle: "奖品名称",
        OrderStatus: "状态",
        OrderTime: "核销时间",
      };

      const lastData = [obj1];
      exData.forEach((i) => {
        const CampaignType =
          i.CampaignType === "2" ? "绑定抽积分" : "盲盒抽实物";
        const OrderStatus =
          i.CampaignType === "2"
            ? "已领取"
            : i.OrderStatus === "2"
            ? "已领取"
            : i.PrizeType === "1"
            ? "未领取"
            : "-";
        const PrizeTitle =
          i.CampaignType === "2" ? `${i.PrizeTitle}积分` : i.PrizeTitle;
        const OrderTime = i.OrderTime ? i.OrderTime : "-";
        const obj = {
          AddTime: i.AddTime,
          CampaignTitle: i.CampaignTitle,
          CampaignType,
          MallName: i.MallName,
          CampaignMallCode: i.CampaignMallCode,
          MemberName: i.MemberName,
          WeChatPhoneNumber: i.WeChatPhoneNumber,
          MemberCode: i.MemberCode,
          MemberCardNo: i.MemberCardNo,
          RegistMallCode: i.RegistMallCode,
          PrizeTitle,
          OrderStatus,
          OrderTime,
        };
        lastData.push(obj);
      });
      const now = moment().format("YYYY-MM-DD");
      this.exportExcel(lastData, lastData[1].MallName + now);
      this.downloadLoading = false;
      this.$message({ message: "中奖名单导出成功！", type: "success" });
    },
    s2ab(s) {
      // 字符串转字符流
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; i += 1) {
        view[i] = s.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    getCharCol(n) {
      // 将指定的自然数转换为26进制表示。映射关系：[0-25] -> [A-Z]。

      let s = "";

      let m = 0;

      while (n > 0) {
        m = (n % 26) + 1;

        s = String.fromCharCode(m + 64) + s;

        n = (n - m) / 26;
      }

      return s;
    },

    exportExcel(json, downName, type) {
      const keyMap = []; // 获取键

      Object.keys(json[0]).forEach((k) => {
        keyMap.push(k);
      });

      console.info("keyMap", keyMap, json);

      const tmpdata = []; // 用来保存转换好的json

      json
        .map((v, i) =>
          keyMap.map((k, j) => ({
            v: v[k],

            position:
              (j > 25 ? this.getCharCol(j) : String.fromCharCode(65 + j)) +
              (i + 1),
          }))
        )
        .reduce((prev, next) => prev.concat(next))
        .forEach(function (v) {
          tmpdata[v.position] = {
            v: v.v,
          };
        });
      console.log("tmpdata", tmpdata);
      const outputPos = Object.keys(tmpdata); // 设置区域,比如表格从A1到D10
      const tmpWB = {
        SheetNames: ["mySheet"], // 保存的表标题

        Sheets: {
          mySheet: {
            ...tmpdata, // 内容

            "!ref": `${outputPos[0]}:${outputPos[outputPos.length - 1]}`, // 设置填充区域
          },
        },
      };

      const tmpDown = new Blob(
        [
          this.s2ab(
            XLSX.write(
              tmpWB,

              {
                bookType: type === undefined ? "xlsx" : type,
                bookSST: false,
                type: "binary",
              } // 这里的数据是用来定义导出的格式类型
            )
          ),
        ],
        {
          type: "",
        }
      ); // 创建二进制对象写入转换好的字节流

      const href = URL.createObjectURL(tmpDown); // 创建对象超链接

      console.log(href);
      this.outFile.download = `${downName}.xlsx`; // 下载名称
      this.outFile.href = href; // 绑定a标签
      this.outFile.click(); // 模拟点击实现下载
      // setTimeout(function() {
      //   // 延时释放

      //   URL.revokeObjectURL(tmpDown); // 用URL.revokeObjectURL()来释放这个object URL
      // }, 100);
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
</style>
