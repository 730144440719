import request from "@/utils/request";

/**
 * 获取订单列表数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchOrderList(data) {
  return request({
    url: "/adminapi/order/list",
    method: "get",
    params: data
  });
}

/**
 * 获取订单详情数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchOrderDetail(orderId) {
  return request({
    url: `/adminapi/order/info/${orderId}`,
    method: "get"
  });
}

/**
 * 获取订单记录数据
 * @param {Object}} data 参数传递
 * @returns
 */
export function fetchOrderRecord(data) {
  return request({
    url: "/adminapi/counter/counter/list",
    method: "get",
    params: data
  });
}

/**
 * 提交订单备注
 * @param {Object}} data 参数传递
 * @returns
 */
export function postOrderRemark(data) {
  return request({
    url: "/adminapi/counter/counter/list",
    method: "get",
    data
  });
}