<template>
  <el-dialog
    class="main-dialog-form size-lg"
    :visible.sync="isVisible"
    title="商品规格"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      :model="formData"
      :rules="formRules"
      ref="instForm"
      size="small"
      label-width="120px"
    >
      <el-row>
        <el-col :span="9">
          <el-form-item label="规格模板名称：" prop="rule_name">
            <el-input
              v-model="formData.rule_name"
              placeholder="请输入标题名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="attrsList.length > 0" :span="24">
          <el-form-item>
            <vuedraggable
              :list="attrsList"
              group="peoples"
              handle=".move-icon"
              :move="handleAttrsMove"
              @end="handleAttrsEndMove"
            >
              <div
                v-for="(item, index) in attrsList"
                :key="index"
                :class="['rowmid', index !== 0 ? 'mgt10' : '']"
              >
                <div class="move-icon mgr10">
                  <span class="iconfont icon_diandiandian"></span>
                </div>
                <div
                  style="width: 90%"
                  :class="moveIndex === index ? 'borderStyle' : ''"
                >
                  <div class="rowmid">
                    <span class="mgr5">{{ item.value }}</span>
                    <span
                      class="el-icon-circle-close"
                      @click="handleRemoveRole(index)"
                    ></span>
                  </div>
                  <div class="row rowwrap">
                    <vuedraggable
                      :list="item.detail"
                      handle=".drag-tag"
                      class="rowmid rowwrap"
                    >
                      <el-tag
                        v-for="(attrName, attrIndex) in item.detail"
                        :key="attrIndex"
                        size="small"
                        closable
                        class="mgr20 mgt5 mgb5 drag-tag"
                        @close="handleRemoveAttr(item.detail, attrIndex)"
                      >
                        {{ attrName }}
                      </el-tag>
                    </vuedraggable>
                    <el-input
                      v-model="item.detail.attrsVal"
                      placeholder="添加属性"
                      class="input-append-button"
                      style="width: 150px"
                      @keyup.enter.native="
                        handleAddAttr(item.detail.attrsVal, index, $event)
                      "
                    >
                      <el-button
                        slot="append"
                        type="primary"
                        @click="handleAddAttr(item.detail.attrsVal, index)"
                        >添加</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </div>
            </vuedraggable>
          </el-form-item>
        </el-col>
        <el-col v-if="showAddNewRule" :span="24">
          <el-row>
            <el-col :span="9">
              <el-form-item label="规格：" class="main-form-row">
                <el-input
                  v-model="formNewRule.attrsName"
                  placeholder="请输入规格"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="规格值：" class="main-form-row">
                <el-input
                  v-model="formNewRule.attrsVal"
                  placeholder="请输入规格值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="mgl20">
              <el-button
                type="primary"
                size="small"
                style="min-width: 50px"
                @click="handleConfirmNewRule"
                >确定</el-button
              >
              <el-button
                size="small"
                style="min-width: 50px"
                @click="handleCancelNewRule"
                >取消</el-button
              >
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="!showAddNewRule" :span="24">
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="handleAddNewRule"
              >添加新规格</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button
        @click="handleConfirm"
        type="primary"
        size="small"
        :loading="isLoading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import vuedraggable from "vuedraggable";
import { editAttrApi, fetchRule } from "@/api/product";

export default {
  name: "AddAttr",
  components: {
    vuedraggable,
  },
  data() {
    return {
      // 是否显示弹框
      isVisible: false,
      // 是否显示加载中
      isLoading: false,
      // 是否为编辑模式
      isEdit: false,
      EditId: 0,
      // 表单数据
      formData: {},
      // 表单规则
      formRules: {
        rule_name: [{ required: true, message: "请输入标题名称" }],
      },
      // 是否显示添加新规格
      showAddNewRule: false,
      // 排序顺序
      moveIndex: -1,
      // 规格属性列表
      attrsList: [],
      // 规格数据
      formNewRule: {
        attrsName: "",
        attrsVal: "",
      },
    };
  },
  methods: {
    // 由外部调用显示弹框
    async show({ data = false } = {}) {
      let ruleInfo;
      this.isEdit = data !== false;
      this.EditId = data ? data.id : "0";
      if (this.isEdit) {
        ruleInfo = await fetchRule(this.EditId);
        ruleInfo = ruleInfo.data.info;
      }

      console.log(ruleInfo);
      this.reset(ruleInfo);
      this.isVisible = true;
    },

    // 重置数据
    reset(data) {
      console.log(data);
      this.showAddNewRule = false;
      this.moveIndex = -1;
      this.attrsList = data ? data.spec : [];
      this.formNewRule = {
        attrsName: "",
        attrsVal: "",
      };
      this.formData = {
        rule_name: data ? data.rule_name : "", // 规格名称
      };
    },

    // 检测规格移动
    handleAttrsMove(evt) {
      this.moveIndex = evt.draggedContext.index;
    },

    // 规格移动结束
    handleAttrsEndMove() {
      this.moveIndex = "";
    },

    // 删除规格
    handleRemoveRole(index) {
      this.attrsList.splice(index, 1);
    },

    // 删除规格的单个标签
    handleRemoveAttr(item, index) {
      item.splice(index, 1);
    },

    // 添加新规格
    handleAddNewRule() {
      this.formNewRule = {
        attrsName: "",
        attrsVal: "",
      };
      this.showAddNewRule = true;
    },

    // 添加规格属性
    handleAddAttr(newAttr, idx) {
      if (newAttr) {
        if (!this.attrsList[idx].detail.includes(newAttr)) {
          this.attrsList[idx].detail.push(newAttr);
        }
        this.attrsList[idx].detail.attrsVal = "";
      } else {
        this.$message.warning("请添加属性");
      }
    },

    // 确定添加新规格
    handleConfirmNewRule() {
      if (
        /^\s*$/.test(this.formNewRule.attrsName) ||
        /^\s*$/.test(this.formNewRule.attrsVal)
      ) {
        this.$message.warning("请添加完整的规格！");
      } else {
        const existAttrs = this.attrsList.filter((item) => {
          return item.value === this.formNewRule.attrsName;
        });
        if (existAttrs.length === 0) {
          this.attrsList.push({
            value: this.formNewRule.attrsName,
            detail: [this.formNewRule.attrsVal],
          });
        }
        this.showAddNewRule = false;
      }
    },

    // 取消添加新规格
    handleCancelNewRule() {
      this.showAddNewRule = false;
    },

    // 关闭弹框
    handleClose() {
      this.isVisible = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.reset();
          this.$refs.instForm.resetFields();
        }, 200);
      });
    },

    // 点击确定
    handleConfirm() {
      this.$refs.instForm.validate((valid) => {
        if (valid) {
          if (this.attrsList.length === 0) {
            this.$message.error("请添加规格信息！");
            return;
          }

          let canGoOn = true;
          this.attrsList.some((item) => {
            if (item.detail.length === 0) {
              canGoOn = false;
              this.$message.error("规格属性不能为空！");
              return true;
            }
            return false;
          });

          if (canGoOn) {
            this.saveFormData();
          }
        }
      });
    },

    // 保存表单数据
    async saveFormData() {
      console.log("this.formData", this.formData); // 常规表单数据
      console.log("this.attrList", this.attrsList); // 规格列表信息
      const formData = {
        ...this.formData,
        spec: this.attrsList,
      };

      this.isLoading = true;
      const res = await editAttrApi(formData, this.EditId);
      this.$message.success({
        message: this.isEdit ? "编辑规格成功！" : "添加规格成功！",
      });
      this.isLoading = false;
      this.isVisible = false;
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scope>
// 边框样式
.borderStyle {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}
</style>
