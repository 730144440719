var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
      'comp-swiper': true,
      'border-radius': _vm.config.swiperRadiusType === 'radius',
      'space-margin': _vm.config.isSpaceMargin,
    },style:(_vm.layoutStyle)},[_c('swiper',{key:_vm.swiperOption.timestamp,ref:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.config.slideList),function(item){return _c('swiper-slide',{key:item.id},[(item.image)?_c('img',{staticClass:"banner-img",style:('height: ' + _vm.swiperHeight + 'px'),attrs:{"src":_vm.imageBaseUrl + item.image}}):_c('EmptyBanner',{attrs:{"height":_vm.swiperHeight,"iconSize":30,"iconColor":"#aaa"}})],1)}),1),(_vm.indicatorList.length > 1 && _vm.config.indicatorType !== 'none')?_c('div',{staticClass:"swiper-indicator",class:['align-' + (_vm.config.indicatorAlign || 'center')]},[(_vm.config.indicatorType === 'num')?_c('div',{staticClass:"indicator-item type-num"},[_vm._v(" "+_vm._s(_vm.currentIndex + 1)+"/"+_vm._s(_vm.indicatorList.length)+" ")]):_vm._l((_vm.indicatorList),function(item,index){return _c('div',{key:index,staticClass:"indicator-item",class:[
            index === _vm.currentIndex ? 'indicator-active' : '',
            'type-' + (_vm.config.indicatorType || 'dot'),
          ],style:('background-color: ' +
            (index === _vm.currentIndex
              ? _vm.config.indicatorActiveColor
              : _vm.config.indicatorColor) +
            ';')})})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }