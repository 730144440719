<template>
  <div
    :class="{
      'comp-banner': true,
      'border-radius': config.bgRadiusType === 'radius'
    }"
    :style="layoutStyle"
  >
    <!-- 三宫格布局 -->
    <div v-if="useImageList.length > 0 && config.styleType === 'threePalaces'" :class="['comp-banner-' + config.styleType]">
      <div
        v-for="(item) in useImageList"
        :key="item.id"
        :class="item.isPadding ? 'comp-banner-padding' : 'comp-banner-item'"
        :style="item.isPadding ? item.style : ''"
      >
        <template v-if="item.isMultiple">
          <div
            v-for="(innerItem) in item.list"
            :key="innerItem.id"
            :class="innerItem.isPadding ? 'comp-banner-padding' : 'comp-banner-subitem'"
            :style="innerItem.isPadding ? innerItem.style : ''"
          >
            <template v-if="!innerItem.isPadding">
              <img v-if="innerItem.image" :class="['comp-banner-img', config.imgRadiusType === 'radius' ? 'border-radius' : '']" :src="imageBaseUrl + innerItem.image" alt="" />
              <EmptyBanner v-else />
            </template>
          </div>
        </template>
        <template v-else-if="!item.isPadding">
          <img v-if="item.image" :class="['comp-banner-img', config.imgRadiusType === 'radius' ? 'border-radius' : '']" :src="imageBaseUrl + item.image" alt="" />
          <EmptyBanner v-else />
        </template>
      </div>
    </div>
    <!-- 四宫格布局 -->
    <div v-else-if="useImageList.length > 0 && config.styleType === 'fourPalaces'" :class="['comp-banner-' + config.styleType]">
      <div
        v-for="(item) in useImageList"
        :key="item.id"
        :class="item.isPadding ? 'comp-banner-padding' : 'comp-banner-item'"
        :style="item.isPadding ? item.style : ''"
      >
        <template v-if="item.isMultiple">
          <div
            v-for="(innerItem) in item.list"
            :key="innerItem.id"
            :class="innerItem.isPadding ? 'comp-banner-padding' : 'comp-banner-subitem'"
            :style="innerItem.isPadding ? innerItem.style : ''"
          >
            <template v-if="!innerItem.isPadding">
              <img v-if="innerItem.image" :class="['comp-banner-img', config.imgRadiusType === 'radius' ? 'border-radius' : '']" :src="imageBaseUrl + innerItem.image" alt="" />
              <EmptyBanner v-else />
            </template>
          </div>
        </template>
        <template v-else-if="!item.isPadding">
          <img v-if="item.image" :class="['comp-banner-img', config.imgRadiusType === 'radius' ? 'border-radius' : '']" :src="imageBaseUrl + item.image" alt="" />
          <EmptyBanner v-else />
        </template>
      </div>
    </div>
    <!-- 其他布局 -->
    <div v-else-if="useImageList.length > 0" :class="['comp-banner-' + config.styleType]">
      <div
        v-for="(item) in useImageList"
        :key="item.id"
        :class="item.isPadding ? 'comp-banner-padding' : 'comp-banner-item'"
        :style="item.isPadding ? item.style : ''"
      >
        <template v-if="!item.isPadding">
          <img v-if="item.image" :class="['comp-banner-img', config.imgRadiusType === 'radius' ? 'border-radius' : '']" :src="imageBaseUrl + item.image" alt="" />
          <EmptyBanner v-else />
        </template>
      </div>
    </div>
    <!-- 空图片 -->
    <EmptyBanner v-else />
  </div>
</template>

<script>
  import { imageBaseUrl } from "@/utils/config";
  import EmptyBanner from './EmptyBanner.vue'

  export default {
    name: "WidgetPictureCube",
    components: {
      EmptyBanner,
    },
    props: {
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        // 图片资源路径
        imageBaseUrl,
      }
    },
    computed: {
      // 使用的图片列表
      useImageList() {
        const {styleType, imageList, paddingValue} = this.config

        const getPaddingItemRow = () => {
          return { isPadding: true, id: `is-padding-${Math.round(Math.random()*100000000)}`, style: `width: ${paddingValue || 0}px;` }
        }
        const getPaddingItemCol = () => {
          return { isPadding: true, id: `is-padding-${Math.round(Math.random()*100000000)}`, style: `height: ${paddingValue || 0}px;` }
        }

        let useImageList = imageList || []
        if (imageList && imageList.length >= 2 && paddingValue > 0) {
          // 带有内部间距的布局
          if (styleType === 'two') {
            useImageList = [imageList[0], getPaddingItemRow(), imageList[1]]
          }
          else if (styleType === 'three') {
            useImageList = [imageList[0], getPaddingItemRow(), imageList[1], getPaddingItemRow(), imageList[2]]
          }
          else if (styleType === 'threePalaces') {
            useImageList = [imageList[0], getPaddingItemRow(), { isMultiple: true, id: 'is-mulitiple', list: [imageList[1], getPaddingItemCol(), imageList[2]]}]
          }
          else if (styleType === 'four') {
            useImageList = [imageList[0], getPaddingItemRow(), imageList[1], getPaddingItemRow(), imageList[2], getPaddingItemRow(), imageList[3]]
          }
          else if (styleType === 'fourPalaces') {
            useImageList = [
              { isMultiple: true, id: 'is-mulitiple-1', list: [imageList[0], getPaddingItemRow(), imageList[1]]},
              getPaddingItemCol(),
              { isMultiple: true, id: 'is-mulitiple-2', list: [imageList[2], getPaddingItemRow(), imageList[3]]},
            ]
          }
        } else if (styleType === 'threePalaces') {
          // 三宫格布局
          useImageList = [imageList[0], { isMultiple: true, id: 'is-mulitiple', list: [imageList[1], imageList[2]]}]
        } else if (styleType === 'fourPalaces') {
          // 四宫格布局
          useImageList = [
            { isMultiple: true, id: 'is-mulitiple-1', list: [imageList[0], imageList[1]]},
            { isMultiple: true, id: 'is-mulitiple-2', list: [imageList[2], imageList[3]]},
          ]
        }

        return useImageList
      },

      // 容器样式
      layoutStyle() {
        const styleObj = {}
        const {bgColor, marginHorizontal, marginTop} = this.config

        if (bgColor) {
          styleObj.backgroundColor = bgColor
        }

        if (marginHorizontal) {
          styleObj.marginLeft = `${marginHorizontal}px`
          styleObj.marginRight = `${marginHorizontal}px`
        }

        if (marginTop) {
          styleObj.marginTop = `${marginTop}px`
        }

        return styleObj;
      },
    },
    methods: {
    },
  };
</script>

<style lang="scss" scope>
// 图片容器
.comp-banner {
  overflow: hidden;
}

// 边框圆角
.border-radius {
  border-radius: 10px;
}

// 单张布局
.comp-banner-single {
  .comp-banner-item {
    width: 100%;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// 两列布局
.comp-banner-two {
  display: flex;
  flex-direction: row;

  .comp-banner-item {
    width: 50%;
  }

  .comp-banner-padding {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .empty-banner {
    width: 100% !important;
    height: 180px !important;
  }

  .empty-banner-icon {
    font-size: 32px !important;
  }
}

// 三列布局
.comp-banner-three {
  display: flex;
  flex-direction: row;

  .comp-banner-item {
    width: 33.33%;
  }

  .comp-banner-padding {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .empty-banner {
    width: 100% !important;
    height: 180px !important;
  }

  .empty-banner-icon {
    font-size: 32px !important;
  }
}

// 三宫格布局
.comp-banner-threePalaces {
  display: flex;
  flex-direction: row;

  .comp-banner-item {
    width: 50%;

    &:first-child .comp-banner-img {
      height: 100%;
    }
  }

  .comp-banner-padding {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .empty-banner {
    width: 100% !important;
    height: 180px !important;
  }

  .empty-banner-icon {
    font-size: 32px !important;
  }
}

// 四列布局
.comp-banner-four {
  display: flex;
  flex-direction: row;

  .comp-banner-item {
    width: 25%;
  }

  .comp-banner-padding {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .empty-banner {
    width: 100% !important;
    height: 180px !important;
  }

  .empty-banner-icon {
    font-size: 32px !important;
  }
}

// 四宫格布局
.comp-banner-fourPalaces {

  .comp-banner-item {
    display: flex;
    flex-direction: row;
  }

  .comp-banner-subitem {
    width: 50%;
  }

  .comp-banner-padding {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comp-banner-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .empty-banner {
    width: 100% !important;
    height: 180px !important;
  }

  .empty-banner-icon {
    font-size: 32px !important;
  }
}
</style>
