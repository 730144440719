<template>
  <div class="main-content">
    <!-- 筛选表单 -->
    <div class="main-section">
      <div class="filter-container">
        <div class="filter-title">店铺选择</div>
        <el-select
          style="width: 250px"
          class="filter-item"
          placeholder="店铺选择"
          v-model="searchData.CampaignMallCode"
          @change="MallChange"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.MallName"
            :value="item.MallCode"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- 主体内容区 -->
    <div class="main-section">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button
          class="filter-item"
          type="primary"
          size="small"
          v-on:click="() => showAddMarkingDialog({ type: 'add' })"
          icon="el-icon-circle-plus-outline"
          >创建活动</el-button
        >
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          fit
          ref="table"
          v-loading="loading"
        >
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>

          <el-table-column
            prop="CampaignTitle"
            label="CampaignTitle"
          ></el-table-column>
          <el-table-column
            prop="CampaignId"
            label="CampaignId"
          ></el-table-column>

          <el-table-column prop="CampaignType" label="CampaignType">
            <template slot-scope="scope">
              {{
                scope.row.CampaignType == "1"
                  ? "盲盒抽实物"
                  : scope.row.CampaignType == "2"
                  ? "绑定抽积分"
                  : "分享绑定"
              }}
            </template>
          </el-table-column>

          <el-table-column
            prop="CampaignMallName"
            label="CampaignMallName"
          ></el-table-column>

          <el-table-column
            prop="CampaignMallCode"
            label="CampaignMallCode"
          ></el-table-column>

          <el-table-column
            prop="CampaignAddTime"
            label="CampaignAddTime"
          ></el-table-column>
          <el-table-column
            prop="CampaignEndTime"
            label="CampaignEndTime"
          ></el-table-column>

          <!-- <el-table-column prop="StartTime" label="StartTime"> </el-table-column>
          <el-table-column prop="EndTime" label="EndTime"> </el-table-column> -->

          <el-table-column label="控制" align="center" width="260">
            <!--  -->
            <template slot-scope="scope">
              <el-button
                v-on:click="
                  () => {
                    TapActivitiesDetail({ CampaignId: scope.row.CampaignId });
                  }
                "
                size="mini"
                style="width: 60px"
                >查看</el-button
              >

              <el-button
                v-on:click="
                  () => {
                    showAddMarkingDialog({
                      type: 'updata',
                      ...scope.row,
                    });
                  }
                "
                size="mini"
                type="primary"
                style="width: 60px"
                >编辑</el-button
              >
              <!-- <el-button
                v-on:click="
                  () => {
                    markDelBtn({
                      CampaignId: scope.row.CampaignId,
                    });
                  }
                "
                size="mini"
                type="danger"
                style="width: 60px"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页处理 -->
      <div class="list-page">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          :total="totalSize"
          :page-sizes="[25, 50, 100]"
          :page-size="searchData.limit"
          @current-change="qiehuan"
          :current-page.sync="searchData.page"
        />
      </div>
    </div>

    <el-dialog
      :title="MarkingDialogType === 'add' ? '创建活动' : '修改活动'"
      :visible.sync="MarkingDialog"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
      :show-close="MarkingDialogType === 'add'"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">活动名称：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.CampaignTitle"
          ></el-input>
        </div>
        <div style="width: 10px"></div>
        <div class="dialog-right">
          <div class="el-upload__tip" slot="tip">- 活动名称不能超过10个字</div>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">活动类型:</div>
        </div>
        <div class="dialog-right">
          <el-select
            style="width: 250px"
            class="filter-item"
            placeholder=""
            v-model="formData.CampaignType"
          >
            <el-option
              v-for="item in CampaignTypeList"
              :key="item.val"
              :label="item.name"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">活动图片:</div>
        </div>
        <div class="dialog-right">
          <el-upload
            class="avatar-uploader"
            :action="baseImageUrl + '/api/upload/image'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image
              v-if="formData.CampaignImage"
              :src="baseImageUrl + formData.CampaignImage"
              class="avatar"
              fit="cover"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div style="width: 10px"></div>
        <div class="dialog-right">
          <div class="el-upload__tip" slot="tip">
            -
            最多上传1张图，单张图片宽度小于800px，高度50-300px之间，大小需限制在1M
          </div>
          <div class="el-upload__tip" slot="tip">
            - 盲盒抽实物活动需要上传1张图
          </div>
          <div class="el-upload__tip" slot="tip">
            - 绑定抽积分活动无需上传图片
          </div>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">详情链接：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.CampaignDetailLink"
          ></el-input>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">开始时间：</div>
        </div>
        <div class="dialog-right">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.CampaignStartTime"
            type="datetime"
            placeholder="活动开始时间"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">结束时间：</div>
        </div>
        <div class="dialog-right">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.CampaignEndTime"
            type="datetime"
            placeholder="活动结束时间"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">消耗积分：</div>
        </div>
        <div class="dialog-right">
          <el-input
            class="filter-item"
            style="width: 350px"
            placeholder=""
            v-model="formData.ConsumePoint"
          ></el-input>
        </div>
        <div style="width: 10px"></div>
        <div class="dialog-right">
          <div class="el-upload__tip" slot="tip">
            - 盲盒抽实物活动需填写消耗积分数
          </div>
          <div class="el-upload__tip" slot="tip">- 绑定抽积分活动无需填写</div>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">奖品:</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-add-prize">
            <el-button
              v-on:click="addPrize"
              type="primary"
              icon="el-icon-circle-plus-outline"
              >添加奖品</el-button
            >
          </div>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title"></div>
        </div>
        <div class="dialog-right">
          <div class="el-upload__tip" slot="tip">- 奖品名称不超过10个字</div>
          <div class="el-upload__tip" slot="tip">
            -
            若奖品概率均为整数，则所有奖品的概率之和必须等于100。填写规则：A奖品概率为30%，填写时写30；B奖品概率为20%，填写时写20；则C奖品概率必须为50%，填写时写50。
          </div>
          <div class="el-upload__tip" slot="tip">
            -
            若任意奖品的概率有小数，则所有奖品的概率之和必须等于1000。填写规则：A奖品的概率为5‰，填写时写5；B奖品的概率为400‰，填写时写400；则C奖品的概率必须为595‰，填写时写595。
          </div>
        </div>
      </div>
      <template v-for="(i, idx) in formData.prizeList">
        <div class="dialog-box" :key="idx">
          <div class="dialog-letf">
            <div class="dialog-title">{{ idx + 1 }}:</div>
          </div>
          <div class="dialog-right">
            <div class="dialog-right-flex">
              {{ formData.CampaignType == "2" ? "积分数值：" : " 奖品名称:" }}
              <div style="width: 5px"></div>
              <el-input
                class="filter-item"
                style="width: 200px"
                placeholder=""
                v-model="i.PrizeTitle"
              ></el-input>
              <template v-if="i.PrizeType != 2">
                <div style="width: 15px"></div>
                {{ formData.CampaignType == "2" ? "数量:" : " 奖品数量:" }}
                <div style="width: 5px"></div>
                <el-input
                  class="filter-item"
                  style="width: 80px"
                  placeholder=""
                  v-model="i.PrizeQuantity"
                ></el-input>
              </template>

              <template v-if="formData.CampaignType == '1'">
                <div style="width: 15px"></div>
                类型:
                <div style="width: 5px"></div>

                <el-select
                  style="width: 120px"
                  class="filter-item"
                  placeholder=""
                  v-model="i.PrizeType"
                >
                  <el-option
                    v-for="item in PrizeTypeList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  ></el-option>
                </el-select>
              </template>
              <div style="width: 15px"></div>
              {{
                formData.CampaignType == "3" ? "邀请绑定成功人数:" : " 概率: "
              }}
              <div style="width: 5px"></div>
              <el-input
                class="filter-item"
                style="width: 80px"
                placeholder=""
                v-model="i.Probability"
              ></el-input>

              <template v-if="formData.CampaignType == '3'">
                <div style="width: 15px"></div>
                奖品图片：
                <div style="width: 5px"></div>

                <div class="dialog-right">
                  <el-upload
                    class="prize-uploader"
                    :action="baseImageUrl + '/api/upload/image'"
                    :show-file-list="false"
                    :on-success="(res) => handlePrizeImageSuccess(res, i)"
                    :before-upload="beforeAvatarUpload"
                  >
                    <el-image
                      v-if="i.PrizeImageUrl"
                      :src="baseImageUrl + i.PrizeImageUrl"
                      class="prize-image"
                      fit="cover"
                    />
                    <i v-else class="el-icon-plus prize-uploader-icon"></i>
                  </el-upload>
                </div>
              </template>

              <template v-if="formData.CampaignType == '3'">
                <div style="width: 15px"></div>
                中奖图：
                <div style="width: 5px"></div>

                <div class="dialog-right">
                  <el-upload
                    class="prize-uploader"
                    :action="baseImageUrl + '/api/upload/image'"
                    :show-file-list="false"
                    :on-success="(res) => handlePrizeImageSuccess2(res, i)"
                    :before-upload="beforeAvatarUpload"
                  >
                    <el-image
                      v-if="i.PrizeImageUrl2"
                      :src="baseImageUrl + i.PrizeImageUrl2"
                      class="prize-image"
                      fit="cover"
                    />
                    <i v-else class="el-icon-plus prize-uploader-icon"></i>
                  </el-upload>
                </div>
              </template>

              <div style="width: 30px"></div>

              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="
                  () => {
                    delPrizeList({ idx, CampaignPrizeId: i.CampaignPrizeId });
                  }
                "
              ></el-button>
            </div>
          </div>
        </div>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="MarkingDialogType === 'add'"
          @click="MarkingDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="addMarkingEvent">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="活动详情"
      :visible.sync="ViewActivityDetails"
      width="85%"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">活动名称：</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title" style="width: 350px">
            {{ ActivitiesDetailData.CampaignTitle }}
          </div>
        </div>
      </div>
      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">活动类型：</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">
            {{
              ActivitiesDetailData.CampaignType == "1"
                ? "盲盒抽实物"
                : ActivitiesDetailData.CampaignType == "2"
                ? "绑定抽积分"
                : "分享绑定"
            }}
          </div>
        </div>
      </div>

      <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">参与人数:</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">
            {{ ActivitiesDetailData.PrizeCount }}
          </div>
        </div>
      </div>
      <!-- <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">奖品库存:</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-add-prize"></div>
        </div>
      </div> -->

      <el-table
        :data="ActivitiesDetailData.prizeList"
        style="width: 100%"
        border
        fit
        ref="table"
        v-loading="loading"
      >
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>

        <el-table-column prop="PrizeTitle" label="PrizeTitle" align="center">
          <template slot-scope="scope">
            {{ scope.row.PrizeTitle
            }}{{
              ActivitiesDetailData.CampaignType == 2 ? "积分" : ""
            }}</template
          >
        </el-table-column>
        <el-table-column
          prop="PrizeCount"
          label="PrizeCount"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="PrizeQuantity"
          label="PrizeQuantity"
          align="center"
        ></el-table-column>
      </el-table>
      <!-- <div class="dialog-box">
        <div class="dialog-letf">
          <div class="dialog-title">奖品1:</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">小米手机</div>
        </div>
        <div class="dialog-letf">
          <div class="dialog-title">中奖人数：</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">10</div>
        </div>
        <div class="dialog-letf">
          <div class="dialog-title">奖品剩余</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">8</div>
        </div>
        <div class="dialog-letf">
          <div class="dialog-title">已经核销</div>
        </div>
        <div class="dialog-right">
          <div class="dialog-title">1</div>
        </div>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ViewActivityDetails = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCampaign,
  delActivity,
  CampaignDetail,
  CampaignList,
  delCampaignPrize,
} from "@/api/RaffleActivityList";
import { getCounterList } from "@/api/storeList";

import deepClone from "@/utils/deepClone";
import { imageBaseUrl } from "@/utils/config";
// import qs from "qs";

export default {
  name: "RaffleActivityList",
  data() {
    return {
      shopList: [],
      baseImageUrl: imageBaseUrl,
      CampaignTypeList: [
        {
          name: "盲盒抽实物",
          val: "1",
        },
        {
          name: "绑定抽积分",
          val: "2",
        },
        {
          name: "分享绑定",
          val: "3",
        },
      ],
      PrizeTypeList: [
        {
          name: "中奖",
          val: "1",
        },
        {
          name: "非中奖",
          val: "2",
        },
      ],
      loading: false,

      tableData: [],

      behavioralConditionsList: [],
      totalSize: 0,
      MarkingDialog: false,
      MarkingDialogType: "add",
      ViewActivityDetails: false,
      ActivitiesDetailData: {},

      formData: {
        CampaignTitle: "",
        CampaignType: "",
        CampaignMallCode: "",
        CampaignDetailLink: "",
        CampaignImage: "",
        CampaignEndTime: "",
        ConsumePoint: "",
        CampaignStartTime: "",

        prizeList: [],
      },
      searchData: {
        CampaignMallCode: "",
        limit: 25,
        page: 1,
      },
    };
  },
  created() {
    this.getSelectData();
  },
  mounted() {},
  methods: {
    async getSelectData() {
      const MallCode = localStorage.getItem("MallCode");

      const tableData = await getCounterList({
        limit: 9999,
        page: 1,
      });
      if (MallCode !== "null") {
        tableData.data.list = tableData.data.list.filter((i) => {
          return i.MallCode === MallCode;
        });
      }

      this.shopList = tableData.data.list;
      this.searchData.CampaignMallCode = tableData.data.list[0].MallCode;
      this.getTableData();
    },
    async MallChange() {
      this.getTableData();
    },
    async TapActivitiesDetail(CampaignId) {
      this.ViewActivityDetails = true;
      const { data } = await CampaignDetail(CampaignId);
      console.log(data);
      this.ActivitiesDetailData = data;
    },
    async getTableData() {
      this.loading = true;
      const tableData = await CampaignList(this.searchData);
      this.tableData = tableData.data.list;
      this.totalSize = tableData.data.count;
      this.loading = false;
    },
    async showAddMarkingDialog(res) {
      const { type } = res;
      //

      if (type === "add") {
        this.formData = {
          CampaignTitle: "",
          CampaignType: "",
          ConsumePoint: "",
          CampaignDetailLink: "",
          CampaignImage: "",
          CampaignStartTime: "",

          CampaignEndTime: "",
          prizeList: [],
          CampaignMallCode: this.searchData.CampaignMallCode,
        };

        // this.formData.CampaignTitle = "";
        // this.formData.CampaignType = "";
        // this.formData.ConsumePoint = "";
        // this.formData.CampaignDetailLink = "";
        // this.formData.CampaignImage = "";
        // this.formData.CampaignEndTime = "";
        // this.formData.prizeList = [];
      } else {
        this.formData.CampaignMallCode = this.searchData.CampaignMallCode;
        this.formData.CampaignId = res.CampaignId;
        this.formData.CampaignTitle = res.CampaignTitle;
        this.formData.CampaignType = res.CampaignType;
        this.formData.ConsumePoint = res.ConsumePoint;
        this.formData.CampaignDetailLink = res.CampaignDetailLink;
        this.formData.CampaignImage = res.CampaignImage;
        this.formData.CampaignStartTime = res.CampaignStartTime;
        this.formData.CampaignEndTime = res.CampaignEndTime;
        this.formData.prizeList = res.prizeList;

        console.log(res);
      }
      this.MarkingDialogType = type;
      this.MarkingDialog = true;
    },
    async markDelBtn(res) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delActivity(res);
          this.getTableData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      console.log(res);
    },
    beforeAvatarUpload(file) {
      const limitSize = 1;

      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < limitSize;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${limitSize}MB!`);
      }
      return isJPG && isLt2M;
    },
    async addMarkingEvent() {
      const data = deepClone(this.formData);

      data.prizeList = JSON.stringify(data.prizeList);
      // data.prizeList = qs.parse(data.prizeList);

      // const cc = (data.prizeList);

      // console.log(data.prizeList);

      // data.prizeList.tos;

      try {
        await this.checkFormData();
        await addCampaign(data);

        this.getTableData();

        this.MarkingDialog = false;
        this.$message({
          message: "保存成功",
          type: "success",
        });
      } catch (e) {
        this.$message.error(e.message);
        console.log(e.message);
      }
    },
    async checkFormData() {
      const {
        CampaignDetailLink,
        CampaignEndTime,
        CampaignImage,
        // CampaignMallCode,
        CampaignStartTime,
        CampaignTitle,
        CampaignType,
        ConsumePoint,
        prizeList,
      } = this.formData;
      let Probability = 0;

      if (CampaignTitle.length > 10) {
        throw new Error("活动名称不超过10个字");
      }
      if (CampaignTitle.length === 0) {
        throw new Error("未填写活动名称");
      }
      if (CampaignType.length === 0) {
        throw new Error("请选择活动类型");
      }
      if (CampaignType === "1") {
        if (CampaignImage.length === 0) {
          throw new Error("请上传活动图片");
        }
        if (CampaignDetailLink.length === 0) {
          throw new Error("请输入活动链接");
        }
        if (CampaignStartTime.length === 0) {
          throw new Error("请选择活动开始时间");
        }
        if (CampaignEndTime.length === 0) {
          throw new Error("请选择活动结束时间");
        }
        if (ConsumePoint.length === 0) {
          throw new Error("请填写活动消耗积分");
        }
        if (Number.isNaN(Number(ConsumePoint))) {
          throw new Error("消耗积分必须是数字");
        }
      }
      const rep = /[.]/;
      let DecimalPointTest = 0;
      prizeList.forEach((i) => {
        Probability += Number(i.Probability);

        DecimalPointTest += Number(rep.test(i.Probability));
      });
      if (DecimalPointTest > 0) {
        throw new Error("奖品概率不能是小数");
      }

      if (
        CampaignType != "3" &&
        !(Probability === 100 || Probability === 1000)
      ) {
        throw new Error("奖品概率相加需要等于100或者1000");
      }
    },
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.getTableData();
    },
    qiehuan(val) {
      this.searchData.page = val;
      this.getTableData();
    },
    addPrize() {
      const obj = {
        PrizeTitle: "",
        Probability: "",
        PrizeQuantity: "",
        PrizeType: "1",
        PrizeImageUrl: "",
        PrizeImageUrl2: "",
      };

      this.formData.prizeList.push(obj);
    },
    async delPrizeList(res) {
      const { idx, CampaignPrizeId } = res;
      if (CampaignPrizeId) {
        console.log(CampaignPrizeId);
        await delCampaignPrize({ CampaignPrizeId });
      }

      this.$delete(this.formData.prizeList, idx);
    },
    handleAvatarSuccess(res) {
      this.formData.CampaignImage = res.data.image_url;
      console.log(res.data.image_url);
    },
    handlePrizeImageSuccess(res, row) {
      row.PrizeImageUrl = res.data.image_url;

      console.log("res", res);
      console.log("row", row);
    },
    handlePrizeImageSuccess2(res, row) {
      row.PrizeImageUrl2 = res.data.image_url;
    },
  },

  destroyed() {},
};
</script>
<style lang="scss" scoped>
.footpagination {
  padding: 20px 0 30px 0;
}

.dialog-box {
  padding-bottom: 15px;
  display: flex;
}
.dialog-title {
  height: 40px;
  width: 80px;
  line-height: 40px;
}
.dialog-letf {
  width: 80px;
}
.dialog-right {
  .el-checkbox.is-bordered {
    margin-bottom: 15px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox {
    margin-right: 15px;
  }
}
.dialog-add-prize {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #409eff;
}
.dialog-right-flex {
  display: flex;
  align-items: center;
}
.avatar-uploader {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.prize-uploader {
  width: 78px;
  height: 78px;
  box-sizing: border-box;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.prize-uploader .el-upload:hover {
  border-color: #409eff;
}
.prize-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.prize-image {
  width: 78px;
  height: 78px;
  display: block;
}
</style>
