import store from '@/store/store'

const MOBILE_WIDTH = 992 // 移动端宽度设定

export default {
  watch: {
    $route() {
      if (this.deviceType === 'mobile' && !this.isCollapseMenu) {
        store.dispatch('changeMenuCollapse', true)
      }
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.$_handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_handleResize)
  },
  mounted() {
    const isMobile = this.$_checkIsMobile()
    if (isMobile) {
      store.dispatch('changeDeviceType', 'mobile')
      store.dispatch('changeMenuCollapse', true)
    }
  },
  methods: {
    // 检测是否为移动端展示模式
    $_checkIsMobile() {
      return document.documentElement.clientWidth < MOBILE_WIDTH
    },

    // 浏览器缩放处理事件
    $_handleResize() {
      if (!document.hidden) {
        const isMobile = this.$_checkIsMobile()
        store.dispatch('changeDeviceType', isMobile ? 'mobile' : 'desktop')

        if (isMobile) {
          store.dispatch('changeMenuCollapse', true)
        }
      }
    },
  },
}
