<template>
  <el-dialog
    class="main-dialog-form size-md"
    :visible.sync="isVisible"
    :title="isEdit ? '添加分类' : '编辑分类'"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form :model="formData" :rules="formRules" ref="instForm" size="small" label-width="120px">
      <el-row>
        <el-col :span="20">
          <el-form-item label="上级分类" prop="pid">
            <el-select v-model="formData.pid" placeholder="请选择上级分类" :disabled="isEdit">
              <el-option
                v-for="item in firstLevelList"
                :key="item.id"
                :value="item.id"
                :label="item.cate_name"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="分类名称：" prop="cate_name">
            <el-input v-model="formData.cate_name" placeholder="请输入分类名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="分类图标：" prop="pic">
            <upload-file v-model="formData.pic" acceptType="image"></upload-file>
            <div class="el-upload__tip">(180*180)</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="分类大图：" prop="big_pic">
            <upload-file v-model="formData.big_pic" acceptType="image"></upload-file>
            <div class="el-upload__tip">(468*340)</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="排序：" prop="sort">
            <el-input-number v-model="formData.sort" placeholder="请输入" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="状态：" prop="is_show">
            <el-radio-group v-model="formData.is_show">
              <el-radio :label="'1'">显示</el-radio>
              <el-radio :label="'0'">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button @click="handleConfirm" type="primary" size="small" :loading="isLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getClassifyListApi, editClassifyApi } from '@/api/product'

  export default {
    name: "AddClassify",
    data() {
      return {
        // 是否显示弹框
        isVisible: false,
        // 是否显示加载中
        isLoading: false,
        // 是否为编辑模式
        isEdit: false,
        EditId:"0",
        // 表单数据
        formData: {},
        // 表单规则
        formRules: {
          pid: [
            { required: true, message: '请选择上级分类' },
          ],
          cate_name: [
            { required: true, message: '请输入分类名称' },
          ],
        },
        // 一级分类列表
        firstLevelList: [],
      }
    },
    methods: {
      // 由外部调用显示弹框
      show({data = false} = {}) {

        console.log(data)
        this.getFisrtLevelClassify()
        this.reset(data)
        this.isEdit = data !== false
        this.EditId = data?data.id:"0"
        this.isVisible = true
      },

      // 重置数据
      reset(data) {
        this.formData = {
          pid: data ? data.pid : '', // 上级分类
          cate_name: data ? data.cate_name : '', // 分类名称
          pic: data ? data.pic : '', // 分类图标
          big_pic: data ? data.big_pic : '', // 分类大图
          sort: data ? data.sort : 0, // 排序
          is_show: data ? data.is_show : 1, // 状态
        }
      },

      // 获取一级分类列表
      async getFisrtLevelClassify() {
        const {data} =  await getClassifyListApi()

        this.firstLevelList = data.list
        this.firstLevelList.unshift({id: "0", cate_name: '顶级菜单'})
      },

      // 关闭弹框
      handleClose() {
        this.isVisible = false
        this.$nextTick(() => {
          setTimeout(()=> {
            this.reset()
            this.$refs.instForm.resetFields()
          }, 200)
        })
      },

      // 点击确定
      handleConfirm() {
        this.$refs.instForm.validate((valid) => {
          if (valid) {
            this.saveFormData()
          }
        });
      },

      // 保存表单数据
      async saveFormData() {
        console.log('this.formData', this.formData) // 常规表单数据
        this.isLoading = true
        const res = await editClassifyApi(this.formData,this.EditId)
        this.$message.success({ message:  this.isEdit ? '编辑分类成功！' : '添加分类成功！' });
        this.isLoading = false
        this.isVisible = false
        this.$emit('confirm')
      },
    },
  };
</script>

<style lang="scss" scope>
  // 边框样式
  .borderStyle {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
  }
</style>
