<template>
  <div class="mall-design">
    <div class="mall-design-list">
      <!-- 操作按钮 -->
      <div class="list-operate">
        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleAddTemplate">添加模板</el-button>
      </div>

      <!-- 列表数据 -->
      <div class="list-table">
        <el-table v-loading="isLoading" :data="listData">
          <el-table-column prop="ModulId" label="模板ID" align="center" min-width="50" ></el-table-column>
          <el-table-column prop="ModulName" label="模板名称" align="center" min-width="200" ></el-table-column>
          <el-table-column prop="CreateTime" label="添加时间" align="center" min-width="100" ></el-table-column>
          <el-table-column prop="EditTime" label="更新时间" align="center" min-width="100" ></el-table-column>
          <el-table-column
            prop="IsIndex"
            label="设为首页"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.IsIndex"
                active-text="是"
                inactive-text="否"
                :active-value="'1'"
                :inactive-value="'0'"
                :disabled="scope.row.IsIndex === '1'"
                :width="46"
                @change="handleChangeIsIndex(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 列表分页处理 -->
      <div class="list-page">
        <el-pagination
          v-if="isDone && listData.length > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="totalElements"
          :current-page="currentPage"
          :page-sizes="paginationSizes"
          :page-size="pageSize"
          :layout="paginationLayout"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import { getModuleList, setIndexModule, deleteModule } from "@/api/design";
  import { imageBaseUrl } from "@/utils/config";
  import mixinTable from "@/utils/mixinsTable";

  export default {
    name: "MallIndexDesign",
    mixins: [mixinTable],
    data() {
      return {
        // 列表接口
        fetchAction: getModuleList,
        // 搜索条件
        searchData: {},
      }
    },
    methods: {
      // 添加模板
      handleAddTemplate() {
        this.$router.push({
          name: "DesignPage",
          query: { id: 0, name: '首页' },
        });
      },

      // 编辑模板
      handleEdit(record) {
        this.$router.push({
          name: "DesignPage",
          query: { id: record.ModulId, name: '首页' },
        });
      },

      // 变更是否为首页的状态
      async handleChangeIsIndex(record) {
        const res = await setIndexModule({ModulId: record.ModulId})
        if (res.status === 200) {
          this.updateListData()
        } else {
          this.$message.error(res.msg)
        }
      },

      // 删除模板
      handleDelete(record) {
        if (record.IsIndex === '1') {
          this.$message.warning('设为首页的模板不可删除！')
          return
        }

        this.$confirm('删除后将无法恢复，请谨慎操作！', '删除', { type: 'warning', callback: async (action) => {
          if (action === 'confirm') {
            const sendData = {ModulId: record.ModulId}
            const res = await deleteModule(sendData)
            if (res.status === 200) {
              this.$message.success('删除模板成功!')
              this.updateListData()
            } else {
              this.$message.error(res.msg)
            }
          }
        }})
      },

      // 预览模板
      handlePreview(record) {

      },

      // 设为首页
      handleSetUse(record) {

      },
    },
  };
</script>

<style lang="scss" scope>
.mall-design {
  display: flex;
  flex-direction: row;
}
.mall-design-preview {
  flex-shrink: 0;
  width: 320px;
  height: 560px;
  border: 1px solid #eee;
  border-radius: 15px;
}
.mall-design-list {
  min-width: 100px;
  flex-grow: 1;
  padding: 0 20px;
}
</style>
