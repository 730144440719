<template>
  <div class="config-cont">
    <el-tabs value="content" class="main-tabs-side">
      <el-tab-pane name="content" label="内容设置">
        <div class="config-section">
          <div class="config-section-msg">最多可添加1张图片，建议宽度90 * 90px</div>
          <el-divider></el-divider>
          <vuedraggable
            class="slide-list"
            handle=".move-icon"
            :list="currentValue.navList"
          >
            <div v-for="(item, index) in currentValue.navList" :key="item.id" class="slide-item">
              <div class="rowmid rel">
                <div class="move-icon mgr10">
                  <span class="iconfont icon_diandiandian"></span>
                </div>
                <UploadFile
                  v-model="item.image"
                  acceptType="image"
                  iconClass="el-icon-picture-outline"
                />
                <div class="delete-icon" @click="handleDeleteItem(item, index)">
                  <i class="el-icon-error"></i>
                </div>
              </div>
              <div>
                <div class="rowmid">
                  <div class="text-muted text-sm mgr10">标题</div>
                  <el-input
                    v-model="item.title"
                    placeholder="请输入标题"
                    size="small"
                  />
                </div>
                <div class="rowmid mgt10">
                  <div class="text-muted text-sm mgr10">链接</div>
                  <el-input
                    v-model="item.page"
                    placeholder="请输入链接"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </vuedraggable>
          <el-divider></el-divider>
          <el-button class="add-content-button" type="primary" plain size="medium" @click="handleAddItem">添加导航项</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane name="style" label="样式设置">
        <div class="config-prop">
          <div class="config-label">图标样式</div>
          <el-radio-group v-model="currentValue.iconRadiusType" size="mini">
            <el-radio-button label="rect">方形</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">每行个数</div>
          <el-radio-group v-model="currentValue.rowPerNum" size="mini">
            <el-radio-button label="3">3个</el-radio-button>
            <el-radio-button label="4">4个</el-radio-button>
            <el-radio-button label="5">5个</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">背景颜色</div>
          <el-color-picker v-model="currentValue.bgColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">文字颜色</div>
          <el-color-picker v-model="currentValue.textColor" show-alpha size="mini"></el-color-picker>
        </div>
        <div class="config-prop">
          <div class="config-label">背景样式</div>
          <el-radio-group v-model="currentValue.bgRadiusType" size="mini">
            <el-radio-button label="rect">直角</el-radio-button>
            <el-radio-button label="radius">圆角</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-prop">
          <div class="config-label">两侧边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginHorizontal" />
            <el-input-number v-model="currentValue.marginHorizontal" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
        <div class="config-prop">
          <div class="config-label">顶部边距</div>
          <div class="rowmid">
            <el-slider v-model="currentValue.marginTop"  />
            <el-input-number v-model="currentValue.marginTop" size="mini" controls-position="right" :min="0" :max="100" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import vuedraggable from "vuedraggable";
  import designUtils from '../utils/designUtils'

  // 生成默认的内容项
  const makeDefaultItem = () => {
    return { id: designUtils.createRandomId(), image: '', title: '', page: ''}
  }

  // 默认配置
  const DEFAULT_DATA = {
    // 内容设置
    navList: [
      { id: designUtils.createRandomId(), image: '', title: '今日推荐', page: ''},
      { id: designUtils.createRandomId(), image: '', title: '热门榜单', page: ''},
      { id: designUtils.createRandomId(), image: '', title: '首发新品', page: ''},
      { id: designUtils.createRandomId(), image: '', title: '促销单品', page: ''},
    ],
    // 样式设置
    iconRadiusType: 'rect',
    rowPerNum: '4',
    bgColor: '#FFFFFF',
    textColor: '#333',
    bgRadiusType: 'rect',
    marginHorizontal: 0,
    marginTop: 0,
  }

  export default {
    name: "ConfigNavList",
    components: {
      vuedraggable,
    },
    props: {
      // 装修配置id
      id: { type: [String, Number], default: '' },
      // 配置数据
      config: {
        type: Object,
        required: true,
        default() {
          return {...DEFAULT_DATA};
        },
      },
    },
    data() {
      return {
        // 当前配置的值
        currentValue: JSON.parse(JSON.stringify(Object.keys(this.config).length > 0 ? this.config : DEFAULT_DATA)),
        // 最多允许的导航项数量
        maxNavNums: 99,
      }
    },
    watch: {
      currentValue: {
        handler() {
          this.$emit('change', this.id, this.currentValue)
        },
        deep: true
      },
    },
    mounted() {
      if (this.id) {
        this.$emit('change', this.id, this.currentValue)
      }
    },
    methods: {
      // 添加内容项
      handleAddItem() {
        if (this.currentValue.navList.length < this.maxNavNums) {
          this.currentValue.navList.push(makeDefaultItem())
        }
      },

      // 删除内容项
      handleDeleteItem(item, index) {
        this.currentValue.navList.splice(index, 1);
        if (this.currentValue.navList.length === 0) {
          this.handleAddItem()
        }
      },
    },
  };
</script>

<style lang="scss" scope>
@import '../utils/config.scss';

.slide-item {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 210px;
  }
}

.delete-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;

  i {
    font-size: 20px;
    color: #999;
  }
}

.add-content-button {
  width: 100%;
}
</style>
