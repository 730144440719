import request from "@/utils/request";

// 抽奖活动列表
export function ErrorLogList(data) {
  return request({
    url: "/adminapi/memberpool/memberpool/ErrorLogList",
    method: "get",
    params: data
  });
}
